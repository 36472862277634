import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import utils from 'src/utils';

interface Option {
  id: string;
  selected?: boolean;
  title?: string;
  value: string;
}

interface Props {
  onChange?: (arg: string[]) => void;
  options: Option[];
  values?: string[];
}

const FilterRadioGroup: React.FC<Props> = ({ options = [], onChange, values = [] }: Props) => {
  const [selected, setSelected] = useState(options[0]?.value);

  const handleOnChange = (selectedOption: string): void => {
    setSelected(selectedOption);
    if (onChange) onChange([selectedOption]);
  };

  options.some(option => {
    const selectedValue = values.find(
      value => option?.value?.toLowerCase() === value?.toLowerCase()
    );
    const hasValueChanged = selectedValue !== selected;
    if (selectedValue && hasValueChanged) setSelected(selectedValue);
  });

  return (
    <RadioGroup
      className="bg-frost px-2 border border-platinum max-h-52 overflow-y-auto shadow-inner"
      onChange={handleOnChange}
      value={selected}
    >
      <RadioGroup.Label className="sr-only">Location distances</RadioGroup.Label>
      <>
        {options.map((option, index) => (
          <RadioGroup.Option
            key={option.value}
            value={option.value}
            className={({ checked }) =>
              utils.classNames(
                index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                index === options.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                checked ? 'border-primary z-10' : 'border-gray-200',
                'relative p-1 flex cursor-pointer focus:outline-none'
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={utils.classNames(
                    checked ? 'bg-primary border-transparent' : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-primary' : '',
                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={utils.classNames(
                      checked ? 'font-bold' : 'font-normal',
                      'block text-sm font-normal text-charcoal'
                    )}
                  >
                    {option.title || option.value}
                  </RadioGroup.Label>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </>
    </RadioGroup>
  );
};

export default FilterRadioGroup;
