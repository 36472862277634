import useSWR from 'swr';
import { Modal, RadioGroupList, RadioGroupTable, TextLink } from 'src/components/ui';
import { AnnotationIcon, PhoneIcon } from '@heroicons/react/outline';
import { useBillingDispatch, updateBilling } from 'src/providers/BillingProvider';
import { useEffect, useState } from 'react';
import { useSession } from 'next-auth/client';
import { useRouter } from 'next/router';

interface Option {
  description?: string;
  name?: string;
  price?: number;
  packageRef?: number;
  duration?: string;
}

interface Props {
  callbackUrl?: string;
  isOpen?: boolean;
  isOffer?: boolean;
  onClose: () => void;
  onSubmit: (message: string) => void;
  vehicleId?: string;
}

const ContactSellerModal: React.FC<Props> = ({
  callbackUrl,
  isOpen = false,
  isOffer = false,
  onClose,
  onSubmit,
  vehicleId,
}: Props) => {
  const [selectedOption, setSelectedOption] = useState<Option>();
  const router = useRouter();
  const dispatch = useBillingDispatch();
  const [textInputValue, setTextInputValue] = useState('');

  // See if user has any seller contacts available
  const [session] = useSession();
  const userEmail = session?.user?.email;
  const { data = [] } = useSWR(`/api/getUserByEmail?email=${userEmail}`);
  const contactCount = data?.data?.[0]?.data?.contactCount;

  // Get buyer packages to build options
  const { data: buyerPackages } = useSWR('/api/packages/getPackages?type=buyer');
  const filteredPackages = buyerPackages?.data?.filter((option: Option) => option?.price);
  const selectedBuyerOption = filteredPackages?.find(
    (option: Option) => option?.packageRef === selectedOption?.packageRef
  );

  // Get vehicle info
  const { data: vehicleData } = useSWR(`/api/getVehicleById?vehicleId=${vehicleId}`);
  const vehicle = vehicleData?.vehicle;

  // Default to first buyable package option
  useEffect(() => {
    setSelectedOption(filteredPackages?.[0]);
    dispatch(updateBilling({ callbackUrl, selectedPlan: filteredPackages?.[0] }));
  }, [buyerPackages]);

  const handleOnClose = (): void => {
    if (onClose) onClose();
  };

  const handleOnSubmit = (): void => {
    if (!contactCount && selectedOption) {
      const url = `${
        process.env.BASE_URL || process.env.NEXT_PUBLIC_VERCEL_URL
      }/my-account/buyer-membership/billing`;
      router.push(url);
    } else {
      onSubmit(textInputValue);
    }
  };

  const getSubmitButtonTitle = (): string => {
    if (!contactCount) return 'Go to Payment';
    if (isOffer) return 'Send Offer';
    return 'Send Message';
  };

  const getSectionToDisplay = (): React.ReactNode => {
    if (!contactCount) return chooseMembershipSection;
    return textAreaSection;
  };

  const getSubmitButtonDisabledState = (): boolean => {
    if (!contactCount) return !selectedOption;
    return !textInputValue.length;
  };

  const getModalTitle = (): React.ReactNode => {
    const contactSectionTitle = (
      <div className="flex justify-center">
        <AnnotationIcon
          className="h-6 w-6 mr-1 mt-1 text-red-600 text-primary"
          aria-hidden="true"
        />
        <h3 className="text-charcoal text-lg font-bold">
          {isOffer ? 'Take Over Lease' : 'Contact Seller'}
        </h3>
      </div>
    );
    const purchaseSectionTitle = (
      <div className="flex justify-start mb-4">
        <h3 className="text-charcoal text-base xs:text-lg font-bold">Purchase Membership</h3>
      </div>
    );

    return !contactCount ? purchaseSectionTitle : contactSectionTitle;
  };

  const questionSubTitle = (
    <div className="text-center mb-2 text-sm font-medium">
      Have a question?&nbsp;
      <span className="text-sm font-extrabold text-primary">Check out the Q&A feed.</span>
    </div>
  );

  const offerSubTitle = (
    <div className="text-center mb-2 text-sm font-medium">
      We will let the seller know you are willing to take over their lease.
    </div>
  );

  const textAreaSection = (
    <>
      <div className="mb-4">{isOffer ? offerSubTitle : questionSubTitle}</div>
      <label className="block font-bold text-steel text-left mb-1">
        {isOffer ? 'Add a Message' : 'Your Message'}
      </label>
      <textarea
        className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
        defaultValue={''}
        maxLength={10000}
        name="contact-seller"
        onChange={e => setTextInputValue(e?.target?.value)}
        rows={4}
      />
    </>
  );

  const chooseMembershipSection = (
    <>
      {questionSubTitle}
      <div className="font-medium text-sm text-slate mb-4 mx-auto w-2/3">
        Please purchase a buyer membership to contact the seller:
      </div>
      <div className="hidden md:block">
        <RadioGroupTable
          options={filteredPackages}
          onChange={option => {
            setSelectedOption(option);
            dispatch(updateBilling({ callbackUrl, selectedPlan: selectedBuyerOption }));
          }}
          selectedOption={selectedBuyerOption}
        />
      </div>
      <div className="block md:hidden">
        <RadioGroupList
          options={filteredPackages}
          onChange={option => {
            dispatch(updateBilling({ callbackUrl, selectedPlan: selectedBuyerOption }));
            setSelectedOption(option);
          }}
          selectedOption={selectedBuyerOption}
        />
      </div>
      <div className="text-sm font-extrabold text-primary mt-5">
        Learn more about our memberships
      </div>
    </>
  );

  const footerSection = (
    <div className="absolute left-0 bottom-0 w-full h-12 pt-3 text-sm text-steel font-bold bg-frost">
      <div className="flex justify-center">
        <div className="mx-2">Need help?</div>
        <span className="hidden sm:block font-medium mr-1">Speak with a lease specialist.</span>
        <span className="flex font-extrabold text-primary w-40">
          <PhoneIcon className="h-6 w-6 text-primary mr-1 -mt-1" aria-hidden="true" />
          1-877-893-1588
        </span>
      </div>
    </div>
  );

  return (
    <Modal
      footer={footerSection}
      isOpen={isOpen}
      isSubmitDisabled={getSubmitButtonDisabledState()}
      onClose={() => handleOnClose()}
      onSubmit={() => handleOnSubmit()}
      submitButtonTitle={getSubmitButtonTitle()}
      closeButtonTitle={'Cancel'}
      title={getModalTitle()}
    >
      <>
        <div className="flex flex-col">
          <div>{vehicle?.sellerFullName}</div>
          <TextLink size="md" href={`mailto: ${vehicle?.sellerEmail}`}>
            {vehicle?.sellerEmail}
          </TextLink>
          <TextLink size="md" href={`tel: ${vehicle?.sellerPhone}`}>
            {vehicle?.sellerPhone}
          </TextLink>
        </div>

        <div className="mt-1">{getSectionToDisplay()}</div>
      </>
    </Modal>
  );
};

export default ContactSellerModal;
