import { SearchFilters } from 'src/types';
import { ParsedUrlQuery } from 'querystring';
import { makeOptions, yearOptions } from 'src/config/inputOptions';
import vehicleCategories from 'src/config/vehicleCategories';

interface Option {
  id: string;
  selected?: boolean;
  title?: string;
  value: string;
}

const getSearchStateFromParams = (params: ParsedUrlQuery, state: SearchFilters) => {
  const stateFromParams = {};
  for (const [key] of Object.entries(params)) {
    if (!params[key]?.length) return;
    if (Array.isArray(state[key])) {
      const filterArray = (params[key] as string).split(',');
      stateFromParams[key] = filterArray;
    } else if (typeof state[key] === 'number') {
      stateFromParams[key] = parseInt(params[key] as string);
    } else {
      stateFromParams[key] = params[key];
    }
  }

  return stateFromParams;
};

const buildSearchOptions = (
  options: string[] = [],
  selected: string[] = [],
  title?: string
): Option[] =>
  options?.map((option, index) => ({
    id: `${index}`,
    selected: selected.includes(option),
    value: option,
    title: title && `${option} ${title}`,
  }));

const getSearchTitle = (filters: SearchFilters): string => {
  const make = filters?.makeFilters[0] || '';
  const body = filters?.bodyStyleFilters[0] || '';
  const year = filters?.yearFilters[0] || '';

  if (!make && !body && !year) return 'Vehicle Search';

  return `${year} ${make} ${body}`.trim();
};

const getAllFilters = (filters: SearchFilters) => {
  const hasMinLengthZipcode = filters?.zipcode?.length > 4;
  const allFilters = [
    ...(filters?.makeFilters?.sort() || []),
    ...(filters?.modelFilters?.sort() || []),
    ...(filters?.locationFilters?.map(
      (filter: string) => hasMinLengthZipcode && `${filter} miles`
    ) || []),
    ...(filters?.yearFilters?.sort()?.reverse() || []),
    ...(filters?.monthsRemainingFilters?.sort() || []),
    ...(filters?.leaseTypeFilters || []),
    ...(filters?.bodyStyleFilters?.sort() || []),
    (filters?.minMonthlyPayment && `$${filters?.minMonthlyPayment} min`) || 0,
    (filters?.maxMonthlyPayment && `$${filters?.maxMonthlyPayment} max`) || 0,
    (hasMinLengthZipcode && filters?.zipcode) || '',
  ].filter(Boolean);

  return allFilters;
};

const parseSearchQueryToFilters = (query: string, filters: SearchFilters, models: string[]) => {
  // Start with existing filters
  const makeFilters = filters?.makeFilters ? [...filters?.makeFilters] : [];
  const modelFilters = filters?.modelFilters ? [...filters?.modelFilters] : [];
  const yearFilters = filters?.yearFilters ? [...filters?.yearFilters] : [];
  const bodyStyleFilters = filters?.bodyStyleFilters ? [...filters?.bodyStyleFilters] : [];

  // Try to match query terms to filters
  const queryTerms = query?.split(' ');
  queryTerms.forEach(term => {
    makeOptions?.forEach(make => {
      if (make?.toLowerCase() === term?.toLowerCase()) makeFilters.push(make)
    });
    yearOptions?.forEach(year => {
      if (year?.toLowerCase() === term?.toLowerCase()) yearFilters.push(year)
    });
    models?.forEach(model => {
      if (model?.toLowerCase() === term?.toLowerCase()) modelFilters.push(model)
    });
    vehicleCategories?.forEach(category => {
      if (category?.categoryCode?.toLowerCase() === term?.toLowerCase()) bodyStyleFilters.push(category?.categoryCode)
    });
  });

  // Return unique filters
  return {
    bodyStyleFilters: [...new Set(bodyStyleFilters)],
    makeFilters: [...new Set(makeFilters)],
    modelFilters: [...new Set(modelFilters)],
    yearFilters: [...new Set(yearFilters)],
  }
}

const removeFilterFromQueryString = (query: string, filter: string): string => {
  // Filter out any terms that match the removed filter
  const queryArray = query?.split(' ');
  const filteredQuery = queryArray?.filter(term => term?.toLowerCase() !== filter?.toLowerCase());

  // Convert back to string and return
  return filteredQuery?.toString()?.replace(',', ' ');
}

export { buildSearchOptions, getAllFilters, getSearchStateFromParams, getSearchTitle, parseSearchQueryToFilters, removeFilterFromQueryString };
