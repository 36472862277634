import Image from 'next/image';
import { LocationMarkerIcon } from '@heroicons/react/outline';
import { Button, TextLink } from 'src/components/ui';
import { isFuture } from 'date-fns';
import utils from 'src/utils';
import { Vehicle } from 'src/types';

interface Props {
  index: number;
  vehicle: Vehicle;
  onSelect: () => void;
}

const WatchlistCard: React.FC<Props> = ({ index = 0, onSelect, vehicle }: Props) => {
  const isLeaseExpired = !isFuture(new Date(vehicle?.leaseExpDate?.['@ts']));
  return (
    <div
      className="border-b border-platinum grid grid-col-1 lg:grid-cols-4 gap-4 pb-8 text-charcoal"
      key={`${vehicle?.vehicleId}-${index}`}
    >
      <div className="col-span-2 relative z-10 col-start-1 row-start-1 px-4 pt-4 pb-3 h-56">
        {vehicle?.location ? (
          <div className="absolute flex bg-opacity-80 bg-slate font-medium items-center justify-center px-2 py-1 rounded-md text-sm text-white">
            <LocationMarkerIcon className="h-6 w-6" />
            <span className="pl-1">{vehicle?.location}</span>
          </div>
        ) : null}
      </div>

      <div className="col-span-2 col-start-1 row-start-1 flex">
        <div className="w-full grid grid-cols-3 grid-rows-2 gap-2">
          <div className="relative col-span-3 row-span-2">
            <Image
              alt="Car Image"
              className="absolute inset-0 object-fit rounded-xl"
              layout="fill"
              src={vehicle?.imageUrl || '/svg/sal-logo-mark.svg'}
            />
          </div>
        </div>
      </div>

      <div className="col-span-full col-start-3 flex flex-col h-full justify-between space-y-4">
        <div className="space-y-1 xs:space-y-0">
          <h3 className="font-bold pt-2 text-xl">{`${vehicle?.year || ''} ${vehicle?.make || ''} ${
            vehicle?.model || ''
          }`}</h3>

          <div className="font-medium text-slate text-sm">
            {vehicle?.drive} {vehicle?.engine} {vehicle?.bodyStyle}{' '}
            {vehicle?.defaultVehicleCategory}
          </div>

          <div className="flex justify-between">
            <span className="bg-barely-blue font-bold p-1 rounded text-charcoal text-sm">
              Lease Transfer
            </span>
            <div className="flex font-bold items-center text-primary text-xl">
              {utils.localizeCurrency(vehicle?.monthlyPayment)}
              <span className="font-normal pl-1 text-base text-slate">/ mo</span>
            </div>
          </div>

          <div className="flex flex-col xs:flex-row font-medium xs:space-x-2 space-y-1 xs:space-y-0">
            {vehicle?.monthsLeft ? (
              <>
                <div className="whitespace-nowrap">
                  {vehicle?.milesPerMonth > 0 ? Math.round(vehicle?.milesPerMonth) : 'Unknown'}
                  <span className="font-normal pl-1 text-slate">miles / month</span>
                </div>
                <div className="whitespace-nowrap">
                  {vehicle?.monthsLeft}
                  <span className="font-normal pl-1 text-slate">months left</span>
                </div>
              </>
            ) : (
              <div className="font-normal pl-1 text-slate">Expired</div>
            )}
          </div>

          <div className="flex flex-col xs:flex-row font-medium xs:space-x-2 space-y-1 xs:space-y-0">
            <div className="whitespace-nowrap">
              {vehicle?.listingPackageName || 'Inactive Package'}
            </div>
          </div>
        </div>

        <div className="flex flex-col xs:flex-row items-center justify-between space-y-4 xs:space-y-0">
          {!isLeaseExpired ? (
            <TextLink
              href="/my-account/my-listings/packages"
              size="sm"
              onClick={() => onSelect && onSelect()}
              scroll
            >
              Change Listing Package
            </TextLink>
          ) : (
            <div className="text-charcoal font-bold uppercase">expired</div>
          )}
          <Button href={`/listing/edit/${vehicle?.vehicleId}`} size="sm">
            Edit Listing
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WatchlistCard;
