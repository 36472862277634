// Input - https://tailwindui.com/components/application-ui/forms/input-groups

interface Props {
  disabled?: boolean;
  icon?: JSX.Element;
  id?: string;
  numbersOnly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  styles?: string;
  title?: string;
  value?: string | number;
}

const Input: React.FC<Props> = ({
  disabled = false,
  icon = null,
  id = '',
  numbersOnly = false,
  onChange = null,
  onFocus = null,
  onKeyDown = null,
  placeholder = '',
  styles = '',
  title = '',
  value = '',
}: Props) => {
  const disabledStyles = disabled ? 'opacity-40' : '';

  return (
    <div className={`${styles} ${disabledStyles}`}>
      <label className="block font-bold mb-1 text-steel" htmlFor={id}>
        {title}
      </label>
      <div className="relative">
        <input
          className="shadow-sm disabled:opacity-50 border-platinum focus:ring-primary focus:border-primary block w-full sm:text-sm rounded-md"
          disabled={disabled}
          id={id}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          placeholder={placeholder}
          type={numbersOnly ? 'number' : 'text'}
          value={value}
        />
        {icon && icon}
      </div>
    </div>
  );
};

export default Input;
