import { XIcon } from '@heroicons/react/outline';

interface Props {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  minimal?: boolean;
  styles?: string;
  title: string;
  titleStyles?: string;
}

const Badge: React.FC<Props> = ({
  onClick,
  minimal = false,
  styles = '',
  title,
  titleStyles = 'text-steel',
}: Props) => {
  const padding = minimal ? 'p-0 pl-0' : 'p-2 pl-3';
  const iconStyle = minimal ? 'ml-1' : 'ml-2';
  return (
    <div
      className={`relative rounded-md bg-white border border-platinum mr-2 shadow cursor-pointer ${styles} ${padding}`}
      onClick={onClick}
    >
      <div className={`flex justify-center items-end leading-5 text-xs ${titleStyles}`}>
        {title}
        <XIcon className={`h-5 w-5 text-primary ${iconStyle}`} aria-hidden="true" />
      </div>
    </div>
  );
};

export default Badge;
