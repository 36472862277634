import Image from 'next/image';

interface Testimonial {
  title: string;
  description: string;
  name: string;
  userImage: string;
}

interface Props {
  imageSide?: 'right' | 'left';
  testimonial: Testimonial;
}

const TestimonialsBannerWithImage: React.FC<Props> = ({
  testimonial,
  imageSide = 'left',
}: Props) => {
  return (
    <div className="bg-transparent pt-16 lg:py-24">
      <div className="pb-16 bg-primary lg:pb-0 lg:z-10 lg:relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
          {imageSide === 'right' ? (
            <div className="block lg:hidden relative lg:-my-8">
              <div
                aria-hidden="true"
                className="absolute w-full top-0 h-1/2 bg-white lg:hidden z-10"
              />
              <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
                <div className="relative lg:initial rounded-xl overflow-hidden lg:h-full z-20">
                  <div className="block lg:hidden">
                    <Image
                      className="object-cover lg:h-full lg:w-full rounded-xl"
                      alt="Testimonial Image"
                      height={250}
                      layout="responsive"
                      src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80"
                      width={384}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {imageSide === 'left' ? (
            <div className="relative lg:-my-8">
              <div
                aria-hidden="true"
                className="absolute w-full top-0 h-1/2 bg-white lg:hidden z-10"
              />
              <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
                <div className="relative lg:initial rounded-xl overflow-hidden lg:h-full z-20">
                  <div className="hidden lg:block overflow-hidden rounded-xl">
                    <Image
                      className="object-cover lg:h-full lg:w-full rounded-xl"
                      alt="Testimonial Image"
                      height={464}
                      layout="fixed"
                      src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80"
                      width={384}
                    />
                  </div>
                  <div className="block lg:hidden">
                    <Image
                      className="object-cover lg:h-full lg:w-full rounded-xl"
                      alt="Testimonial Image"
                      height={250}
                      layout="responsive"
                      src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80"
                      width={384}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
              <blockquote>
                <div>
                  <svg
                    className="h-12 w-12 text-white opacity-25"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="mt-6 text-xl xl:text-2xl font-medium text-white">
                    {testimonial?.description}
                  </p>
                </div>
                <footer className="mt-6">
                  <p className="text-base font-medium text-white">{testimonial?.name}</p>
                  <p className="text-base font-medium text-white">{testimonial?.title}</p>
                </footer>
              </blockquote>
            </div>
          </div>

          {imageSide === 'right' ? (
            <div className="hidden lg:block relative lg:-my-8">
              <div
                aria-hidden="true"
                className="absolute w-full top-0 h-1/2 bg-white lg:hidden z-10"
              />
              <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
                <div className="relative rounded-xl overflow-hidden lg:h-full z-20">
                  <Image
                    className="object-cover lg:h-full lg:w-full rounded-xl"
                    alt="Testimonial Image"
                    height={464}
                    layout="fixed"
                    src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80"
                    width={384}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsBannerWithImage;
