/* This component requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Button } from 'src/components/ui';

interface Props {
  children: React.ReactNode;
  closeButtonTitle?: string;
  footer?: React.ReactNode;
  isOpen?: boolean;
  isSubmitDisabled?: boolean;
  onClose?: () => void;
  onSubmit?: (e: React.MouseEvent<HTMLElement>) => void;
  showButtons?: boolean;
  size?: 'md' | 'lg' | 'xl';
  submitButtonTitle?: string;
  title?: React.ReactNode | string;
}

const Modal: React.FC<Props> = ({
  children = null,
  closeButtonTitle = 'Close',
  isOpen = false,
  isSubmitDisabled = false,
  footer = null,
  onClose,
  onSubmit,
  showButtons = true,
  submitButtonTitle = 'Submit',
  size = 'md',
  title = '',
}: Props) => {
  const [open, setOpen] = useState(isOpen);
  const footerStyles = footer ? 'mb-10' : 'mb-0';
  const sizeStyles = size === 'md' ? 'max-w-xl' : size === 'lg' ? 'max-w-6xl' : 'max-w-8xl';

  if (isOpen !== open) setOpen(isOpen);

  const handleOnClose = (): void => {
    if (onClose) onClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-40 inset-0 overflow-y-auto"
        open={open}
        onClose={handleOnClose}
      >
        <div className="block min-h-screen pt-4 px-4 pb-20 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block align-middle bg-white rounded-lg p-6 overflow-hidden shadow-xl transform transition-all w-full ${sizeStyles}`}
            >
              <div className="block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 mt-2 mr-2 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  onClick={() => handleOnClose()}
                >
                  <span className="sr-only">Close</span>
                  <XIcon
                    className="h-6 w-6 text-slate"
                    aria-hidden="true"
                    onClick={() => handleOnClose()}
                  />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="text-center w-full">
                  <Dialog.Title
                    as="h3"
                    className="text-lg text-center leading-6 font-medium text-steel"
                  >
                    {title}
                  </Dialog.Title>
                  {children}
                </div>
              </div>
              {showButtons ? (
                <div
                  className={`flex flex-col md:flex-row mt-5 justify-center md:justify-evenly items-center ${footerStyles}`}
                >
                  <Button
                    fullWidth
                    href="#"
                    onClick={() => handleOnClose()}
                    size="md"
                    styles="w-full md:w-1/2 border-none mx-1"
                  >
                    {closeButtonTitle}
                  </Button>
                  <Button
                    disabled={isSubmitDisabled}
                    fullWidth
                    href="#"
                    onClick={e => onSubmit(e)}
                    primary
                    size="md"
                    styles="w-full md:w-1/2 mx-1 mt-4 md:mt-0"
                  >
                    {submitButtonTitle}
                  </Button>
                </div>
              ) : null}

              {footer}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
