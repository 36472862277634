import Image from 'next/image';
import { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { Message } from 'src/types';

interface Props {
  messages?: Message[];
  onSelect?: (message: Message) => void;
  onCheckBoxClick?: (message: Message, isChecked: boolean) => void;
  isEditMode?: boolean;
}

const StackedAvatarList: React.FC<Props> = ({
  messages = [],
  onSelect,
  onCheckBoxClick,
  isEditMode = false,
}: Props) => {
  const [selected, setSelected] = useState(messages[0]);
  const isActiveOption = (option: Message): boolean => option?.vehicleId === selected?.vehicleId;
  const getLastMessage = (message: Message): string => {
    const history = message?.history || [];
    const lastMessage = history[history?.length - 1]?.message;
    return lastMessage;
  };

  const handleOnClick = (option: Message): void => {
    setSelected(option);
    if (onSelect && !isEditMode) onSelect(option);
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {messages?.map((message, index) => (
          <li
            className={`cursor-pointer ${isActiveOption(message) ? 'bg-barely-blue' : 'bg-white'}`}
            key={`${message?.title}-${index}`}
            onClick={() => handleOnClick(message)}
          >
            <div className="flex items-center px-4 py-4 md:px-6">
              <div className="min-w-0 flex-1 flex items-center">
                {isEditMode && (
                  <input
                    type="checkbox"
                    className="-ml-0 mr-4 rounded-sm border-silver"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onCheckBoxClick(message, e?.target?.checked)
                    }
                  />
                )}
                <div className="flex-shrink-0 h-12 w-12 rounded-md overflow-hidden">
                  <Image
                    alt="message-icon"
                    height={48}
                    layout="responsive"
                    src={message?.vehicleImage || '/svg/sal-logo-mark.svg'}
                    width={48}
                  />
                </div>
                <div className="min-w-0 flex-1 pl-4">
                  <div>
                    <p
                      className={`text-sm md:text-xs lg:text-sm font-bold truncate ${
                        isActiveOption(message) ? 'text-primary' : 'text-steel'
                      }`}
                    >
                      {message?.title || ''}
                    </p>
                    <p className="mt-2 flex items-center text-sm md:text-xs lg:text-sm text-slate font-medium">
                      <span className="truncate">{getLastMessage(message)}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StackedAvatarList;
