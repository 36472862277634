import { AppLayout } from 'src/components';
import Link from 'next/link';

interface Props {
  title: string;
  description: string;
  href?: string;
  linkText?: string;
}

const Message: React.FC<Props> = ({ title, description, href, linkText }) => {
  return (
    <AppLayout>
      <div className="bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 h-96">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src="/svg/sal-logo-text.svg" alt="Swapalease" />
          <h2 className="mt-6 text-center text-lg font-extrabold text-gray-900">{title}</h2>
          <p className="mt-6 text-center text-gray-900">{description}</p>
          {href && linkText ? (
            <p className="mt-6 text-center text-primary">
              <Link href={href}>
                <a>{linkText}</a>
              </Link>
            </p>
          ) : null}
        </div>
      </div>
    </AppLayout>
  );
};

export default Message;
