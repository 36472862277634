import Link from 'next/link';
import Image from 'next/image';
import { Button } from 'src/components/ui';

interface Props {
  options: Card[];
}

interface Card {
  description: string;
  imageUrl: string;
  link: string;
  title: string;
}

const AdditionalResources: React.FC<Props> = ({ options = [] }: Props) => {
  const renderCard = (card: Card, index: number): React.ReactNode => (
    <Link href={card.link} key={index}>
      <div className="w-full h-auto sm:w-96 sm:h-96 bg-white shadow-lg overflow-hidden rounded-md mb-5 sm:mb-12 mx-2 md:mx-5 cursor-pointer">
        <div className="relative w-full h-48 sm:h-40">
          <Image alt="category-image" layout="fill" src={card.imageUrl} />
        </div>
        <div className="px-8 py-6">
          <h2 className="font-semibold text-xl sm:text-2xl pb-3">{card.title}</h2>
          <div className="font-medium text-slate text-sm sm:text-base">{card.description}</div>
        </div>
      </div>
    </Link>
  );

  return (
    <div className="flex flex-col items-center w-full bg-frost pt-24">
      <h1 className="font-extrabold text-charcoal text-3xl text-center px-4">
        More Learning Resources
      </h1>
      <div className="max-w-screen-sm px-10 text-center mt-4 text-slate text-xl leading-7">
        These resources might interest you
      </div>
      <div className="flex justify-center flex-wrap mt-12">
        {options.map((option, index) => renderCard(option, index))}
      </div>
      <div className="flex justify-center w-full mt-4 mb-28">
        <Button href="/learn" size="md" scroll primary>
          <div className="pl-2 sm:pl-10 pr-2 sm:pr-7">View All Learning Resources</div>
        </Button>
      </div>
    </div>
  );
};

export default AdditionalResources;
