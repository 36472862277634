import Link from 'next/link';
import { Button, Divider } from 'src/components/ui';
import { ChevronRightIcon } from '@heroicons/react/solid';

interface Article {
  title: string;
  date: string;
  description: string;
  link: string;
}

interface Props {
  articles: Article[];
}

const RelatedArticles: React.FC<Props> = ({ articles = [] }: Props) => {
  const getDateString = (dateString: string): string => {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day} ${year}`;
  };

  const renderCard = (article: Article, index: number): React.ReactNode => (
    <div className="w-full sm:w-1/2 px-2 pb-12" key={`${article?.title}-${index}`}>
      <div className="text-slate text-sm">{getDateString(article?.date)}</div>
      <h3 className="font-bold text-charcoal text-lg mt-2 mb-3">{article?.title}</h3>
      <div className="font-medium text-slate mb-3">{article?.description}</div>
      <Link href={article?.link}>
        <div className="font-extrabold text-primary cursor-pointer">Read Full Article</div>
      </Link>
    </div>
  );

  return (
    <div className="w-full">
      <h1 className="font-extrabold text-charcoal text-2xl">Related Articles</h1>
      <Divider styles="mt-6 mb-10" />
      <div className="flex flex-wrap">
        {articles.map((article, index: number) => renderCard(article, index))}
      </div>
      <div className="flex justify-center w-full mt-4 mb-16">
        <Button href="/learn/articles" size="md" scroll>
          <div className="pl-12 sm:pl-20">View All Articles</div>
          <ChevronRightIcon
            className="h-6 w-6 ml-2 mr-8 sm:mr-16 text-primary"
            aria-hidden="true"
          />
        </Button>
      </div>
    </div>
  );
};

export default RelatedArticles;
