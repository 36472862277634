import { useSession } from 'next-auth/client';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { TextLink } from 'src/components/ui';

type Questions = {
  question: string;
  answer: string;
};

interface Props {
  questions: Questions[];
}

const QuestionsAndAnswers: React.FC<Props> = ({ questions = [] }: Props) => {
  const [session] = useSession();

  return (
    <div className="flex flex-col items-start justify-evenly w-auto">
      <h2 className="text-xl mt-6 mb-6 pb-6 font-bold uppercase border-b border-platinum border-solid w-full">
        Q&amp;A Feed
      </h2>
      {session ? (
        <ul className="w-full">
          {questions?.map(({ answer = '', question = '' }, index) => {
            if (answer === '') return null;
            return (
              <li
                className={`text-charcoal mb-4 pb-4 w-full border-0 ${
                  index !== questions.length - 1 ? 'border-b' : ''
                } border-platinum border-solid`}
                key={question}
              >
                <div className="flex items-center font-semibold">
                  <QuestionMarkCircleIcon
                    className="w-9 h-9 mr-3 text-sky-blue flex-shrink-0 rounded-full border-8 border-sky-blue"
                    aria-hidden="true"
                  />
                  <div className="font-medium mr-4">{question}</div>
                  <div className="ml-auto font-bold mr-4">{answer ? 'Yes' : 'No'}</div>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="font-medium text-charcoal">
          <TextLink href="/create-account" size="md">
            Sign up
          </TextLink>{' '}
          or{' '}
          <TextLink href="/sign-in" size="md">
            Log in
          </TextLink>{' '}
          to see Q&amp;A Feed
        </p>
      )}
    </div>
  );
};

export default QuestionsAndAnswers;
