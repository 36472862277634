interface Option {
  id: string;
  value: string;
}

const leaseTypeOptions = ['Lease Transfer', 'New Lease Proposal'];
const locationDistanceOptions = ['50', '100', '150', '200', '250', '300'];
const monthsRemainingOptions = [
  [0, 12],
  [13, 24],
  [25, 36],
  [37, 48],
  [49, 9999],
];
const makeOptions = [
  'Acura',
  'Alfa Romeo',
  'Audi',
  'BMW',
  'Bently',
  'Buick',
  'Cadillac',
  'Chevrolet',
  'Chrysler',
  'Daewoo',
  'Dodge',
  'DS',
  'Ferrari',
  'Fiat',
  'Fisker',
  'Ford',
  'Honda',
  'Hummer',
  'Hyundai',
  'Infiniti',
  'Jaguar',
  'Jeep',
  'Kia',
  'Lamborghini',
  'Lancia',
  'Land Rover',
  'Lexus',
  'Lincoln',
  'Lotus',
  'Maserati',
  'Maybach',
  'Mazda',
  'McLaren',
  'Mercedes-Benz',
  'MG',
  'Mini',
  'Mitsubishi',
  'Morgan',
  'Nissan',
  'Peugeot',
  'Porsche',
  'Renault',
  'Rolls-Royce',
  'Rover',
  'Saab',
  'Smart',
  'Subaru',
  'Suzuki',
  'Tesla',
  'Toyota',
  'Volkswagen',
  'Volvo',
];

const colorOptions = [
  {
    value: 'Black',
    id: 'Black',
    color: 'vehicle-black',
    refId: 1,
  },
  {
    value: 'Dark Brown',
    id: 'Dk. Brown',
    color: 'vehicle-brown-dark',
    refId: 817,
  },
  {
    value: 'Brown',
    id: 'Brown',
    color: 'vehicle-brown',
    refId: 54,
  },
  {
    value: 'Light Brown',
    id: 'Lt. Brown',
    color: 'vehicle-brown-light',
    refId: 340,
  },
  {
    value: 'Silver',
    id: 'Silver',
    color: 'vehicle-silver',
    refId: 3,
  },
  {
    value: 'White',
    id: 'White',
    color: 'vehicle-white',
    refId: 5,
  },
  {
    value: 'Off White',
    id: 'Off White',
    color: 'vehicle-white-off',
    refId: 108,
  },
  {
    value: 'Light Gray',
    id: 'Lt. Gray',
    color: 'vehicle-gray-light',
    refId: 138,
  },
  {
    value: 'Gray',
    id: 'Gray',
    color: 'vehicle-gray',
    refId: 4,
  },
  {
    value: 'Dark Gray',
    id: 'Dk. Gray',
    color: 'vehicle-gray-dark',
    refId: 30,
  },
  {
    value: 'Maroon',
    id: 'Maroon',
    color: 'vehicle-maroon',
    refId: 103,
  },
  {
    value: 'Dark Red',
    id: 'Dk. Red',
    color: 'vehicle-red-dark',
    refId: 79,
  },
  {
    value: 'Red',
    id: 'Red',
    color: 'vehicle-red',
    refId: 2,
  },
  {
    value: 'Orange',
    id: 'Orange',
    color: 'vehicle-orange',
    refId: 37,
  },
  {
    value: 'Beige',
    id: 'Beige',
    color: 'vehicle-beige',
    refId: 25,
  },
  {
    value: 'Tan',
    id: 'Tan',
    color: 'vehicle-tan',
    refId: 78,
  },
  {
    value: 'Yellow',
    id: 'Yellow',
    color: 'vehicle-yellow',
    refId: 15,
  },
  {
    value: 'Gold',
    id: 'Gold',
    color: 'vehicle-gold',
    refId: 6,
  },
  {
    value: 'Dark Green',
    id: 'Dark Green',
    color: 'vehicle-green-dark',
    refId: 21,
  },
  {
    value: 'Green',
    id: 'Green',
    color: 'vehicle-green',
    refId: 18,
  },
  {
    value: 'Light Green',
    id: 'Lt. Green',
    color: 'vehicle-green-light',
    refId: 138,
  },
  {
    value: 'Light Blue',
    id: 'Lt. Blue',
    color: 'vehicle-blue-light',
    refId: 112,
  },
  {
    value: 'Blue',
    id: 'Blue',
    color: 'vehicle-blue',
    refId: 7,
  },
  {
    value: 'Dark Blue',
    id: 'Dk. Blue',
    color: 'vehicle-blue-dark',
    refId: 74,
  },
  {
    value: 'Purple',
    id: 'Purple',
    color: 'vehicle-purple',
    refId: 90,
  },
];

const statesOptions = [
  'ALABAMA',
  'ALASKA',
  'ARIZONA',
  'ARKANSAS',
  'CALIFORNIA',
  'COLORADO',
  'CONNECTICUT',
  'DELAWARE',
  'FLORIDA',
  'GEORGIA',
  'HAWAII',
  'IDAHO',
  'ILLINOIS',
  'INDIANA',
  'IOWA',
  'KANSAS',
  'KENTUCKY',
  'LOUSIANA',
  'MAINE',
  'MARYLAND',
  'MASSACHUSETTS',
  'MICHIGAN',
  'MINNESOTA',
  'MISSISSIPPI',
  'MISSOURI',
  'MONTANA',
  'NEBRASKA',
  'NEVADA',
  'NEW HAMPSHIRE',
  'NEW JERSY',
  'NEW MEXICO',
  'NEW YORK',
  'NORTH CAROLINA',
  'NORTH DAKOTA',
  'OHIO',
  'OKLAHOMA',
  'OREGON',
  'PENNSYLVANIA',
  'RHODE ISLAND',
  'SOUTH CAROLINA',
  'SOUTH DAKOTA',
  'TENNESSEE',
  'TEXAS',
  'UTAH',
  'VERMONT',
  'VIRGINIA',
  'WASHINGTON',
  'WEST VIRGINIA',
  'WISCONSIN',
  'WYOMING',
];

const getYears = (startYear = 1955) => {
  const currentYear = new Date().getFullYear();
  const years = [];
  while (startYear <= currentYear) {
    years.push(`${startYear++}`);
  }
  return years.reverse();
};

const yearOptions = getYears();

const buildInputOptions = (options: string[]): Option[] =>
  options?.map(option => ({
    id: option,
    value: option,
  }));

export {
  buildInputOptions,
  colorOptions,
  leaseTypeOptions,
  locationDistanceOptions,
  makeOptions,
  monthsRemainingOptions,
  statesOptions,
  yearOptions,
};
