import { LeaseCard } from 'src/components';
import { Vehicle } from 'src/types';
import Link from 'next/link';

interface Props {
  vehicles?: Vehicle[];
  onUpdate?: () => void;
}

const LeaseGrid: React.FC<Props> = ({ vehicles, onUpdate }: Props) => (
  <div className="mx-auto max-w-6xl">
    <div className="space-y-12">
      <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
        {vehicles?.map((vehicle: Vehicle, index: number) => (
          <li key={`${vehicle?.vehicleId}-${index}`}>
            <Link href={`/listing/${vehicle?.vehicleId}`}>
              <a>
                <LeaseCard vehicle={vehicle} onUpdate={onUpdate} />
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
);
export default LeaseGrid;
