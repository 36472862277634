// disclosure - https://headlessui.dev/react/disclosure

import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Badge } from 'src/components/ui';

interface Props {
  children?: React.ReactNode;
  filters?: string[];
  styles?: string;
  title?: string;
  onBadgeClick: (filter: string) => void;
}

const FilterDisclosureArea: React.FC<Props> = ({
  children,
  filters = [],
  onBadgeClick,
  styles,
  title,
}: Props) => {
  const renderBadgeSection = (open: boolean): React.ReactNode => {
    const badgeContainerStyling = filters.length && !open ? 'pb-4' : open ? 'pb-0' : 'pb-4';
    return (
      <div className={`flex flex-wrap items-center w-full ${badgeContainerStyling}`}>
        {!open &&
          filters?.map((filter, index) => (
            <div className="ml-2" key={index} onClick={() => onBadgeClick(filter)}>
              <Badge title={filter} styles="mb-2 shadow-none" minimal />
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className={`w-full mx-auto bg-white pt-2 ${styles}`}>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full text-sm font-bold text-left pt-4 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75">
              <span className="ml-2">{title}</span>
              <ChevronDownIcon
                className={`${
                  open ? 'transform -rotate-90' : ''
                } w-6 h-6 text-primary mr-2 md:mr-0`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-sm text-gray-500">{children}</Disclosure.Panel>
            {renderBadgeSection(open)}
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default FilterDisclosureArea;
