const leasing101Chapters = [
  {
    href: '/learn/leasing-101',
    name: 'Lease 101 Home',
  },
  {
    href: '/learn/leasing-101-chapter-1-lease-basics',
    name: 'Chapter 1: Lease Basics',
  },
  {
    href: '/learn/leasing-101-chapter-2-lease-vs-loan',
    name: 'Chapter 2: Lease vs Loan',
  },
  {
    href: '/learn/leasing-101-chapter-3-calculating-your-monthly-lease-payment',
    name: 'Chapter 3: Calculating your Monthly Lease Payment',
  },
  {
    href: '/learn/leasing-101-chapter-4-assuming-a-lease',
    name: 'Chapter 4: Assuming a Lease',
  },
  {
    href: '/learn/leasing-101-chapter-5-shopping-for-your-vehicle',
    name: 'Chapter 5: Shopping for Your Vehicle',
  },
  {
    href: '/learn/leasing-101-chapter-6-shopping-for-your-insurance',
    name: 'Chapter 6: Shopping for Your Insurance',
  },
  {
    href: '/learn/leasing-101-chapter-7-shopping-for-your-lease',
    name: 'Chapter 7: Shopping for Your Lease',
  },
  {
    href: '/learn/leasing-101-chapter-8-negotiating-the-best-deal',
    name: 'Chapter 8: Negotiating the Best Deal',
  },
  {
    href: '/learn/leasing-101-chapter-9-understanding-your-lease-contract',
    name: 'Chapter 9: Understanding Your Lease Contract',
  },
  {
    href: '/learn/leasing-101-chapter-10-understanding-your-credit',
    name: 'Chapter 10: Understanding Your Credit',
  },
  {
    href: '/learn/leasing-101-chapter-11-how-to-exit-your-lease-early',
    name: 'Chapter 11: How to Exit your Lease Early',
  },
  {
    href: '/learn/leasing-101-chapter-12-lease-end-options',
    name: 'Chapter 12: Lease End Options',
  },
  {
    href: '/learn/lease-glossary',
    name: 'Lease Glossary',
  },
  {
    href: '/learn/car-lease-payment-calculator',
    name: 'Car Lease Payment Calculator',
  },
];

export default leasing101Chapters;
