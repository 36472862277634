import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { classNames } from 'src/utils/classNames';

import chapterOptions from 'src/mocks/leasing101Chapters';

const Leasing101Menu: React.FC = () => (
  <Popover className="z-0 relative">
    {({ open }) => (
      <>
        <div className="relative z-10 bg-white shadow">
          <div className="max-w-6xl mx-auto flex px-4 py-6 sm:px-6 lg:px-8">
            <Popover.Button
              className={classNames(
                open ? 'text-sky-blue' : 'text-primary',
                'group bg-white font-bold rounded-md inline-flex items-center text-base font-medium hover:text-sky-blue focus:outline-none'
              )}
            >
              <span>Lease 101 Table of Contents</span>
              <ChevronDownIcon
                className={classNames(
                  open ? 'text-gray-600' : 'text-gray-400',
                  'ml-2 h-5 w-5 group-hover:text-gray-500'
                )}
                aria-hidden="true"
              />
            </Popover.Button>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 -translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-1"
        >
          <Popover.Panel className="absolute z-10 inset-x-0 transform">
            <div className="absolute inset-0 flex" aria-hidden="true">
              <div className="bg-white w-full xl:mr-96 shadow-lg" />
            </div>

            <div className="relative mx-auto">
              <nav
                className="bg-white sm:pt-4 pb-8 px-4 lg:px-8 xl:mr-96"
                aria-labelledby="solutions-heading"
              >
                <h2 id="solutions-heading" className="sr-only">
                  Lease 101 Table Of Contents
                </h2>

                <div className="flex flex-col flex-wrap justify-start items-start lg:w-1/2 lg:h-64">
                  {chapterOptions.map(item => (
                    <div key={item.name} className="pr-2 lg:pr-8 py-1">
                      <a
                        href={item.href}
                        className="-m-3 py-3 flex items-center rounded-md text-base font-medium text-primary hover:text-sky-blue transition ease-in-out duration-150"
                      >
                        <span className="ml-4">{item.name}</span>
                      </a>
                    </div>
                  ))}
                </div>
              </nav>
            </div>
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
);

export default Leasing101Menu;
