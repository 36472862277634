import Image from 'next/image';
import Link from 'next/link';
import { Button, Divider, FlyoutMenu, NavLink, DisclosureArea, TextLink } from 'src/components/ui';
import { ContactSpecialistBanner } from 'src/components';
import { Fragment } from 'react';
import { signIn, signOut, useSession } from 'next-auth/client';

import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  ArrowNarrowRightIcon,
  ClipboardListIcon,
  ExclamationCircleIcon,
  MenuIcon,
  XIcon,
  ThumbUpIcon,
  BookOpenIcon,
  SearchIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/outline';
import utils from 'src/utils';
import { useNotificationDispatch, updateNotification } from 'src/providers/NotificationProvider';

const Navigation: React.FC = () => {
  const [session] = useSession();
  const dispatch = useNotificationDispatch();

  const signUserOut = (): void => {
    signOut();
    dispatch(
      updateNotification({
        title: 'Signed Out',
        icon: 'success',
        show: true,
        description: 'You have been successfully signed out!',
      })
    );
  };

  const avatar = session?.user?.image ? (
    <Image
      height="48"
      width="48"
      className="h-10 w-10 rounded-full"
      src={session.user.image}
      alt="Profile Image"
    />
  ) : (
    <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
      <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    </span>
  );

  const learnMenuOptions = [
    {
      name: 'Lease 101',
      description: 'Tools to help with your car lease.',
      href: '/learn/leasing-101',
      icon: <BookOpenIcon className="mr-2 text-primary h-6 w-6" aria-hidden="true" />,
    },
    {
      name: 'Buyer & Seller Tips',
      description: 'Learn the ins and outs of getting out of your car lease.',
      href: '/learn/buyer-seller-tips',
      icon: <ThumbUpIcon className="mr-2 text-primary h-6 w-6" aria-hidden="true" />,
    },
    {
      name: 'Lease Company Information',
      description: 'Learn the ins and outs of getting into a car lease.',
      href: '/learn/lease-company-information',
      icon: <ClipboardListIcon className="mr-2 text-primary h-6 w-6" aria-hidden="true" />,
    },
    {
      name: 'FAQs',
      description: 'Frequently asked questions about Swapalease.',
      href: '/learn/faq',
      icon: <QuestionMarkCircleIcon className="mr-2 text-primary h-6 w-6" aria-hidden="true" />,
    },
  ];

  const accountMenuOptions = [
    {
      name: 'Profile',
      href: '/my-account/profile',
    },
    {
      name: 'Buyer Membership',
      href: '/my-account/buyer-membership',
    },
    {
      name: 'Preferences',
      href: '/my-account/preferences',
    },
    {
      name: 'Messages',
      href: '/my-account/messages',
    },
    {
      name: 'My Listings',
      href: '/my-account/my-listings',
    },
    {
      name: 'Watchlist',
      href: '/my-account/watchlist',
    },
    {
      name: 'Saved Searches',
      href: '/my-account/saved-searches',
    },
  ];

  const learnMenuChildren = (
    <div className="w-full h-auto bg-frost pt-8 px-8 pb-2">
      <div className="font-semibold text-slate text-sm uppercase">OTHER Resources</div>
      <TextLink href="/learn/testimonials" size="md" scroll>
        <div className="font-bold mt-4">Testimonials</div>
      </TextLink>
      <TextLink href="/learn/helpful-tools" size="md" scroll>
        <div className="font-bold mt-4">Helpful Tools</div>
      </TextLink>
      <TextLink href="/learn/fraud-prevention" size="md" scroll>
        <div className="font-bold mt-4">Fraud Prevention</div>
      </TextLink>
      <TextLink href="/learn" size="md" scroll>
        <div className="flex mx-auto mt-4">
          <div className="font-extrabold text-primary mb-6 cursor-pointer">
            View all Learning Resources
          </div>
          <ArrowNarrowRightIcon className="h-4 w-4 ml-2 mt-1 text-primary" aria-hidden="true" />
        </div>
      </TextLink>
      <ContactSpecialistBanner />
    </div>
  );

  const learnMobileMenuTitle = (
    <div className="flex w-full ml-3 font-extrabold text-base text-steel">
      <ExclamationCircleIcon className="text-primary h-6 w-6 mr-2" aria-hidden="true" />
      Learn More
    </div>
  );

  return (
    <Disclosure as="nav" className="relative bg-white shadow z-40">
      {({ open }) => (
        <>
          <div className="max-w-6xl mx-auto px-4">
            <div className="flex justify-between h-16">
              <div className="flex w-full">
                <div className="flex-shrink-0 flex items-center">
                  <div className="h-8 w-auto">
                    <Link href="/">
                      <a>
                        <Image
                          src="/svg/sal-logo-text.svg"
                          alt="Swapalease logo"
                          width="176"
                          height="34"
                        />
                      </a>
                    </Link>
                  </div>
                </div>

                <div className="hidden md:ml-6 md:flex md:space-x-8 justify-evenly w-full">
                  <NavLink title="Browse Leases" to="/search" />
                  <NavLink title="How it Works" to="/learn/how-it-works" />
                  <div className="border-transparent text-charcoal hover:border-primary hover:bg-secondary hover:text-secondary inline-flex items-center px-1 pt-1 border-b-2 text-sm font-bold z-40">
                    <FlyoutMenu title="Learn" options={learnMenuOptions}>
                      {learnMenuChildren}
                    </FlyoutMenu>
                  </div>
                </div>
              </div>

              <div className="flex flex-row w-16 md:w-96 items-center justify-end">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>

                <div className="hidden md:block">
                  <Button href="/create" primary size="md">
                    List My Lease
                  </Button>
                </div>
                {/* Profile dropdown */}

                {session ? (
                  <div className="hidden md:block w-16">
                    <Menu as="div" className="ml-3 relative z-40">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                              <span className="sr-only">Open user menu</span>
                              <div className="h-9 w-9 rounded-full overflow-hidden">{avatar}</div>
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              {accountMenuOptions.map((item, idx) => (
                                <Menu.Item key={idx}>
                                  {({ active }) => (
                                    <Link href={item.href}>
                                      <a
                                        className={utils.classNames(
                                          active ? 'bg-secondary' : '',
                                          'block px-4 py-2 text-sm text-gray-700'
                                        )}
                                      >
                                        {item.name}
                                      </a>
                                    </Link>
                                  )}
                                </Menu.Item>
                              ))}

                              <Divider />

                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    className={utils.classNames(
                                      active ? 'bg-secondary' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                    onClick={() => signUserOut()}
                                  >
                                    Sign Out
                                  </button>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                ) : (
                  <div className="hidden md:block w-18">
                    <button
                      className="block px-4 py-2 text-sm font-bold text-primary"
                      onClick={() => signIn()}
                    >
                      Sign In
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="pt-2 pb-3 space-y-1">
              <Link href="/search">
                <a className="font-extrabold flex text-charcoal pl-3 pr-4 py-6" href="/search">
                  <SearchIcon className="text-primary h-6 w-6 mr-2" aria-hidden="true" />
                  Browse Leases
                </a>
              </Link>
              <Divider />
              <a
                href="/learn/how-it-works"
                className="font-extrabold flex text-charcoal pl-3 pr-4 py-6"
              >
                <ClipboardListIcon className="text-primary h-6 w-6 mr-2" aria-hidden="true" />
                How it Works
              </a>
              <Divider />
              <DisclosureArea title={learnMobileMenuTitle} styles="block md:hidden -ml-2">
                <div className="flex flex-col space-y-8 ml-10 mt-4 z-40">
                  <TextLink href="/learn/leasing-101" size="md" scroll>
                    <div className="font-extrabold text-charcoal ml-3">Lease 101</div>
                  </TextLink>
                  <TextLink href="/learn/buyer-seller-tips" size="md" scroll>
                    <div className="font-extrabold text-charcoal ml-3">Buyer & Seller Tips</div>
                  </TextLink>
                  <TextLink href="/learn/lease-company-information" size="md" scroll>
                    <div className="font-extrabold text-charcoal ml-3">
                      Lease Company Information
                    </div>
                  </TextLink>
                  <TextLink href="/learn/faq" size="md" scroll>
                    <div className="font-extrabold text-charcoal ml-3">FAQ</div>
                  </TextLink>
                  <TextLink href="/learn" size="md" scroll>
                    <div className="font-extrabold text-charcoal ml-3 pb-4">
                      View All Learning Resources
                    </div>
                  </TextLink>
                </div>
              </DisclosureArea>
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="w-full flex justify-center py-2">
                <Button
                  primary
                  size="lg"
                  href="/create"
                  styles="hidden xs:block w-full px-4"
                  fullWidth
                >
                  <div className="py-1">List My Lease</div>
                </Button>
                <Button primary size="sm" href="/create" styles="xs:hidden w-full px-4" fullWidth>
                  <div className="py-1">List My Lease</div>
                </Button>
              </div>
              {session ? (
                <>
                  <div className="flex items-center px-4 sm:px-6">
                    <div className="flex-shrink-0">{avatar}</div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">
                        {session?.user?.name}
                      </div>
                      <div className="text-sm font-medium text-gray-500">
                        {session?.user?.email}
                      </div>
                    </div>
                  </div>

                  <div className="mt-3 space-y-1">
                    <Link href="/my-account/profile">
                      <a className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6 w-full">
                        <div className="mx-auto text-gray-500">My Account</div>
                      </a>
                    </Link>
                    <button
                      className="block px-4 sm:px-6 py-2 text-md text-gray-700 w-full"
                      onClick={() => signUserOut()}
                    >
                      <div className="mx-auto font-extrabold text-base text-primary">Sign Out</div>
                    </button>
                  </div>
                </>
              ) : (
                <button
                  className="block px-4 sm:px-6 py-2 text-md font-bold text-primary w-full"
                  onClick={() => signIn()}
                >
                  <div className="mx-auto text-gray-500">
                    Existing customer?{' '}
                    <span className="font-extrabold text-base text-primary">Sign In</span>
                  </div>
                </button>
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navigation;
