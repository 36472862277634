import { getFormattedDateText } from 'src/utils/dateUtils'
import { isFuture } from 'date-fns';
import { BillingState } from 'src/types';

interface Transaction {
  accountType: string;
  authAmount: string;
  settleAmount: number;
  transId: string;
}

interface TransactionData {
  submitTimeLocal: string;
  transaction: Transaction;
  settleAmount: string;
}

interface PaymentInfo {
  amount: string;
  date: string;
}

const getCardImage = (cardType: string): string => {
  if (cardType === 'Visa') return `/visa.png`;
  if (cardType === 'AmericanExpress') return `/american-express.png`;
  if (cardType === 'Discover') return `/discover.png`;
  if (cardType === 'MasterCard') return `/master-card.png`;
  return '/default-card.png';
};

const isPaymentFormComplete = (state: BillingState) => {
  const year = parseInt(`20${state?.year}`);
  const month = parseInt(state?.month) - 1;
  const date = new Date(year, month);

  const isIncomplete =
    state?.cardNumber?.length < 13 ||
    state?.month?.length < 1 ||
    state?.year?.length < 2 ||
    state?.cvc?.length < 3 ||
    !state?.name ||
    !state?.address ||
    !state?.city ||
    !state?.state ||
    state?.zip?.length < 5 ||
    !isFuture(date);

  return !isIncomplete;
}

const getCardCode = (cardNumber: string): string => {
  if (cardNumber?.length >= 8) return `${cardNumber[4]}${cardNumber[5]}${cardNumber[6]}${cardNumber[7]}`;
  return 'XXXX';
};

const getPaymentText = (currentTransaction: TransactionData): PaymentInfo => {
  const payment = { amount: '', date: '' };

  // Use the authnet transaction authAmount if available
  if (currentTransaction?.transaction?.authAmount) {
    payment.amount = `$${currentTransaction?.transaction?.authAmount}`;
  }

  // If no authAmount try to use settleAmount
  if (currentTransaction?.settleAmount && !payment.amount) {
    payment.amount = `$${currentTransaction?.settleAmount}`;
  }

  // Get transaction date
  if (payment.amount && currentTransaction?.submitTimeLocal) {
    payment.date = getFormattedDateText(currentTransaction?.submitTimeLocal);
  }

  return payment;
};

export { isPaymentFormComplete, getCardImage, getCardCode, getPaymentText };
