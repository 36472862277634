import Image from 'next/image';
import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import utils from 'src/utils';

type Option = {
  id?: string | number;
  value?: string;
  color?: string;
  icon?: string;
};

interface Props {
  disabled?: boolean;
  onChange?: (arg: Option) => void;
  options: Option[];
  placeholder?: string;
  selectedOption?: Option;
  styles?: string;
  title?: string;
}

const SelectMenu: React.FC<Props> = ({
  disabled,
  onChange,
  options,
  placeholder,
  selectedOption,
  styles,
  title = '',
}: Props) => {
  const disabledStyles = disabled ? 'opacity-40' : '';

  const renderOption = (option: Option, selected: boolean): React.ReactNode => {
    const iconSpacing = option.color || option.icon ? 'ml-3' : '';
    const valueText = (
      <span
        className={utils.classNames(
          selected ? 'font-bold' : 'font-medium',
          `block truncate ${iconSpacing}`
        )}
      >
        {option.value}
      </span>
    );

    return (
      <>
        {option.color ? (
          <div
            className={`flex-shrink-0 h-6 w-6 rounded-full border-2 border-solid border-black border-opacity-50 bg-${option.color}`}
          />
        ) : null}
        {option.icon ? <Image alt="option icon" height="16" src={option.icon} width="16" /> : null}

        {valueText}
      </>
    );
  };

  return (
    <Listbox value={selectedOption} onChange={option => onChange(option)} disabled={disabled}>
      {({ open }) => (
        <div className={`${styles} ${disabledStyles}`}>
          <Listbox.Label className="block font-bold text-steel">{title}</Listbox.Label>
          <div className="mt-1 relative">
            <Listbox.Button className="bg-white relative w-full border border-platinum rounded-md shadow-sm pl-3 pr-2 sm:pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm">
              <span className="flex items-center">
                {selectedOption?.color ? (
                  <div
                    className={`flex-shrink-0 h-6 w-6 rounded-full border-2 border-solid border-black border-opacity-50 bg-${selectedOption.color}`}
                  />
                ) : null}
                {selectedOption?.color ? (
                  <span
                    className={`ml-3 block truncate border-white${
                      selectedOption?.value ? '' : ' text-slate'
                    }`}
                  >
                    {selectedOption?.value || placeholder}
                  </span>
                ) : (
                  <span
                    className={`block truncate overflow-hidden border-white${
                      selectedOption?.value ? '' : ' text-slate'
                    }`}
                  >
                    {selectedOption?.value || placeholder}
                  </span>
                )}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="bg-white h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-30 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                {options &&
                  options.map((option, index) => (
                    <Listbox.Option
                      key={`${option.id}-${index}`}
                      className={({ active }) =>
                        utils.classNames(
                          active ? 'text-white bg-barely-blue primary' : 'text-charcoal',
                          'cursor-default select-none relative py-2 pl-3 pr-9'
                        )
                      }
                      value={option}
                    >
                      {({ selected }) => (
                        <>
                          <div
                            className={`flex items-center text-steel ${
                              selected ? 'font-bold' : 'font-medium'
                            }`}
                          >
                            {renderOption(option, selected)}
                          </div>

                          {selected ? (
                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default SelectMenu;
