// Input - https://tailwindui.com/components/application-ui/forms/input-groups

interface Props {
  disabled?: boolean;
  numbersOnly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  styles?: string;
  title?: string;
  value?: string | number | null;
}

const CurrencyInput: React.FC<Props> = ({
  disabled,
  numbersOnly,
  onChange,
  placeholder,
  styles,
  title,
  value,
}: Props) => {
  const disabledStyles = disabled ? 'opacity-40' : '';

  return (
    <div className={`${styles} ${disabledStyles}`}>
      <label htmlFor="price" className="block font-bold text-steel">
        {title}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">$</span>
        </div>
        <input
          value={value}
          type={numbersOnly ? 'number' : 'text'}
          name="price"
          id="price"
          className="focus:ring-primary focus:border-primary block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
          placeholder={placeholder || '0.00'}
          aria-describedby="price-currency"
          onChange={onChange}
          disabled={disabled}
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm" id="price-currency">
            USD
          </span>
        </div>
      </div>
    </div>
  );
};

export default CurrencyInput;
