import useSWR from 'swr';
import { Avatar, Button } from 'src/components/ui';
import { getDate } from 'src/utils/dateUtils';
import { Message } from 'src/types';
import { History } from 'src/types/Message';

interface User {
  name?: string;
  image?: string;
  userId?: number;
  isBuyer?: boolean;
}

interface Props {
  messages: Message;
  styles?: string;
  user: User;
  onAccept: () => void;
}

const MessageBuffer: React.FC<Props> = ({ messages, onAccept, styles = '', user }: Props) => {
  // Get vehicle data
  const { data } = useSWR(`/api/getVehicleById?vehicleId=${messages?.vehicleId}`);
  const vehicle = data?.vehicle;
  const offerAccepted = vehicle?.vehicleStatusId === 4;

  const offerAcceptedButton = (
    <Button size="md" disabled>
      Offer Accepted
    </Button>
  );

  const loader = (
    <div className="flex justify-center items-center h-8 w-8 flex-shrink-0 mt-1">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
    </div>
  );

  const getMessagesRow = (message: History, index: number): React.ReactNode => {
    const isUsersMessage = message?.userId == user?.userId;

    const getSellerButton = (): React.ReactNode => {
      if (!vehicle) return loader;
      if (offerAccepted) return offerAcceptedButton;
      return (
        <Button size="md" primary onClick={() => onAccept && onAccept()}>
          Accept the Offer
        </Button>
      );
    };

    const getBuyerButton = (): React.ReactNode => {
      if (!vehicle) return loader;
      if (offerAccepted) return offerAcceptedButton;
      return (
        <div className="font-extrabold text-white bg-primary px-3 py-2 my-2 rounded-full text-center mr-auto">
          Offer Sent
        </div>
      );
    };

    if (!isUsersMessage) {
      return (
        <div className="flex flex-col my-3" key={index}>
          <div className="flex justify-end w-full pr-4 pl-20 md:pl-4 lg:pl-20">
            <div className="rounded-md p-2 mr-3 text-right bg-frost text-steel">
              {message?.message}
            </div>
            <Avatar image={message?.correspondent?.image} size={'md'} />
          </div>
          {message?.isOfferMessage && <div className="mr-16 my-2 ml-auto">{getSellerButton()}</div>}
          <div className="text-xs font-medium text-slate ml-auto mr-16 mt-1">
            {getDate(message?.timestamp, 'Pp')}
          </div>
        </div>
      );
    }

    return (
      <div className="flex flex-col my-3" key={index}>
        <div className="flex w-full pl-4 pr-20 md:pr-4 lg:pr-20 mb-1">
          <Avatar image={user?.image} size={'md'} />
          <div className="rounded-md p-2 ml-3 bg-lighter-blue text-steel">{message.message}</div>
        </div>
        {message?.isOfferMessage && <div className="ml-16 my-2 mr-auto">{getBuyerButton()}</div>}
        <div className="text-xs font-medium text-slate ml-16">
          {getDate(message?.timestamp, 'Pp')}
        </div>
      </div>
    );
  };

  return (
    <div className={`flex flex-col-reverse h-80 overflow-scroll overflow-x-hidden ${styles}`}>
      {messages?.history?.map((message, index) => getMessagesRow(message, index)).reverse()}
    </div>
  );
};
export default MessageBuffer;
