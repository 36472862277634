import { Modal } from 'src/components/ui';
import { PhoneIcon } from '@heroicons/react/outline';

interface Payment {
  date: string;
  ref: string;
  description: string;
  amount: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  payments: Payment[];
}

const PaymentHistoryModal: React.FC<Props> = ({ isOpen = false, onClose, payments }: Props) => {
  const modalFooterSection = (
    <div className="absolute left-0 bottom-0 w-full h-12 pt-3 text-sm text-steel font-bold bg-frost">
      <div className="flex justify-center">
        <div className="mx-2">Need help?</div>
        <span className="hidden sm:block font-medium mr-1">Speak with a lease specialist.</span>
        <span className="flex font-extrabold text-primary w-40">
          <PhoneIcon className="h-6 w-6 text-primary mr-1 -mt-1" aria-hidden="true" />
          1-877-893-1588
        </span>
      </div>
    </div>
  );

  return (
    <Modal
      footer={modalFooterSection}
      isOpen={isOpen}
      onClose={() => onClose()}
      showButtons={false}
      title="Your Payments"
      size="lg"
    >
      <div className="flex flex-col w-full h-96 mt-5">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 mb-12">
                <thead className="bg-frost">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-bold text-slate uppercase tracking-wider"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-bold text-slate uppercase tracking-wider"
                    >
                      Ref#
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-bold text-slate uppercase tracking-wider"
                    >
                      description
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-bold text-slate uppercase tracking-wider"
                    >
                      amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {payments?.map((payment, index) => (
                    <tr
                      key={`${payment.date}-${index}`}
                      className={index % 2 === 0 ? 'bg-white' : 'bg-barely-blue'}
                    >
                      <td className="text-left px-6 py-4 whitespace-nowrap text-sm font-medium text-steel">
                        {payment.date}
                      </td>
                      <td className="text-left px-6 py-4 whitespace-nowrap text-sm font-medium text-steel">
                        {payment.ref}
                      </td>
                      <td className="text-left px-6 py-4 whitespace-nowrap text-sm font-medium text-steel">
                        {payment.description}
                      </td>
                      <td className="text-left px-6 py-4 whitespace-nowrap text-sm font-bold text-steel">
                        {payment.amount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentHistoryModal;
