interface Props {
  step: number;
  totalSteps: number;
  compact?: boolean;
  onClick?: (index: number) => void;
}

const Stepper: React.FC<Props> = ({ step, totalSteps, onClick, compact = false }: Props) => {
  const handleOnClick = (index: number): void => {
    if (onClick) onClick(index);
  };

  const getCompletedSteps = (): React.ReactNode[] => {
    if (!step) return null;

    const steps = [];
    for (let i = 0; i < step; i++) {
      const currentStepColor = i + 1 === step ? 'bg-light-blue' : null;
      steps.push(
        <div
          className={`flex justify-center items-center w-6 h-6 rounded-full ${currentStepColor}`}
          key={i + 1}
          onClick={() => handleOnClick(i + 1)}
        >
          <div className="w-3 h-3 rounded-full bg-primary"></div>
        </div>
      );
    }

    return steps;
  };

  const getUncompletedSteps = (): React.ReactNode[] => {
    if (!step || !totalSteps) return null;
    const uncompletedCount = totalSteps - step;
    const steps = [];

    for (let i = 0; i < uncompletedCount; i++) {
      const key = i + 1 + step;
      steps.push(
        <div
          className={`flex justify-center items-center w-6 h-6 rounded-full ${
            onClick ? 'cursor-pointer' : ''
          }`}
          key={key}
          onClick={() => handleOnClick(key)}
        >
          <div className="w-3 h-3 rounded-full bg-platinum"></div>
        </div>
      );
    }

    return steps;
  };

  return (
    <div
      className={`flex items-center w-full px-0 text-sm font-bold text-slate ${
        compact ? 'justify-start' : 'justify-between'
      } ${onClick ? 'cursor-pointer' : ''}`}
    >
      {compact ? null : `Step ${step} of ${totalSteps}`}
      {getCompletedSteps()}
      {getUncompletedSteps()}
    </div>
  );
};

export default Stepper;
