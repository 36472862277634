interface Option {
  id: string;
  selected?: boolean;
  value: string;
}

interface Props {
  styles?: string;
  onChange?: (arg: string[]) => void;
  options: Option[];
  values?: string[];
}

const FilterSelect: React.FC<Props> = ({
  options = [],
  onChange,
  styles = '',
  values = [],
}: Props) => {
  const handleClick = (option: Option): void => {
    const currentSelection = options.filter(option => option.selected);

    // Deselect the selected
    const isAlreadySelected = currentSelection.find(
      selectedOption => selectedOption.id === option.id
    );
    if (isAlreadySelected) {
      const filteredSelected = currentSelection.filter(
        selectedOption => selectedOption.id !== option.id
      );
      if (onChange) handleOnChange(filteredSelected);
      return;
    }

    // Add new selected option
    currentSelection.push(option);
    if (onChange) handleOnChange(currentSelection);
  };

  const handleOnChange = (selectedOptions: Option[]): void => {
    const values = selectedOptions.map(option => option.value);
    onChange(values);
  };

  const updatedOptions = options.map(option => {
    if (values.find(value => option?.value?.toLowerCase() === value?.toLowerCase())) {
      return {
        ...option,
        selected: true,
      };
    }

    return option;
  });

  return (
    <div
      className={`bg-frost px-2 border border-platinum max-h-52 overflow-y-auto shadow-inner ${styles}`}
    >
      {updatedOptions.map((option, i) => (
        <div
          key={i}
          className={`relative block py-1 text-sm cursor-pointer ${
            option.selected ? 'font-bold' : ''
          }`}
          onClick={() => handleClick(option)}
        >
          <div className="flex justify-start items-center w-full">
            <input
              id="push_email"
              name="push_notifications"
              type="checkbox"
              className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded"
              checked={option.selected}
              readOnly
            />
            <div className="ml-3 text-charcoal">{option.value}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FilterSelect;
