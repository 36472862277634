import Image from 'next/image';

interface Testimonial {
  title: string;
  description: string;
  name: string;
  userImage: string;
}

interface Props {
  testimonial: Testimonial;
  showDots: boolean;
}

const Testimonial: React.FC<Props> = ({ testimonial, showDots = false }: Props) => {
  const renderDots = (): React.ReactNode => (
    <svg
      className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
      width={404}
      height={404}
      fill="none"
      viewBox="0 0 404 404"
      role="img"
      aria-labelledby="svg-workcation"
    >
      <title id="svg-workcation">Testimonials</title>
      <defs>
        <pattern
          id="ad119f34-7694-4c31-947f-5c9d249b21f3"
          x={0}
          y={0}
          width={20}
          height={20}
          patternUnits="userSpaceOnUse"
        >
          <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
        </pattern>
      </defs>
      <rect width={404} height={404} fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)" />
    </svg>
  );

  return (
    <section className="py-8 overflow-hidden">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {showDots ? renderDots() : null}
        <div className="relative">
          <blockquote className="mt-10">
            <div className="max-w-3xl mx-auto text-2xl leading-9 font-medium text-gray-900">
              <p>{testimonial?.description}</p>
            </div>
            <footer className="mt-8">
              <div className="flex items-center justify-center lg:justify-start max-w-3xl mx-auto">
                <div className="md:flex-shrink-0">
                  <Image
                    className="mx-auto h-10 w-10 rounded-full mr-2 md:mr-0"
                    alt="Profile Image"
                    height={40}
                    layout="fixed"
                    src={testimonial?.userImage}
                    width={40}
                  />
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">{testimonial?.name}</div>

                  <svg
                    className="hidden md:block mx-1 h-5 w-5 text-indigo-600"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-gray-500">{testimonial?.title}</div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
