import Image from 'next/image';
import { HeartIcon as HeartOutlineIcon, LocationMarkerIcon } from '@heroicons/react/outline';
import { HeartIcon as HeartSolidIcon } from '@heroicons/react/solid';
import utils from 'src/utils';
import { useState } from 'react';
import { useSession } from 'next-auth/client';
import { useNotificationDispatch, updateNotification } from 'src/providers/NotificationProvider';
import { Vehicle } from 'src/types';

interface Props {
  onUpdate?: () => void;
  vehicle: Vehicle;
}

const LeaseCard: React.FC<Props> = ({ onUpdate, vehicle }) => {
  const [session] = useSession();
  const userEmail = session?.user?.email;
  const notifyDispatch = useNotificationDispatch();
  const [image, setImage] = useState(vehicle?.imageUrl);

  const handleAddFavorite = async (): Promise<void> => {
    const res = await fetch('/api/watchlist/saveWatchlistItem', {
      body: JSON.stringify({
        vehicleId: vehicle?.vehicleId,
        userEmail,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    if (onUpdate) onUpdate();

    const { error } = await res.json();
    if (error) {
      notifyDispatch(
        updateNotification({
          title: 'Save Failed',
          icon: 'error',
          show: true,
          description: 'There was an issue adding vehicle to watchlist.',
        })
      );
      console.error(error);
    }
  };

  const handleRemoveFavorite = async (): Promise<void> => {
    // Delete the favorite vehicle
    const res = await fetch('/api/watchlist/deleteWatchlistItem', {
      body: JSON.stringify({ id: vehicle?.watchlistId }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    });

    // Update to latest watchlist
    if (onUpdate) onUpdate();

    const { error } = await res.json();
    if (error) {
      notifyDispatch(
        updateNotification({
          title: 'Remove Failed',
          icon: 'error',
          show: true,
          description: 'There was an issue removing vehicle from watchlist.',
        })
      );
      console.error(error);
    }
  };

  const leaseType = vehicle.isForLease
    ? 'Lease Transfer'
    : vehicle.isDealer
    ? 'Dealer'
    : 'New Loan';

  return (
    <div className="grid grid-cols-1 text-charcoal border-b">
      <div className="relative z-10 col-start-1 row-start-1 px-4 pt-4 pb-3 h-56">
        {vehicle?.location ? (
          <div className="absolute flex bg-opacity-80 bg-slate font-medium items-center justify-center px-2 py-1 rounded-md text-sm text-white">
            <LocationMarkerIcon className="h-6 w-6" />
            <span className="pl-1">{vehicle?.location}</span>
          </div>
        ) : null}
        <div
          className="absolute bg-white -bottom-3 right-4 rounded-full shadow-md text-primary z-10 cursor-pointer"
          onClick={e => {
            e.preventDefault();
            if (vehicle?.isFavorite) {
              handleRemoveFavorite();
            } else {
              handleAddFavorite();
            }
          }}
        >
          <div className="h-12 relative w-12">
            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              {vehicle?.isFavorite ? (
                <HeartSolidIcon height="24" width="24" />
              ) : (
                <HeartOutlineIcon height="24" width="24" />
              )}
            </div>
          </div>
        </div>
      </div>

      <h3 className="col-start-1 font-bold pt-2 row-start-2 text-xl">{`${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`}</h3>
      <div className="col-start-1 font-bold row-start-3 pt-1">
        <div className="flex justify-between mr-2">
          <div>
            <span className="inline-flex items-center px-2 py-1 rounded-md text-sm font-medium bg-gray-100 text-gray-500">
              {leaseType}
            </span>
          </div>
          <div>
            <span className="text-primary text-2xl">
              {utils.localizeCurrency(vehicle?.monthlyPayment)}
            </span>
            <span className="font-normal pl-1 text-slate">/ mo</span>
          </div>
        </div>
      </div>

      <div className="col-start-1 flex flex-col font-bold row-start-4 pb-2">
        {/*<div className="flex space-x-2">
          {vehicle?.monthsLeft > 0 ? (
            <div>
              <span className="font-bold text-lg pl-1">{vehicle?.mileage?.toLocaleString()}</span>
              <span className="font-normal pl-1 text-slate">miles / month for </span>
              <span className="font-bold text-lg pl-1">{vehicle?.monthsLeft}</span>
              <span className="font-normal pl-1 text-slate">months</span>
            </div>
          ) : (
            <span className="font-normal pl-1 text-slate">Expired listing</span>
          )}
        </div>*/}

        {/* TODO UNMOCK DATA*/}
        <div>
          <span className="font-bold text-lg pl-1">
            {Math.round(Math.random() * (4000 - 500) + 500)}
          </span>
          <span className="font-normal pl-1 text-slate">miles / month for </span>
          <span className="font-bold text-lg pl-1">{Math.round(Math.random() * (48 - 9) + 9)}</span>
          <span className="font-normal pl-1 text-slate">months</span>
        </div>
      </div>

      <div className="col-start-1 row-start-1 flex">
        <div className="w-full grid grid-cols-3 grid-rows-2 gap-2">
          <div className="relative col-span-3 row-span-2">
            <Image
              alt="Car Image"
              className="absolute inset-0 object-contain rounded-xl"
              layout="fill"
              src={image}
              onError={() => setImage('/svg/sal-logo-mark.svg')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaseCard;
