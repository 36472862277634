interface Props {
  className?: string;
  color: string;
  percent: number;
}

const CircularLoading: React.FC<Props> = ({ className = null, color, percent }: Props) => (
  <svg className={`circular-chart ${className}`} viewBox="0 0 36 36">
    <path
      className="circle"
      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
      stroke={color}
      strokeDasharray={`${percent}, 100`}
    />
  </svg>
);

export default CircularLoading;
