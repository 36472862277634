interface Props {
  description?: string;
  descriptionStyles?: string;
  onChange?: (checked: boolean) => void;
  styles?: string;
  title: string;
  titleStyles?: string;
}

const CheckBox: React.FC<Props> = ({
  description = '',
  descriptionStyles = 'text-slate',
  onChange,
  styles = '',
  title = '',
  titleStyles = 'text-charcoal',
}: Props) => {
  const titleId = title?.toLowerCase().replace(' ', '-');

  return (
    <fieldset className={`space-y-5 ${styles}`}>
      <legend className="sr-only">Check Box</legend>
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            aria-describedby="comments-description"
            className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded"
            id={titleId}
            name={title}
            onChange={e => onChange(e?.target?.checked)}
            type="checkbox"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={titleId} className={`text-sm font-bold ${titleStyles}`}>
            {title}
          </label>
          <p id="comments-description" className={`font-medium ${descriptionStyles}`}>
            {description}
          </p>
        </div>
      </div>
    </fieldset>
  );
};

export default CheckBox;
