import React, { createContext, useContext, useReducer } from 'react';
import { SearchFilters } from 'src/types';
import { removeFilterFromQueryString } from 'src/utils/search';

export interface Payload {
  filter?: string;
}

export interface Action {
  type: string;
  payload?: Payload;
}

interface Props {
  children?: any;
  filter?: string;
}

const initialState: SearchFilters = {
  bodyStyleFilters: [],
  leaseTypeFilters: [],
  locationFilters: ['50'],
  makeFilters: [],
  maxMonthlyPayment: 0,
  minMonthlyPayment: 0,
  modelFilters: [],
  monthsRemainingFilters: [],
  searchUrl: '/search',
  query: '',
  yearFilters: [],
  zipcode: '',
};

const actionTypes = {
  resetFilters: 'resetFilters',
  removeFilter: 'removeFilter',
  updateSearchFilters: 'updateSearchFilters',
};

// Builds search page params based on current state
const getSearchUrl = (state: SearchFilters) => {
  const paramTypes = [
    'bodyStyleFilters',
    'leaseTypeFilters',
    'locationFilters',
    'makeFilters',
    'modelFilters',
    'minMonthlyPayment',
    'maxMonthlyPayment',
    'query',
    'monthsRemainingFilters',
    'yearFilters',
    'zipcode',
  ];
  let searchUrl = '/search?';

  paramTypes.forEach(type => {
    const isArray = Array.isArray(state[type]);
    if (isArray && state[type]?.length) {
      searchUrl += `&${type}=${state[type].join(',')}`;
    } else if (!isArray && state[type]) {
      searchUrl += `&${type}=${state[type]}`;
    }
  });

  return searchUrl;
};

const parsePriceString = (priceFilter: string): number => {
  const price = priceFilter?.split(' ')[0]?.replace('$', '');
  return parseInt(price);
};

const removePriceFilter = (state: SearchFilters, value: string, key: string, text: string): number => {
  const isPriceFilter = value?.toLowerCase()?.includes(text);
  if (!isPriceFilter) return state[key];
  const priceValue = parsePriceString(value);
  return priceValue === state[key] ? 0 : state[key];
};

const SearchStateContext = createContext<SearchFilters>(initialState);
const SearchDispatchContext = createContext<React.Dispatch<Action> | null>(null);

function searchReducer(state: SearchFilters, action: Action): SearchFilters {
  switch (action.type) {
    case 'updateSearchFilters':
      const newState = {
        ...state,
        ...action.payload,
      };
      return {
        ...newState,
        searchUrl: getSearchUrl(newState),
      };
    case 'removeFilter': {
      const removedValue = action.payload.filter;
      const newState = {
        ...state,
        makeFilters: state.makeFilters.filter((filterValue: string) => filterValue !== removedValue),
        modelFilters: state.modelFilters.filter((filterValue: string) => filterValue !== removedValue),
        yearFilters: state.yearFilters.filter((filterValue: string) => filterValue !== removedValue),
        leaseTypeFilters: state.leaseTypeFilters.filter(
          (filterValue: string) => filterValue !== removedValue
        ),
        bodyStyleFilters: state.bodyStyleFilters.filter(
          (filterValue: string) => filterValue !== removedValue
        ),
        monthsRemainingFilters: state.monthsRemainingFilters.filter(
          (filterValue: string) => filterValue !== removedValue
        ),
        locationFilters: state.locationFilters.filter((filterValue: string) => {
          const isLocationFilter = removedValue?.toLowerCase()?.includes('miles');
          if (!isLocationFilter) return true;
          const distance = removedValue?.split(' ')[0];
          return `${filterValue}` !== `${distance}`;
        }),
        zipcode: state.zipcode === removedValue ? '' : state.zipcode,
        minMonthlyPayment: removePriceFilter(state, removedValue, 'minMonthlyPayment', ' min'),
        maxMonthlyPayment: removePriceFilter(state, removedValue, 'maxMonthlyPayment', ' max'),
        query: removeFilterFromQueryString(state?.query, removedValue), 
      };
      return {
        ...newState,
        searchUrl: getSearchUrl(newState),
      };
    }
    case 'resetFilters':
      return initialState;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function SearchProvider(props: Props = { children: null }) {
  const [state, dispatch] = useReducer(searchReducer, initialState, state => ({ ...state }));
  return (
    <SearchStateContext.Provider value={state}>
      <SearchDispatchContext.Provider value={dispatch}>
        {props.children}
      </SearchDispatchContext.Provider>
    </SearchStateContext.Provider>
  );
}

const updateSearchFilters = (searchProps = {}): Action => ({
  type: actionTypes.updateSearchFilters,
  payload: searchProps,
});

const removeFilter = (filter: string): Action => ({
  type: actionTypes.removeFilter,
  payload: {
    filter: filter,
  },
});

const resetFilters = (): Action => ({
  type: actionTypes.resetFilters,
});

function useSearchState() {
  const context = useContext(SearchStateContext);
  if (context === undefined) {
    throw new Error(`useSearchState must be used within a SearchProvider`);
  }
  return context;
}

function useSearchDispatch() {
  const context = useContext(SearchDispatchContext);
  if (context === undefined) {
    throw new Error(`useSearchDispatch must be used within a SearchProvider`);
  }
  return context;
}

function useSearch() {
  const context: [SearchFilters, React.Dispatch<Action>] = [
    useSearchState(),
    useSearchDispatch() as React.Dispatch<Action>,
  ];

  if (context === undefined) {
    throw new Error(`useSearch must be used within a SearchProvider`);
  }
  return context;
}

export {
  SearchProvider as default,
  resetFilters,
  removeFilter,
  useSearch,
  useSearchState,
  useSearchDispatch,
  updateSearchFilters,
};
