import { useState } from 'react';
import { CogIcon } from '@heroicons/react/outline';
import { colorOptions } from 'src/config/inputOptions';

interface Color {
  colorGroup: string;
}

interface Props {
  color: Color;
  mpg: string;
  mileage: string;
  interiorColor: Color;
  upholsteryType?: string;
  options?: string[];
  trim: string;
  transmissionId: number;
}

const EquipmentList: React.FC<Props> = ({
  color,
  mileage,
  mpg,
  interiorColor,
  trim,
  options = [],
  transmissionId = 1,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const extColorStyle = colorOptions.find(option => option.id === color?.colorGroup);
  const intColorStyle = colorOptions.find(option => option.id === interiorColor?.colorGroup);

  return (
    <div className="flex flex-row flex-wrap items-center justify-start sm:justify-evenly w-auto pl-2 md:pl-0">
      <div className="flex justify-start items-center w-1/2 sm:w-48 my-8">
        <img
          src="/svg/gauge.svg"
          className="w-7 mr-2 mb-1 fill-primary"
          height="48"
          width="48"
          alt="gauge"
        />
        <div className="flex flex-col">
          <span className="font-bold">{mileage}</span>
          <span>Miles</span>
        </div>
      </div>

      <div className="flex justify-start items-center w-1/2 sm:w-48 my-8">
        <img
          src="/svg/drop.svg"
          className="w-7 mr-2 mb-1 fill-primary"
          height="48"
          width="48"
          alt="drop"
        />
        <div className="flex flex-col">
          <span className="font-bold">{mpg || 'N/A'}</span>
          <span>MPG</span>
        </div>
      </div>

      <div className="flex justify-start items-center w-1/2 sm:w-48 my-8">
        <div
          className={`rounded-full w-7 h-7 border-2 borer-black-500 mr-2 mb-1 bg-${extColorStyle?.color}`}
        ></div>
        <div className="flex flex-col">
          <span className="font-bold">{color?.colorGroup}</span>
          <span>Exterior Color</span>
        </div>
      </div>

      <div className="flex justify-start items-center w-1/2 sm:w-48 my-8">
        <div
          className={`rounded-full w-7 h-7 border-2 borer-black-500 mr-2 mb-1 bg-${intColorStyle?.color}`}
        ></div>
        <div className="flex flex-col">
          <span className="font-bold">{interiorColor?.colorGroup}</span>
          <span>Interior Color</span>
        </div>
      </div>

      <div className="flex justify-start items-center w-1/2 sm:w-48 my-8">
        <CogIcon className="h-7 w-7 mr-2 mb-1 text-primary" aria-hidden="true" />
        <div className="flex flex-col">
          <span className="font-bold">{transmissionId === 1 ? 'Automatic' : 'Standard'}</span>
          <span>Transmission</span>
        </div>
      </div>

      <div className="flex justify-start items-center w-1/2 sm:w-48 my-8">
        <img
          src="/svg/car_front.svg"
          className="w-7 mr-2 mb-1 fill-primary"
          height="48"
          width="48"
          alt="car front"
        />
        <div className="flex flex-col">
          <span className="font-bold">{trim}</span>
          <span>Trim</span>
        </div>
      </div>

      {/* Hidden items while closed*/}

      {isOpen
        ? options?.map((option, index) => (
            <div className="flex justify-start items-center w-1/2 sm:w-48 my-8" key={index}>
              <CogIcon className="h-7 w-7 mr-2 mb-1 text-primary" aria-hidden="true" />
              <div className="flex flex-col">
                <span className="font-bold">{option}</span>
              </div>
            </div>
          ))
        : null}

      <div className="flex justify-center w-full">
        {!!options?.length && (
          <div
            onClick={() => setIsOpen(!isOpen)}
            className="font-extrabold px-4 py-2 border-2 border-primary bg-white text-primary rounded-full cursor-pointer"
          >
            View Full Equipment List
          </div>
        )}
      </div>
    </div>
  );
};

export default EquipmentList;
