import { Button } from 'src/components/ui';

interface Props {
  title: string;
  subtitle: string;
  description: string;
  actionText: string;
  actionUrl: string;
  imageUrl?: string;
}

const ContentCard: React.FC<Props> = ({
  title,
  subtitle,
  description,
  imageUrl,
  actionText,
  actionUrl,
}: Props) => (
  <div className="grid grid-cols-1">
    <div className="relative z-10 col-start-1 row-start-1 px-4 pt-52">
      <h1 className="text-2xl font-bold text-primary">{title}</h1>
    </div>
    <div className="px-4 pt-2">
      <p className="text-lg font-bold text-charcoal">{subtitle}</p>
      <p className="text-md text-charcoal py-2">{description}</p>
      <Button primary size="lg" href={actionUrl}>
        {actionText}
      </Button>
    </div>

    <div className="col-start-1 row-start-1 flex ">
      <div className="w-full grid">
        <div className="relative">
          <img
            src={imageUrl}
            alt=""
            className="absolute inset-0 w-full h-full object-cover bg-gray-100 rounded-md"
          />
        </div>
      </div>
    </div>
  </div>
);

export default ContentCard;
