const vehicleCategories = [
  {
    id: 1,
    categoryCode: 'Green/Hybrid',
  },
  {
    id: 2,
    categoryCode: 'Compact',
  },
  {
    id: 3,
    categoryCode: 'Sedan',
  },
  {
    id: 4,
    categoryCode: 'Wagon',
  },
  {
    id: 5,
    categoryCode: 'Van',
  },
  {
    id: 6,
    categoryCode: 'Convertible',
  },
  {
    id: 7,
    categoryCode: 'Coupe',
  },
  {
    id: 9,
    categoryCode: 'Sport',
  },
  {
    id: 10,
    categoryCode: 'Truck',
  },
  {
    id: 11,
    categoryCode: 'SUV/Crossover',
  },
  {
    id: 12,
    categoryCode: 'Motorcycle',
  },
  {
    id: 13,
    categoryCode: 'Tow Truck',
  },
  {
    id: 14,
    categoryCode: 'Commercial',
  },
  {
    id: 16,
    categoryCode: 'Hatchback',
  },
];

export default vehicleCategories;
