import { useState } from 'react';
import Image from 'next/image';
import { ImageCarousel } from 'src/components';
import { HeartIcon as HeartOutlineIcon } from '@heroicons/react/outline';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';

interface Props {
  images: string[];
  title: string;
}

const HeroGallery: React.FC<Props> = ({ images, title }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const router = useRouter();

  const toggleImageCarouselModal = (index: number): void => {
    setActiveImageIndex(index);
    setModalOpen(true);
  };

  return images?.length ? (
    <div className="mx-auto relative w-full">
      <div className="bg-frost flex items-center p-2 text-primary">
        <div className="flex font-medium cursor-pointer" onClick={() => router.back()}>
          <ChevronLeftIcon height="24" width="24" /> <a>Back to results</a>
        </div>
        <div className="flex justify-end flex-grow">
          <a href="/save" className="flex h-8 items-center md:hidden ml-4 order-2 relative w-8">
            <HeartOutlineIcon height="24" width="24" />
          </a>
          <a href="/share" className="flex h-8 items-center md:hidden ml-4 order-1 relative w-8">
            <Image height="24" src="/svg/share.svg" width="24" />
          </a>
        </div>
      </div>

      <div className="max-w-screen-xl mx-auto w-full md:grid md:grid-cols-4 md:gap-x-1">
        <div
          className={`overflow-hidden lg:block ${
            images?.length > 1
              ? 'col-span-3 aspect-w-3 aspect-h-2'
              : 'col-span-4 aspect-w-4 aspect-h-2'
          }`}
        >
          <Image
            alt="vehicle image"
            layout="fill"
            onClick={() => toggleImageCarouselModal(0)}
            src={images?.[0] || '/svg/sal-logo-mark.svg.svg'}
            className={`w-full h-full object-center object-cover cursor-pointer`}
          />
        </div>
        {images?.length > 1 ? (
          <div className="hidden md:grid md:grid-cols-1 md:gap-y-1 ">
            {images.slice(1, 4)?.map((image, idx) => (
              <div className="aspect-w-3 aspect-h-2 overflow-hidden" key={`autoimg-${idx}`}>
                <Image
                  alt={`vehicle image ${idx + 1}`}
                  layout="fill"
                  onClick={() => toggleImageCarouselModal(idx + 1)}
                  src={image}
                  className={`w-full h-full object-center cursor-pointer ${
                    images.length === 2 ? 'object-contain' : 'object-cover'
                  }`}
                />
                {idx === 2 ? (
                  <>
                    <div className="absolute bg-primary flex h-auto inset-0 opacity-50 w-full" />
                    <div
                      className="absolute content-center flex h-auto inset-0 justify-center w-full cursor-pointer"
                      onClick={() => toggleImageCarouselModal(3)}
                    >
                      <p className="mx-auto opacity-100 self-center text-4xl sm:text-6xl md:text-7xl text-white w-auto">{`+${images.length}`}</p>
                    </div>
                  </>
                ) : null}
              </div>
            ))}
          </div>
        ) : null}
      </div>

      {modalOpen && (
        <ImageCarousel
          activeIndex={activeImageIndex}
          images={images}
          onClose={() => setModalOpen(false)}
          title={title}
        />
      )}
    </div>
  ) : null;
};

export default HeroGallery;
