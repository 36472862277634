import { PhoneIcon } from '@heroicons/react/outline';

const ContactSpecialistModalFooter: React.FC = () => {
  return (
    <div className="absolute left-0 bottom-0 w-full h-12 pt-3 text-sm text-steel font-bold bg-frost">
      <div className="flex justify-center">
        <div className="mx-2">Need help?</div>
        <span className="hidden sm:block font-medium mr-1">Speak with a lease specialist.</span>
        <span className="flex font-extrabold text-primary w-40">
          <PhoneIcon className="h-6 w-6 text-primary mr-1 -mt-1" aria-hidden="true" />
          1-877-893-1588
        </span>
      </div>
    </div>
  );
};

export default ContactSpecialistModalFooter;
