import { ReactChild, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { AppLayout } from 'src/components';
import { SaveSearchIcon } from 'src/components/ui/svgs/SaveSearchIcon';
import utils from 'src/utils';
import {
  ChatAlt2Icon,
  CogIcon,
  CreditCardIcon,
  HeartIcon,
  UserGroupIcon,
  UserCircleIcon,
} from '@heroicons/react/outline';

const sideNavigation = [
  {
    href: '/my-account/profile',
    icon: UserCircleIcon,
    name: 'profile',
    title: 'Profile',
  },
  {
    href: '/my-account/buyer-membership',
    icon: CreditCardIcon,
    name: 'buyerMembership',
    title: 'Buyer Membership',
  },
  {
    href: '/my-account/preferences',
    icon: CogIcon,
    name: 'preferences',
    title: 'Preferences',
  },
  {
    href: '/my-account/messages',
    icon: ChatAlt2Icon,
    name: 'messages',
    title: 'Messages',
  },
  {
    href: '/my-account/my-listings',
    icon: UserGroupIcon,
    name: 'myListings',
    title: 'My Listings',
  },
  {
    href: '/my-account/watchlist',
    icon: HeartIcon,
    name: 'watchlist',
    title: 'Watchlist',
  },
  {
    href: '/my-account/saved-searches',
    icon: SaveSearchIcon,
    name: 'savedSearches',
    title: 'Saved Searches',
  },
];

interface Props {
  children: ReactChild;
  hasShadow?: boolean;
}

const MyAccountLayout: React.FC<Props> & { auth: boolean } = ({ children, hasShadow = true }) => {
  const [activePage, setActivePage] = useState('profile');
  const router = useRouter();
  const childContainerStyles = hasShadow ? 'border border-transparent shadow' : '';

  const { pathname } = router;
  const currentPage = pathname
    .split('/')[2]
    ?.split('-')
    .map((word, index) => (index !== 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
    .join('');

  useEffect(() => {
    currentPage && setActivePage(currentPage);
  }, []);

  return (
    <AppLayout title="My Account">
      <div className="bg-primary h-80 hidden md:block"></div>
      <div className="max-w-6xl md:-mt-72 mx-auto lg:px-4">
        <h1 className="bg-primary font-bold hidden md:block pb-8 text-4xl text-white pl-4 lg:pl-0">
          My Account
        </h1>
        <div className="bg-frost max-w-7xl mx-auto pb-10 px-0 md:px-2 lg:px-8 md:py-12 rounded-lg">
          <div className="md:gap-x-5 md:grid md:grid-cols-12">
            <aside className="md:col-span-3 px-2 lg:px-4 py-6 md:py-0">
              <nav className="space-y-1">
                {sideNavigation.map(item => (
                  <Link href={item.href} key={item.name}>
                    <a
                      aria-current={item.name ? 'page' : undefined}
                      className={utils.classNames(
                        item.name === activePage
                          ? 'bg-white text-primary'
                          : 'text-steel hover:text-primary hover:bg-white',
                        'capitalize flex font-medium group items-center px-3 py-2 rounded-md'
                      )}
                    >
                      <item.icon
                        aria-hidden="true"
                        className={utils.classNames(
                          item.name ? '' : 'text-steel group-hover:text-primary',
                          'mr-2'
                        )}
                        height={24}
                        width={24}
                      />
                      {item.title}
                    </a>
                  </Link>
                ))}
              </nav>
            </aside>

            <div
              className={`bg-white  md:col-span-9 rounded-lg  space-y-6 ${childContainerStyles}`}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

MyAccountLayout.auth = true;

export default MyAccountLayout;
