import React, { createContext, useContext, useReducer } from 'react';

interface Action {
  type: string;
  payload?: object;
}

interface State {
  autoHide: boolean;
  description: string;
  icon: 'success' | 'warning' | 'error' | 'info';
  show: boolean;
  title: string;
}

const initialState: State = {
  autoHide: false,
  description: '',
  icon: 'info',
  show: false,
  title: '',
};

interface Props {
  children?: any;
}

const actionTypes = {
  update: 'update',
  close: 'close',
};

const NotificationStateContext = createContext<State>(initialState);
const NotificationDispatchContext = createContext<React.Dispatch<Action> | null>(null);

function NotificationReducer(state: State, action: any) {
  switch (action.type) {
    case actionTypes.update: {
      const newState = {
        ...state,
        ...action.payload.props,
      };
      return newState;
    }
    case actionTypes.close: {
      return initialState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function NotificationProvider({ children = null }: Props) {
  const [state, dispatch] = useReducer(NotificationReducer, initialState, state => ({ ...state }));
  return (
    <NotificationStateContext.Provider value={state}>
      <NotificationDispatchContext.Provider value={dispatch}>
        {children}
      </NotificationDispatchContext.Provider>
    </NotificationStateContext.Provider>
  );
}

const updateNotification = (NotificationProps = {}) => ({
  type: actionTypes.update,
  payload: {
    props: NotificationProps,
  },
});

const closeNotification = () => ({
  type: actionTypes.close,
  payload: {},
});

function useNotificationState() {
  const context = useContext(NotificationStateContext);
  if (context === undefined) {
    throw new Error(`useNotificationState must be used within a NotificationProvider`);
  }
  return context;
}

function useNotificationDispatch() {
  const context = useContext(NotificationDispatchContext);
  if (context === undefined) {
    throw new Error(`useNotificationDispatch must be used within a NotificationProvider`);
  }
  return context;
}

function useNotification() {
  const context: [State, React.Dispatch<Action>] = [
    useNotificationState(),
    useNotificationDispatch() as React.Dispatch<Action>,
  ];

  if (context === undefined) {
    throw new Error(`useNotification must be used within a NotificationProvider`);
  }
  return context;
}

export {
  NotificationProvider as default,
  closeNotification,
  useNotification,
  useNotificationState,
  useNotificationDispatch,
  updateNotification,
};
