import { Footer, Navigation, ContactSpecialistBanner } from 'src/components';
import Head from 'next/head';

interface LayoutProps {
  children?: JSX.Element | JSX.Element[];
  title?: string;
  hideBanner?: boolean;
}

const AppLayout: React.FC<LayoutProps> = ({
  title = '',
  hideBanner = false,
  children,
}: LayoutProps) => {
  return (
    <>
      <Head>
        <title>Swapalease{title && ` - ${title}`}</title>
        <meta
          name="description"
          content="Swapalease.com is the number one car lease transfer marketplace. Get out of your auto lease early without penalties or take over an existing short term lease"
        />
        <meta
          name="google-site-verification"
          content="zxzS5ewgpoIpK3YsRVk2TMCdmKSQMN7Te7TdM20zkEA"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Navigation />

      {hideBanner ? null : (
        <div className="hidden md:block">
          <ContactSpecialistBanner primary />
        </div>
      )}

      <main>{children}</main>

      {hideBanner ? null : (
        <div className="hidden md:block">
          <ContactSpecialistBanner />
        </div>
      )}
      <Footer />
    </>
  );
};

export default AppLayout;
