import zipcodes from 'zipcodes';

interface userData {
  city: string;
  state: string;
  zip: string;
}

export const getLocation = (data: userData): string => {
  // Use zipcode for location if it exists
  const zipcode = data?.zip;
  if (zipcode) {
    const city = zipcodes.lookup(zipcode)?.city || '';
    const state = zipcodes.lookup(zipcode)?.state || '';
    if (city && state) return `${city}, ${state}`;
  }

  // If no zipcode try to use user city and state
  const city = data?.city;
  const state = data?.state;

  if (!city && !state) return 'N/A';
  if (!city) return state;
  if (!state) return city;
  return `${city}, ${state}`;
};

export function vehicleImageResize(
  url: string,
  size: 'small' | 'thumb' | 'large' | 'huge'
): string {
  return url?.replace('thumb', size) || '';
}

export const VEHICLE_STATUS = {
  0: 'INCOMPLETE',
  1: 'INACTIVE',
  2: 'ACTIVE',
  3: 'SOLD',
  4: 'PENDING',
};
