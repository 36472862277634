import { PopupAlert } from 'src/components';
import { SelectMenu } from 'src/components/ui';
import { useRouter } from 'next/router';

const navigation = {
  browseLeases: [
    { name: 'Search Listings', href: '/search' },
    // { name: 'Leases Near You', href: '/search' },
    { name: 'Affordable Leases', href: '/search?sortType=Lowest%20Monthly%20Payment' },
    // { name: 'Featured Leases', href: '/search' },
    { name: 'Newest Leases', href: '/search?sortType=Newest%20Listing%20Date' },
    { name: 'Popular Makes', href: '/search?makeFilters=Audi,BMW' }, // bmw audi params
    { name: 'Exotic Leases', href: '/search?&bodyStyleFilters=Sport' }, // exotic params
  ],
  learn: [
    { name: 'How it Works', href: '/learn/how-it-works' },
    { name: 'Lease 101', href: '/learn/leasing-101' },
    { name: 'Buyer & Seller Tips', href: '/learn/buyer-seller-tips' },
    { name: 'Lease Company Information', href: '/learn/lease-company-information' },
    { name: 'FAQs', href: '/learn/faq' },
    { name: 'Testimonials', href: '/learn/testimonials' },
    { name: 'Helpful Tools', href: '/learn/helpful-tools' },
    { name: 'Fraud Prevention', href: '/learn/fraud-prevention' },
    { name: 'View All Learning Resources', href: '/learn' },
  ],
  exitLease: [
    { name: 'List Your Lease', href: '/create' },
    { name: 'Get Out of My Lease', href: '/create' },
    { name: 'How it Works', href: '/learn/how-it-works-for-sellers' },
    { name: 'Seller Memberships', href: '#' },
  ],
  findLease: [
    { name: 'Become a Buyer', href: '/sign-in' },
    { name: 'Browse Leases', href: '/search' },
    { name: 'How it Works', href: '/learn/how-it-works-for-buyers' },
    { name: 'Buyer Memberships', href: '#' },
    { name: 'Tool Swaps Newsletter', href: '#' },
  ],
  mediaCenter: [
    { name: 'Press Releases', href: '#' },
    { name: 'Leasing Center', href: '#' },
    { name: 'Videos', href: '#' },
    { name: 'In the News', href: '#' },
  ],
  extra1: [
    { name: 'JosephAuto.com', href: 'http://josephauto.com' },
    { name: 'Affiliate Program', href: '/programs/affiliate-program' },
    { name: 'Ambassador Program', href: '/programs/ambassador-program' },
    { name: 'Careers', href: '/career-opportunities' },
    { name: 'Contact Us', href: '/contact' },
    { name: 'Leave Feedback', href: '/feedback' },
  ],
  extra2: [
    { name: 'Privacy Policy', href: '/privacy-policy' },
    { name: 'Terms and Conditions', href: '/terms-and-conditions' },
    { name: 'Site Map', href: '/site-map' },
    { name: 'ADA Policy', href: '/ada-policy' },
    { name: 'Wantalease.com', href: 'http://wantalease.com' },
  ],
  states: [
    { value: 'Leases in California', id: '/search?locationFilters=100&zipcode=90001' },
    { value: 'Leases in New York', id: '/search?locationFilters=100&zipcode=10001' },
    { value: 'Leases in Florida', id: '/search?locationFilters=100&zipcode=33101' },
    { value: 'Leases in Texas', id: '/search?locationFilters=100&zipcode=77449' },
    { value: 'Leases in New Jersey', id: '/search?locationFilters=100&zipcode=07303' },
    { value: 'Leases in Illinois', id: '/search?locationFilters=100&zipcode=60412' },
    { value: 'Leases in Michigan', id: '/search?locationFilters=100&zipcode=48006' },
    { value: 'Leases in Virginia', id: '/search?locationFilters=100&zipcode=23452' },
    { value: 'Leases in Minnesota', id: '/search?locationFilters=100&zipcode=55418' },
    { value: 'Leases in Ohio', id: '/search?locationFilters=100&zipcode=43001' },
  ],
  makes: [
    { value: 'BMW Leases', id: '/search?makeFilters=BMW' },
    { value: 'Tesla Leases', id: '/search?makeFilters=Tesla' },
    { value: 'Lexus Leases', id: '/search?makeFilters=Lexus' },
    { value: 'Ford Leases', id: '/search?makeFilters=Ford' },
    { value: 'Chevrolet Leases', id: '/search?makeFilters=Chevrolet' },
    { value: 'Toyota Leases', id: '/search?makeFilters=Toyota' },
    { value: 'Audi Leases', id: '/search?makeFilters=Audi' },
    { value: 'Cadillac Leases', id: '/search?makeFilters=Cadillac' },
    { value: 'Volkswagen Leases', id: '/search?makeFilters=Volkswagen' },
    { value: 'Nissan Leases', id: '/search?makeFilters=Nissan' },
  ],
  models: [
    { value: 'BMW 3 Series Leases', id: '/search?makeFilters=BMW&modelFilters=3 Series' },
    { value: 'Tesla Model 3 Leases', id: '/search?makeFilters=Tesla&modelFilters=Model 3' },
    { value: 'Lexus ES 350 Leases', id: '/search?makeFilters=Lexus&modelFilters=ES350' },
    { value: 'Ford Fusion Leases', id: '/search?makeFilters=Ford&modelFilters=Fusion' },
    {
      value: 'Chevrolet Silverado 1500 Leases',
      id: '/search?makeFilters=Chevrolet&modelFilters=Silverado',
    },
    { value: 'Toyota Camry Leases', id: '/search?makeFilters=Toyota&modelFilters=Camry' },
    { value: 'Audi A4 Leases', id: '/search?makeFilters=Audi&modelFilters=A4' },
    {
      value: 'Cadillac Escalade Leases',
      id: '/search?makeFilters=Cadillac&modelFilters=Escalade',
    },
    { value: 'Volkswagen Jetta Leases', id: '/search?makeFilters=Volkswagen&modelFilters=Jetta' },
    { value: 'Nissan Altima Leases', id: '/search?makeFilters=Nissan&modelFilters=Sentra' },
  ],
  cities: [
    { value: 'Leases in Los Angeles', id: '/search?locationFilters=50&zipcode=90001' },
    { value: 'Leases in New York', id: '/search?locationFilters=50&zipcode=10001' },
    { value: 'Leases in Miami', id: '/search?locationFilters=50&zipcode=33101' },
    { value: 'Leases in Detroit', id: '/search?locationFilters=50&zipcode=48127' },
    { value: 'Leases in San Diego', id: '/search?locationFilters=50&zipcode=22400' },
    { value: 'Leases in San Francisco', id: '/search?locationFilters=50&zipcode=94016' },
    { value: 'Leases in Philadelphia', id: '/search?locationFilters=50&zipcode=19019' },
    { value: 'Leases in Boston', id: '/search?locationFilters=50&zipcode=02101' },
    { value: 'Leases in Chicago', id: '/search?locationFilters=50&zipcode=60007' },
    { value: 'Leases in Baltimore', id: '/search?locationFilters=50&zipcode=21201' },
  ],
};

const Footer: React.FC = () => {
  const router = useRouter();
  return (
    <footer className="bg-dark-blue" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Browse Leases
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.browseLeases.map(item => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
                <hr className="mt-4 w-10 border-2 border-white border-opacity-30" />
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Learn
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.learn.map(item => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
                <hr className="mt-4 w-10 border-2 border-white border-opacity-30" />
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Exit Your Lease
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.exitLease.map(item => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>

                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase mt-12">
                  Find A Lease
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.findLease.map(item => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
                <hr className="mt-4 w-10 border-2 border-white border-opacity-30" />
              </div>
            </div>
          </div>

          <div className="mt-8 xl:mt-0">
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Contact Us
            </h3>
            <p className="mt-4 text-base font-semibold text-gray-300">
              Customer Service & Technical Support
            </p>
            <p className="text-base text-gray-300">
              <span className="font-bold">Toll free: </span>(866) 792-7669
            </p>
            <p className="text-base text-gray-300">
              <span className="font-bold">E-mail: </span>
              <a href="mailto:support@swapalease.com">support@swapalease.com</a>
            </p>
            <h3 className="mt-8 text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Business Hours
            </h3>
            <p className="mt-4 text-base font-semibold text-gray-300">
              Monday - Friday: 9AM - 8PM EST
            </p>
            <p className="text-base text-gray-300">
              <span className="font-bold">Saturday: </span>9AM - 4PM EST
            </p>
            <p className="text-base text-gray-300 font-bold">Closed Sunday</p>

            <h3 className="mt-8 text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Business Office
            </h3>
            <p className="mt-4 text-base font-semibold text-gray-300">
              Monday - Friday: 9AM - 8PM EST
            </p>
            <p className="text-base text-gray-300">
              <span className="font-bold">Phone: </span>(513) 381-0100
            </p>
            <p className="text-base text-gray-300">
              <span className="font-bold">Fax: </span>(513) 381-1091
            </p>

            <h3 className="mt-8 text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Mailing Address
            </h3>
            <p className="text-base text-gray-300 mt-4">11224 Cornell Park Drive</p>
            <p className="text-base text-gray-300 font-bold">Cincinnati, Ohio 45242</p>
            <hr className="mt-4 w-10 border-2 border-white border-opacity-30" />
          </div>
        </div>

        <div className="flex justify-between w-full mt-8 flex-wrap">
          <div className="w-52">
            <SelectMenu
              onChange={option => router.push(option.id as string)}
              options={navigation.states}
              placeholder="Popular States"
            />
          </div>
          <div className="w-52">
            <SelectMenu
              onChange={option => router.push(option.id as string)}
              options={navigation.makes}
              placeholder="Popular Makes"
            />
          </div>
          <div className="w-52">
            <SelectMenu
              onChange={option => router.push(option.id as string)}
              options={navigation.models}
              placeholder="Popular Models"
            />
          </div>
          <div className="w-52">
            <SelectMenu
              onChange={option => router.push(option.id as string)}
              options={navigation.cities}
              placeholder="Popular Cities"
            />
          </div>
        </div>

        <div className="mt-4 border-t border-gray-700 md:flex md:items-center md:justify-between flex-wrap">
          <ul className="mt-4 flex justify-between flex-wrap">
            {navigation.extra1.map(item => (
              <li key={item.name} className="mt-4 sm:mt-0">
                <a href={item.href} className="text-base text-gray-300 hover:text-white pr-4">
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="md:flex md:items-center justify-start md:justify-between flex-wrap">
          <ul className="mt-4 flex justify-between flex-wrap">
            {navigation.extra2.map(item => (
              <li key={item.name} className="mt-4 sm:mt-0">
                <a href={item.href} className="text-base text-gray-300 hover:text-white pr-4">
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <PopupAlert />
    </footer>
  );
};

export default Footer;
