import { Button, Divider, TextLink } from 'src/components/ui';
import { CheckIcon } from '@heroicons/react/outline';
import utils from 'src/utils';

interface Option {
  description?: string;
  duration?: string;
  title?: string;
  notes?: string[];
  price?: string;
  priceSubtext?: string;
}

interface Props {
  href?: string;
  index?: number;
  onSelect?: (option: Option) => void;
  option: Option;
  showButton?: boolean;
}

const PlanCard: React.FC<Props> = ({
  option,
  index = 0,
  onSelect,
  showButton = true,
  href = '#',
}: Props) => {
  const renderDetailRow = (detail: string): React.ReactNode => (
    <div key={`${option.title}-${index}-${detail}`} className="flex mb-6">
      <CheckIcon className="h-6 w-6 text-green-500 mr-4 -mt-1 flex-shrink-0" aria-hidden="true" />
      <div className="text-sm font-bold text-slate text-left">{detail}</div>
    </div>
  );

  return (
    <div
      key={`${option.title}-${index}-${option.duration}`}
      className="border-2 border-platinum mt-4 m-0 md:m-3 rounded-xl flex-shrink-0 h-700 w-full md:w-72"
    >
      <div className="p-6">
        <h3 className="text-xl text-charcoal font-bold text-left">{option.title}</h3>
        {option?.price ? (
          <div className="font-extrabold text-4xl text-charcoal mt-8 text-left">
            {utils.localizeCurrency(option.price)}
            <span className="text-base font-bold text-slate ml-2">
              {option?.duration && `for ${option.duration}`}
            </span>
          </div>
        ) : null}
        {option?.priceSubtext && (
          <div className="font-bold text-slate my-1 text-left">{option?.priceSubtext}</div>
        )}
        {option?.price && showButton && (
          <Button
            fullWidth
            primary
            href={href}
            size="md"
            styles="whitespace-nowrap my-8"
            onClick={() => onSelect(option)}
          >
            {option?.title}
          </Button>
        )}

        {!option?.price && (
          <div className="font-bold text-charcoal my-8 text-left">
            Call{' '}
            <TextLink size="md" href="tel:1-877-893-1588">
              1-877-893-1588
            </TextLink>{' '}
            for Pricing
          </div>
        )}
      </div>
      <Divider />
      <div className="p-6 pb-8 md:pb-36">
        <h6 className="text-xs text-charcoal uppercase font-bold mb-6 text-left">
          What’s included
        </h6>
        {option?.notes?.map(option => renderDetailRow(option))}
      </div>
    </div>
  );
};

export default PlanCard;
