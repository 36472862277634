import { Button, Divider, Modal } from 'src/components/ui';
import { ContactSpecialistModalFooter } from 'src/components';
import { CheckIcon } from '@heroicons/react/outline';
import utils from 'src/utils';

interface Option {
  description?: string;
  name?: string;
  price?: number;
  duration?: string;
  notes: string[];
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSelect?: (option: Option) => void;
  options: Option[];
}

const BuyerPricingPlansModal: React.FC<Props> = ({
  isOpen = false,
  options,
  onClose,
  onSelect,
}: Props) => {
  const renderDetailRow = (detail: string): React.ReactNode => (
    <div key={detail} className="flex mb-6">
      <CheckIcon className="h-6 w-6 text-green-500 mr-4 -mt-1" aria-hidden="true" />
      <div className="text-sm font-bold text-slate">{detail}</div>
    </div>
  );

  const renderCard = (option: Option, index: number): React.ReactNode => (
    <div
      key={`${option.name}-${index}`}
      className="border-2 border-platinum text-left mt-4 m-0 md:m-3 rounded-xl w-full md:w-72"
    >
      <div className="p-6">
        <h3 className="text-xl text-charcoal font-bold">{option.name}</h3>
        <div className="font-extrabold text-4xl text-charcoal mt-8">
          {utils.localizeCurrency(option.price)}
          <span className="hidden sm:inline text-base font-bold text-slate ml-2">{`for ${option?.duration}`}</span>
        </div>
        <div className="sm:hidden text-base font-bold text-slate ml-2">{`for ${option?.duration}`}</div>
        <Button
          fullWidth
          primary
          size="md"
          styles="whitespace-nowrap my-8"
          onClick={() => onSelect(options[index])}
        >
          <div className="hidden sm:block">{`Buy ${option.name}`}</div>
          <div className="block sm:hidden">{option.name}</div>
        </Button>
      </div>
      <Divider />
      <div className="p-6 pb-8 md:pb-36">
        <h6 className="text-xs text-charcoal uppercase font-bold mb-6">What’s included</h6>
        {option?.notes?.map(detail => renderDetailRow(detail))}
      </div>
    </div>
  );

  return (
    <Modal
      footer={<ContactSpecialistModalFooter />}
      isOpen={isOpen}
      onClose={onClose}
      showButtons={false}
      size="xl"
      title="Buyer Membership Details"
    >
      <div className="flex justify-center flex-wrap mb-8 mx-4">
        {options?.map((option, index) => renderCard(option, index))}
      </div>
    </Modal>
  );
};

export default BuyerPricingPlansModal;
