import { PhoneIcon } from '@heroicons/react/outline';

interface Props {
  primary?: boolean;
}

const ContactSpecialistBanner: React.FC<Props> = ({ primary = false }: Props) => {
  const bgColor = primary ? 'bg-medium-blue' : 'bg-frost';
  const textColor = primary ? 'text-white' : 'text-primary';
  return (
    <div className={`relative flex justify-center py-4 ${bgColor}`}>
      <PhoneIcon className={`h-4 w-4 sm:h-6 sm:w-6 mr-2 ${textColor}`} aria-hidden="true" />
      <div className={`text-white font-bold text-xs sm:text-base ${textColor}`}>
        <a href="tel:1-888-683-5326">Talk to a Lease Specialist • 1-888-683-5326</a>
      </div>
    </div>
  );
};

export default ContactSpecialistBanner;
