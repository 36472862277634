import { Footer, AdminNavigation } from 'src/components';
import Head from 'next/head';

interface LayoutProps {
  children?: JSX.Element | JSX.Element[];
  title?: string;
}

const AdminLayout: React.FC<LayoutProps> & { auth: boolean } = ({ title = '', children }) => {
  return (
    <div className="bg-frost">
      <Head>
        <title>Swapalease{title && ` - ${title}`}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <AdminNavigation />

      <main>
        <div className="max-w-6xl mt-12 mx-auto lg:px-4">
          <h1 className="font-bold md:block text-4xl text-charcoal pl-4 lg:pl-0">{title}</h1>
          <div className="max-w-7xl mx-auto pb-10 px-0 md:px-2 md:py-4 rounded-lg">{children}</div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

AdminLayout.auth = true;

export default AdminLayout;
