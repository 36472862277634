import React, { createContext, useContext, useReducer } from 'react';
import { BillingState } from 'src/types';

interface Action {
  type: string;
  payload?: object;
}

interface Props {
  children: any;
}

const initialState: BillingState = {
  address: '',
  address2: '',
  callbackUrl: '',
  cardType: '',
  cardNumber: '',
  selectedPlan: null,
  city: '',
  cvc: '',
  month: '',
  listingPackageId: 0,
  name: '',
  state: '',
  transactionId: '',
  vehicleId: '',
  vehicleDescription: '',
  year: '',
  zip: '',
};

const actionTypes = {
  update: 'update',
};

const BillingStateContext = createContext<BillingState>(initialState);
const BillingDispatchContext = createContext<React.Dispatch<Action> | null>(null);

function BillingReducer(state: BillingState, action: any) {
  switch (action.type) {
    case actionTypes.update: {
      const newState = {
        ...state,
        ...action.payload.props,
      };
      return newState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function BillingProvider(props: Props = { children: null }) {
  const [state, dispatch] = useReducer(BillingReducer, initialState, state => ({ ...state }));
  return (
    <BillingStateContext.Provider value={state}>
      <BillingDispatchContext.Provider value={dispatch}>
        {props.children}
      </BillingDispatchContext.Provider>
    </BillingStateContext.Provider>
  );
}

const updateBilling = (billingProps = {}) => ({
  type: actionTypes.update,
  payload: {
    props: billingProps,
  },
});

function useBillingState() {
  const context = useContext(BillingStateContext);
  if (context === undefined) {
    throw new Error(`useBillingState must be used within a BillingProvider`);
  }
  return context;
}

function useBillingDispatch() {
  const context = useContext(BillingDispatchContext);
  if (context === undefined) {
    throw new Error(`useBillingDispatch must be used within a BillingProvider`);
  }
  return context;
}

function useBilling() {
  const context: [BillingState, React.Dispatch<Action>] = [
    useBillingState(),
    useBillingDispatch() as React.Dispatch<Action>,
  ];

  if (context === undefined) {
    throw new Error(`useBilling must be used within a BillingProvider`);
  }
  return context;
}

export {
  BillingProvider as default,
  initialState,
  useBilling,
  useBillingState,
  useBillingDispatch,
  updateBilling,
};
