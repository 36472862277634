import { Modal } from 'src/components/ui';
import { ContactSpecialistModalFooter } from 'src/components';
import { PlanCard } from 'src/components';

interface Option {
  description?: string;
  name?: string;
  price?: string;
  duration?: string;
  priceSubtext?: string;
  options?: string[];
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSelect?: (option: Option) => void;
  options: Option[];
}

const SellerPricingPlansModal: React.FC<Props> = ({
  isOpen = false,
  options,
  onClose,
  onSelect,
}: Props) => (
  <Modal
    footer={<ContactSpecialistModalFooter />}
    isOpen={isOpen}
    onClose={onClose}
    showButtons={false}
    size="xl"
    title="Seller Membership Details"
  >
    <div className="font-bold text-sm text-slate mb-4">
      List your lease on Swapalease for exposure on the #1 lease marketplace!
    </div>
    <div className="flex justify-center flex-wrap mb-8 mx-4">
      {options?.map((option, index) => (
        <div key={`${option}-${index}`}>
          <PlanCard option={option} index={index} onSelect={onSelect} showButton={false} />
        </div>
      ))}
      <div className="font-bold text-charcoal my-6">
        Upgrades available to all seller memberships:{' '}
        <span className="font-normal">
          2 week featured autos, sponsored listings, home page & featured auto placements, social
          media package, and newsletter exposure.
        </span>
      </div>
    </div>
  </Modal>
);

export default SellerPricingPlansModal;
