import { Button, Modal, SelectMenu, TextLink } from 'src/components/ui';
import { Vehicle } from 'src/types';

interface Option {
  id: string;
  value: string;
  drive?: string;
  msrp?: string;
  mpg?: string;
}

interface VehicleData {
  vehicleData?: Vehicle;
  styleOptions?: Option[];
}

interface Props {
  onChange: (option: Option) => void;
  onClose: () => void;
  onCancel: () => void;
  vehicle: VehicleData;
  selectedStyle: string;
  isOpen: boolean;
}

const EquipmentList: React.FC<Props> = ({
  isOpen = false,
  onChange,
  onCancel,
  onClose,
  selectedStyle,
  vehicle,
}: Props) => {
  const noVehicleSection = (
    <>
      <h3 className="font-bold text-lg text-steel text-left">Sorry, no vehicle found.</h3>
      <h3 className="font-bold text-lg text-steel text-center my-16">
        Would you like to try again?
      </h3>
      <Button onClick={() => onClose()} fullWidth primary size="md">
        Try again
      </Button>
    </>
  );

  const foundVehicleSection = (
    <>
      <h3 className="font-bold text-xl text-steel text-left mb-6">Is this your vehicle?</h3>
      <h3 className="font-bold text-xl text-charcoal text-center mb-6">{`${
        vehicle?.vehicleData?.year || ''
      } ${vehicle?.vehicleData?.make || ''} ${vehicle?.vehicleData?.model || ''}`}</h3>

      <div className="text-left mb-6">
        <p className="px-4 mt-6 text-sm text-slate">Style/Body</p>
        {vehicle?.styleOptions ? (
          <SelectMenu
            onChange={(option: Option) => onChange(option)}
            options={vehicle?.styleOptions}
            placeholder="Select a style"
            selectedOption={vehicle?.styleOptions?.find(option => option.id === selectedStyle)}
            styles="mt-1 px-4"
          />
        ) : (
          <p className="font-bold px-4 mt-1">{vehicle?.vehicleData?.bodyStyle || 'Unknown'}</p>
        )}

        <p className="px-4 mt-6 text-sm text-slate">Engine</p>
        <p className="font-bold px-4 mt-1">{vehicle?.vehicleData?.engine || 'Unknown'}</p>
        <p className="px-4 mt-6 text-sm text-slate">Drive</p>
        <p className="font-bold px-4 mt-1">{vehicle?.vehicleData?.drive || 'Unknown'}</p>
      </div>
      <Button onClick={() => onClose()} fullWidth href="/create/user-details" primary size="md">
        Yes, that&apos;s correct.
      </Button>
      <TextLink href="/create" size="md" scroll onClick={() => onCancel()}>
        <div className="font-bold mt-4">No, try another vehicle.</div>
      </TextLink>
    </>
  );

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()} showButtons={false}>
      <>{vehicle ? foundVehicleSection : noVehicleSection}</>
    </Modal>
  );
};

export default EquipmentList;
