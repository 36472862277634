import Image from 'next/image';
import { useState } from 'react';
import { Stepper } from 'src/components/ui';
import { ChevronRightIcon } from '@heroicons/react/solid';

interface Testimonial {
  title: string;
  description: string;
  name: string;
  userImage: string;
}

interface Props {
  testimonials: Testimonial[];
}

const TestimonialsPreview: React.FC<Props> = ({ testimonials = [] }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const selectedTestimonial = testimonials[selectedIndex];
  const handleDotClick = (index: number): void => setSelectedIndex(index - 1);

  return (
    <section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-16">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          role="img"
          aria-labelledby="svg-workcation"
        >
          <title id="svg-workcation">Testimonials</title>
          <defs>
            <pattern
              id="ad119f34-7694-4c31-947f-5c9d249b21f3"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect width={404} height={404} fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)" />
        </svg>

        <div className="relative">
          <div className="w-full">
            <div className="w-48 mx-auto">
              <Image src="/svg/sal-logo-text.svg" alt="Swapalease logo" width="176" height="34" />
            </div>
          </div>
          <blockquote className="mt-10">
            <div className="max-w-3xl mx-auto text-2xl leading-9 font-medium text-gray-900">
              <p>{selectedTestimonial?.description}</p>
            </div>
            <footer className="mt-8">
              <div className="flex items-center justify-center lg:justify-start max-w-3xl mx-auto">
                <div className="md:flex-shrink-0">
                  <Image
                    className="mx-auto h-10 w-10 rounded-full mr-2 md:mr-0 oveflow-hidden"
                    alt="user-icon"
                    height={40}
                    layout="fixed"
                    src={selectedTestimonial?.userImage}
                    width={40}
                  />
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">
                    {selectedTestimonial?.name}
                  </div>

                  <svg
                    className="hidden md:block mx-1 h-5 w-5 text-indigo-600"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-gray-500">
                    {selectedTestimonial?.title}
                  </div>
                </div>
              </div>
              <div className="flex justify-between flex-col lg:flex-row items-center max-w-3xl mt-8 mx-auto xl:pr-8">
                <div className="ml-2 mb-6 xl:mb-0">
                  <Stepper
                    step={selectedIndex + 1}
                    totalSteps={testimonials?.slice(0, 5).length}
                    onClick={handleDotClick}
                    compact
                  />
                </div>
                <a
                  className="font-extrabold relative text-md text-primary hover:underline"
                  href="/learn/testimonials"
                >
                  <div className="flex">
                    View All Testimonials
                    <ChevronRightIcon className="h-6 w-6 -mt-0.5 text-primary" aria-hidden="true" />
                  </div>
                </a>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsPreview;
