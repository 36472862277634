// disclosure - https://headlessui.dev/react/disclosure

import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

interface Props {
  children?: React.ReactNode;
  styles?: string;
  title?: string | React.ReactNode;
}

const DisclosureArea: React.FC<Props> = ({ children, styles, title }: Props) => {
  return (
    <div className={`w-full mx-auto bg-white pt-2 ${styles}`}>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full py-4 text-sm font-bold text-left rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75">
              <span className="pl-2 md:pl-0 w-full">{title}</span>
              <ChevronDownIcon
                className={`${
                  open ? 'transform -rotate-90' : ''
                } w-6 h-6 text-primary mr-2 md:mr-0`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-sm text-gray-500">{children}</Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default DisclosureArea;
