import Image from 'next/image';
import { LocationMarkerIcon } from '@heroicons/react/outline';
import { HeartIcon } from '@heroicons/react/solid';
import { Button, TextLink } from 'src/components/ui';
import utils from 'src/utils';

type Vehicle = {
  defaultVehicleCategory?: string;
  bodyStyle?: string;
  drive?: string;
  engine?: string;
  imageUrl?: string;
  location?: string;
  make?: string;
  mileage?: string;
  model?: string;
  monthlyPayment?: string;
  monthsLeft?: number;
  milesPerMonth?: number;
  year?: string;
};

interface Props {
  vehicle: Vehicle;
  onRemove: () => void;
}

const WatchlistCard: React.FC<Props> = ({
  onRemove,
  vehicle = {
    bodyStyle: '',
    defaultVehicleCategory: '',
    drive: '',
    engine: '',
    imageUrl: '',
    location: '',
    make: '',
    mileage: '',
    model: '',
    monthlyPayment: '',
    milesPerMonth: 0,
    monthsLeft: 0,
    year: '',
  },
}: Props) => {
  const mapDriveTrain = (): string => {
    const is4x4 = vehicle?.drive?.match(/4|four/gim);
    const isAWD = vehicle?.drive?.match(/all/gim);
    const isFWD = vehicle?.drive?.match(/front/gim);
    const isRWD = vehicle?.drive?.match(/rear/gim);

    if (is4x4) return '4x4';
    if (isAWD) return 'AWD';
    if (isFWD) return 'FWD';
    if (isRWD) return 'RWD';

    return '';
  };

  return (
    <div className="border-b border-platinum grid grid-col-1 lg:grid-cols-4 gap-4 pb-8 text-charcoal">
      <div className="col-span-2 relative z-10 col-start-1 row-start-1 px-4 pt-4 pb-3 h-56">
        {vehicle?.location ? (
          <div className="absolute flex bg-opacity-80 bg-slate font-medium items-center justify-center px-2 py-1 rounded-md text-sm text-white">
            <LocationMarkerIcon className="h-6 w-6" />
            <span className="pl-1">{vehicle?.location}</span>
          </div>
        ) : null}
        <a
          href="#"
          className="absolute bg-white -bottom-3 right-4 rounded-full shadow-md text-primary z-10"
        >
          <div className="h-12 relative w-12">
            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <HeartIcon height="24" width="24" />
            </div>
          </div>
        </a>
      </div>

      <div className="col-span-2 col-start-1 row-start-1 flex">
        <div className="w-full grid grid-cols-3 grid-rows-2 gap-2">
          <div className="relative col-span-3 row-span-2">
            <Image
              alt="Car Image"
              className="absolute inset-0 object-fit rounded-xl"
              layout="fill"
              src={vehicle?.imageUrl}
            />
          </div>
        </div>
      </div>

      <div className="col-span-full col-start-3 flex flex-col h-full justify-between space-y-4">
        <div className="space-y-1 xs:space-y-0">
          <h3 className="font-bold pt-2 text-xl">{`${vehicle?.year || ''} ${vehicle?.make || ''} ${
            vehicle?.model || ''
          }`}</h3>

          <div className="font-medium text-slate text-sm">
            {mapDriveTrain()} {vehicle?.engine} {vehicle?.bodyStyle}{' '}
            {vehicle?.defaultVehicleCategory}
          </div>

          <div className="flex justify-between">
            <span className="bg-barely-blue font-bold p-1 rounded text-charcoal text-sm">
              Lease Transfer
            </span>
            <div className="flex font-bold items-center text-primary text-xl">
              {utils.localizeCurrency(vehicle?.monthlyPayment)}
              <span className="font-normal pl-1 text-base text-slate">/ mo</span>
            </div>
          </div>

          <div className="flex flex-col xs:flex-row font-medium xs:space-x-2 space-y-1 xs:space-y-0">
            {vehicle?.monthsLeft ? (
              <>
                <div className="whitespace-nowrap">
                  {vehicle?.milesPerMonth}
                  <span className="font-normal pl-1 text-slate">miles / month</span>
                </div>
                <div className="whitespace-nowrap">
                  {vehicle?.monthsLeft}
                  <span className="font-normal pl-1 text-slate">months left</span>
                </div>
              </>
            ) : (
              <div className="font-normal pl-1 text-slate">Expired</div>
            )}
          </div>
        </div>

        <div className="flex flex-col xs:flex-row items-center justify-between space-y-4 xs:space-y-0">
          <TextLink size="sm" onClick={onRemove}>
            Remove from favorites
          </TextLink>
          <Button href="#" size="sm" primary>
            Contact Seller
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WatchlistCard;
