import Link from 'next/link';

interface Props {
  title: string;
  to: string;
  disabled?: boolean;
}

const NavLink: React.FC<Props> = ({ title, to }: Props) => (
  <Link href={to}>
    <a className="border-transparent text-charcoal hover:border-primary hover:bg-secondary hover:text-secondary inline-flex items-center px-1 pt-1 border-b-2 text-sm font-bold">
      {title}
    </a>
  </Link>
);

export default NavLink;
