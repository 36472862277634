import { Switch } from '@headlessui/react';
import { classNames } from 'src/utils/classNames';

interface Props {
  isChecked: boolean;
  onChange: (e: boolean) => void;
}

const Toggle: React.FC<Props> = ({ onChange, isChecked = false }: Props) => {
  return (
    <Switch
      checked={isChecked}
      onChange={e => onChange && onChange(e)}
      className={classNames(
        isChecked ? 'bg-primary' : 'bg-gray-200',
        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          isChecked ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
        )}
      />
    </Switch>
  );
};

export default Toggle;
