import { useRouter } from 'next/router';
import { ChevronLeftIcon } from '@heroicons/react/solid';

interface Props {
  showBackText?: boolean;
  styles?: string;
  title?: string;
}

const BackBanner: React.FC<Props> = ({ showBackText = false, styles = '', title = '' }: Props) => {
  const router = useRouter();

  return (
    <div
      className={`flex justify-center w-full h-12 pl-2 bg-frost text-primary font-extrabold border-t-2 border-silver ${styles}`}
    >
      <div className="max-w-lg flex items-center w-full">
        <ChevronLeftIcon
          className="h-8 w-8 text-primary cursor-pointer"
          onClick={() => router.back()}
        />
        {showBackText ? 'Back' : null}
        <p className="font-bold text-slate mx-auto pr-8">{title}</p>
      </div>
    </div>
  );
};

export default BackBanner;
