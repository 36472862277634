import { XCircleIcon } from '@heroicons/react/solid';

interface Props {
  title: string;
  description: string;
}

// Currently only for error alerts
// Can extend colors/icons for other types
const InlineAlert: React.FC<Props> = ({ title, description }) => (
  <div className="rounded-md bg-red-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-red-700">{title}</h3>
        <div className="mt-2 text-sm text-red-700">
          <p>{description}</p>
        </div>
      </div>
    </div>
  </div>
);

export default InlineAlert;
