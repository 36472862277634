import { Divider } from 'src/components/ui';

interface State {
  address?: string;
  address2?: string;
  cardNumber?: string;
  city?: string;
  cvc?: string;
  month?: string;
  name?: string;
  state?: string;
  year?: string;
  zip?: string;
}

interface Props {
  onUpdate: (payload: State) => void;
  state?: State;
}

const BillingForm: React.FC<Props> = ({ onUpdate, state }: Props) => {
  return (
    <div className="flex flex-col w-full h-auto mb-4">
      <form className="pt-2" id="paymentForm" method="POST">
        <h3 className="font-bold text-xl text-slate text-left mt-3">Credit Card</h3>
        <div className="mt-1 mb-8 md:w-1/2">
          <div className="mt-1">
            <input
              autoComplete="card"
              className="shadow-sm rounded-bl-none rounded-br-none focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
              id="cardNumber"
              maxLength={20}
              name="cardNumber"
              onChange={e => onUpdate({ cardNumber: e?.target?.value?.toString() })}
              placeholder="card number"
              type="tel"
              value={state?.cardNumber}
            />
          </div>
          <div className="flex">
            <div className="w-1/3">
              <input
                autoComplete="expMonth"
                className="shadow-sm border-t-0 border-r-0 rounded-tl-none rounded-tr-none rounded-br-none focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                id="expMonth"
                maxLength={2}
                name="expMonth"
                onChange={e => onUpdate({ month: e?.target?.value?.toString() })}
                placeholder="month"
                type="tel"
                value={state?.month}
              />
            </div>
            <div className="w-1/3">
              <input
                autoComplete="expYear"
                className="shadow-sm border-t-0 border-l-1 rounded-tl-none rounded-tr-none rounded-br-none rounded-bl-none focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                id="expYear"
                maxLength={2}
                name="expYear"
                onChange={e => onUpdate({ year: e?.target?.value?.toString() })}
                placeholder="year"
                type="tel"
                value={state?.year}
              />
            </div>
            <div className="w-1/3">
              <input
                autoComplete="cvc"
                className="border-l-0 border-t-0 rounded-tl-none rounded-tr-none rounded-bl-none shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                id="cardCode"
                maxLength={4}
                name="cardCode"
                onChange={e => onUpdate({ cvc: e?.target?.value })}
                placeholder="cvc"
                type="text"
                value={state?.cvc}
              />
            </div>
          </div>
        </div>
        <input type="hidden" name="dataValue" id="dataValue" />
        <input type="hidden" name="dataDescriptor" id="dataDescriptor" />
        <Divider />
        <h3 className="font-bold text-xl text-slate text-left mt-3">Billing Information</h3>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="email" className="text-left block text-sm font-bold text-steel">
              Name on card
            </label>
            <div className="mt-1">
              <input
                autoComplete="name"
                className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                id="name"
                name="name"
                onChange={e => onUpdate({ name: e?.target?.value })}
                type="text"
                value={state?.name}
              />
            </div>
          </div>
          <div className="sm:col-span-3"></div>
          <div className="sm:col-span-3">
            <label
              htmlFor="street-address"
              className="text-left block text-sm font-bold text-steel"
            >
              Street address
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="street-address"
                id="street-address"
                autoComplete="street-address"
                className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                onChange={e => onUpdate({ address: e?.target?.value })}
                value={state?.address}
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="street-address"
              className="flex block text-sm font-bold text-steel w-full"
            >
              <div className="flex justify-between w-full">
                Street address 2<div className="mr-1 font-normal">Optional</div>
              </div>
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="street-address-2"
                id="street-address-2"
                autoComplete="street-address-2"
                className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                onChange={e => onUpdate({ address2: e?.target?.value })}
                value={state?.address2}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="city" className="text-left block text-sm font-bold text-steel">
              City
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="city"
                id="city"
                className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                onChange={e => onUpdate({ city: e?.target?.value })}
                value={state?.city}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="state" className="text-left block text-sm font-bold text-steel">
              State / Province
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="state"
                id="state"
                className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                onChange={e => onUpdate({ state: e?.target?.value })}
                value={state?.state}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="zip" className="text-left block text-sm font-bold text-steel">
              ZIP / Postal
            </label>
            <div className="mt-1">
              <input
                type="tel"
                name="zip"
                id="zip"
                maxLength={5}
                autoComplete="postal-code"
                className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                onChange={e => onUpdate({ zip: e?.target?.value?.toString() })}
                value={state?.zip}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BillingForm;
