import { ChevronRightIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline';

interface Card {
  description: string;
  link: string;
  status: boolean;
  title: string;
}

interface Props {
  cards: Card[];
}

const HorizontalLinkCards: React.FC<Props> = ({ cards = [] }: Props) => (
  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
    {cards.map(card => (
      <div
        key={card?.title}
        className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
      >
        <div className="relative flex-1 min-w-0">
          <a href={card?.link} className="focus:outline-none">
            <div className="flex justify-between">
              <div className="flex flex-col overflow-hidden">
                <p className="font-medium text-gray-900 h-auto truncate">{card?.title}</p>
                <div className="flex">
                  {card.status ? (
                    <CheckCircleIcon className="h-4 w-4 text-green-500 mt-0.5 mr-1" />
                  ) : (
                    <ExclamationCircleIcon className="h-4 w-4 text-red-500 mt-0.5 mr-1" />
                  )}
                  <p className="text-sm text-gray-500 truncate">{card?.description}</p>
                </div>
              </div>

              <div className="flex justify-center items-center">
                <ChevronRightIcon className=" right-0 top-2 h-6 w-6 text-primary" />
              </div>
            </div>
          </a>
        </div>
      </div>
    ))}
  </div>
);

export default HorizontalLinkCards;
