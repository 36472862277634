import { ContentCard } from 'src/components';

const cards = [
  {
    title: 'Get Out',
    subtitle: 'Exit your lease',
    description:
      'Look here to exit your lease. This is a bunch of text describing how you can exit your lease',
    actionText: 'Learn More',
    actionUrl: '/learn/how-it-works-for-sellers',
    imageUrl: '/get-out.png',
  },
  {
    title: 'Get In',
    subtitle: 'Take over a lease',
    description:
      'Look here to get in a lease. This is a bunch of text describing how you can enter a lease',
    actionText: 'Learn More',
    actionUrl: '/learn/how-it-works-for-buyers',
    imageUrl: '/get-in.png',
  },
];

const LeaseGrid: React.FC = () => (
  <div className="mx-auto px-4 max-w-6xl sm:px-6 pb-8">
    <div className="space-y-12">
      <ul className="space-y-12 sm:grid grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0">
        {cards.map((card, idx) => (
          <li key={idx}>
            <ContentCard {...card} />
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default LeaseGrid;
