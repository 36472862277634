import React, { createContext, useContext, useReducer } from 'react';
import { CreateListingState } from 'src/types';

interface Action {
  type: string;
  payload?: object;
}

interface Props {
  children: any;
}

const initialState: CreateListingState = {
  bodyStyle: '',
  canTransfer: false,
  country: '',
  currentMileage: '',
  description: '',
  drive: '',
  email: '',
  endTimeRestriction: '',
  engine: '',
  exteriorColor: null,
  firstName: '',
  interiorColor: null,
  lastName: '',
  leaseEndBuyoutAmount: '',
  leasingCompanyId: '',
  make: '',
  makeRefId: '',
  membership: null,
  model: '',
  modelRefId: '',
  monthlyLeaseMileage: '',
  monthlyPayment: '',
  options: [],
  phone: '',
  questions: [],
  remainingMonths: '',
  state: '',
  trim: 0,
  transmissionId: 1,
  userId: null,
  vehicleId: null,
  vehicleStatusId: 0,
  vin: '',
  year: '',
  zipcode: '',
};

const actionTypes = {
  update: 'update',
};

const CreateListingStateContext = createContext<CreateListingState>(initialState);
const CreateListingDispatchContext = createContext<React.Dispatch<Action> | null>(null);

function createListingReducer(state: CreateListingState, action: any) {
  switch (action.type) {
    case actionTypes.update: {
      const newState = {
        ...state,
        ...action.payload.props,
      };
      return newState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function CreateListingProvider(props: Props = { children: null }) {
  const [state, dispatch] = useReducer(createListingReducer, initialState, state => ({ ...state }));
  return (
    <CreateListingStateContext.Provider value={state}>
      <CreateListingDispatchContext.Provider value={dispatch}>
        {props.children}
      </CreateListingDispatchContext.Provider>
    </CreateListingStateContext.Provider>
  );
}

const updateCreateListing = (createListingProps = {}) => ({
  type: actionTypes.update,
  payload: {
    props: createListingProps,
  },
});

function useCreateListingState() {
  const context = useContext(CreateListingStateContext);
  if (context === undefined) {
    throw new Error(`useCreateListingState must be used within a CreateListingProvider`);
  }
  return context;
}

function useCreateListingDispatch() {
  const context = useContext(CreateListingDispatchContext);
  if (context === undefined) {
    throw new Error(`useCreateListingDispatch must be used within a CreateListingProvider`);
  }
  return context;
}

function useCreateListing() {
  const context: [CreateListingState, React.Dispatch<Action>] = [
    useCreateListingState(),
    useCreateListingDispatch() as React.Dispatch<Action>,
  ];

  if (context === undefined) {
    throw new Error(`useCreateListing must be used within a CreateListingProvider`);
  }
  return context;
}

export {
  CreateListingProvider as default,
  initialState,
  useCreateListing,
  useCreateListingState,
  useCreateListingDispatch,
  updateCreateListing,
};
