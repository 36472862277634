export const localizeCurrency = (currency: number | string): string => {
  if (!currency) return '';
  const value = isNaN(currency as number) ? 0 : currency;
  return Number(value).toLocaleString('en-US', {
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency',
  });
}
