import Image from 'next/image';
import querystring from 'querystring';
import { useState, useEffect } from 'react';
import { Button, Input, Modal, TextLink } from 'src/components/ui';
import { SearchFilterMenu } from 'src/components';
import { getSearchStateFromParams, getAllFilters } from 'src/utils/search';
import {
  resetFilters,
  removeFilter,
  useSearch,
  updateSearchFilters,
} from 'src/providers/SearchProvider';

interface Ref {
  id: string;
}

interface Search {
  data: Data;
  ref: Ref;
}

interface RefData {
  id?: string | number;
}

interface VehicleData {
  vehModel: string;
}

interface ModelData {
  ref: RefData;
  data: VehicleData;
}

interface Data {
  searchUrl?: string;
  email?: Record<string, string>;
  title?: string;
  searchImage?: string;
  searchId?: string;
}

interface Props {
  search: Search;
  onDelete: (searchId: string) => void;
  onUpdate: (data: Data) => void;
}

const SavedSearchCard = ({ search, onDelete, onUpdate }: Props): JSX.Element => {
  const [state, dispatch] = useSearch();
  const [modelOptions, setModelOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const searchId = search?.ref?.['@ref']?.id;
  const searchImage = search?.data?.searchImage || '';

  const title = search?.data?.title || '';
  const [searchTitle, setSearchTitle] = useState(title);

  const searchUrl = search?.data?.searchUrl || '';
  const params = querystring.decode(searchUrl);
  delete params['/search?'];

  // Load saved search into state after initial hydration
  useEffect(() => {
    const hasParams = Object.keys(params).length;
    if (!initialized && hasParams) {
      const stateFromParams = getSearchStateFromParams(params, state);
      setSearchTitle(title);
      dispatch(updateSearchFilters({ ...stateFromParams }));
      setInitialized(true);
    }
  }, [params]);

  // Update model options when makes change
  useEffect(() => {
    getVehicleModelOptions();
  }, [state?.makeFilters]);

  const stateFromParams = getSearchStateFromParams(params, state);
  const allFilters = getAllFilters(stateFromParams);

  const getVehicleModelOptions = async (): Promise<void> => {
    const response = await fetch(`/api/getModelsByMake?make=${state.makeFilters[0]}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const modelData = await response.json();
    const options = modelData?.data
      ? modelData?.data.map((model: ModelData) => model?.data?.vehModel)?.sort()
      : [];
    setModelOptions(options);
  };

  const handleOnSave = (): void => {
    if (onUpdate) {
      onUpdate({
        searchUrl: state?.searchUrl,
        searchId: searchId,
        title: searchTitle || title,
      });
    }
  };

  const titleSection = (
    <div className="flex flex-col items-start w-full text-sm font-bold">
      <div className="pl-2">Search Title</div>
      <Input
        id="user-details-first-name-input"
        onChange={e => setSearchTitle(e?.target?.value)}
        placeholder={title}
        styles="mt-1 w-full"
        value={searchTitle}
      />
    </div>
  );

  const handleOnClose = (): void => {
    setIsModalOpen(false);
    const stateFromParams = getSearchStateFromParams(params, state);
    dispatch(updateSearchFilters({ ...stateFromParams }));
  };

  const handleOnSubmit = (): void => {
    handleOnSave();
    setIsModalOpen(false);
  };

  return (
    <div className="border-b border-platinum grid grid-col-1 sm:grid-cols-5 gap-4 pb-8 text-charcoal">
      <div className="col-span-2 relative col-start-1 row-start-1 flex h-56">
        <div className="w-full grid grid-cols-3 grid-rows-2 gap-2">
          <div className="relative col-span-3 row-span-2 rounded-xl bg-lighter-blue">
            {searchImage ? (
              <Image
                alt="Vehicle Image"
                className="absolute inset-0 object-fit"
                layout="fill"
                src={searchImage}
              />
            ) : (
              <div className="flex justify-center pt-3">
                <Image
                  alt="Vehicle Image"
                  layout="fixed"
                  width="200"
                  height="200"
                  src="/svg/sal-logo-mark.svg"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="col-span-full col-start-3 flex flex-col h-full justify-between space-y-4">
        <div className="space-y-2 xs:space-y-0">
          <h3 className="flex font-bold justify-center xs:justify-start pt-2 text-xl">{`${
            title || 'My Saved Search'
          }`}</h3>

          <div className="space-y-3">
            <div className="flex font-medium flex-wrap justify-center xs:justify-start text-slate">
              {allFilters.map((filter, index) => (
                <div key={`${index}-${filter}`}>{filter}&nbsp;</div>
              ))}
            </div>
            <div className="flex font-bold items-center justify-center xs:justify-start space-x-2 text-sm text-steel">
              <input
                className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded"
                defaultChecked={isChecked}
                id={`${title}-notify-me`}
                name="notify-me"
                onClick={() => {
                  setIsChecked(!isChecked);
                }}
                type="checkbox"
              />
              <label className="font-bold text-sm" htmlFor={`${title}-notify-me`}>
                Notify me of new listings
              </label>
            </div>
          </div>
        </div>

        <div className="flex flex-col xs:flex-row items-center justify-between space-y-4 xs:space-y-0">
          <TextLink
            size="sm"
            onClick={() => {
              setInitialized(false);
              onDelete(searchId);
            }}
          >
            Delete Search
          </TextLink>
          <TextLink
            size="sm"
            onClick={() => {
              setInitialized(false);
              setIsModalOpen(true);
            }}
          >
            Edit Search
          </TextLink>
          <Button size="sm" href={searchUrl} primary scroll>
            View Search
          </Button>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => handleOnClose()}
        onSubmit={() => handleOnSubmit()}
        submitButtonTitle="Save"
        closeButtonTitle="Cancel"
        title="Edit Saved Search"
      >
        <SearchFilterMenu
          dispatch={dispatch}
          filters={state}
          models={modelOptions}
          removeFilter={removeFilter}
          resetFilters={resetFilters}
          topSection={titleSection}
          updateSearchFilters={updateSearchFilters}
        ></SearchFilterMenu>
      </Modal>
    </div>
  );
};

export default SavedSearchCard;
