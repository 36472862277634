type Option = {
  id?: string | number;
  value?: string;
};

interface Props {
  icon?: string;
  onChange?: (arg: Option[]) => void;
  options: Option[];
  optionStyles?: string;
  selected?: Option[];
  styles?: string;
}

const MultiSelect: React.FC<Props> = ({
  selected,
  styles,
  options,
  optionStyles,
  onChange,
}: Props) => {
  const handleClick = (option: Option): void => {
    const currentSelection = [...selected];

    // Deselect the selected
    const isAlreadySelected = currentSelection.find(
      selectedOption => selectedOption.id === option.id
    );
    if (isAlreadySelected) {
      const filteredSelected = currentSelection.filter(
        selectedOption => selectedOption.id !== option.id
      );
      if (onChange) onChange(filteredSelected);
      return;
    }

    // Add new selected option
    currentSelection.push(option);
    if (onChange) onChange(currentSelection);
  };

  const getIsSelected = (option: Option): boolean => {
    const isSelected = selected?.some(selectedOption => selectedOption.id === option.id);

    return !!isSelected;
  };

  return (
    <div className={styles}>
      {options?.map((option, i) => (
        <div
          key={i}
          className={
            getIsSelected(option)
              ? `relative block rounded-lg border-2 border-primary bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-light-blue sm:flex sm:justify-between focus:outline-none mt-3 ${optionStyles}`
              : `relative block rounded-lg border-2 border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none mt-3 ${optionStyles}`
          }
          onClick={() => handleClick(option)}
        >
          <div className="flex justify-start items-center w-full">
            <div className="ml-1">{option.value}</div>
            <input
              id="push_email"
              name="push_notifications"
              type="checkbox"
              className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded ml-auto"
              checked={getIsSelected(option)}
              readOnly
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default MultiSelect;
