import { useEffect, useState } from 'react';
import LeaseCard from './LeaseCard';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Vehicle } from 'src/types';
import Link from 'next/link';

interface Props {
  categoryLinkUrl?: string;
  className?: string;
  title: string;
  vehicles: Vehicle[];
  onUpdate?: () => void;
}

const CardCarousel: React.FC<Props> = ({
  categoryLinkUrl = '',
  className = '',
  title = '',
  vehicles = [],
  onUpdate,
}: Props) => {
  const [activeCardsIndex, setActiveCardsIndex] = useState(3);
  const [columnCount, setColumnCount] = useState(3);

  const onInitialRender = (): (() => void | undefined) => {
    const isLg = window.matchMedia('(min-width: 1024px)').matches;
    const isSm = window.matchMedia('(min-width: 640px)').matches;

    if (isLg) {
      setActiveCardsIndex(3);
      setColumnCount(3);
    } else if (isSm) {
      setActiveCardsIndex(2);
      setColumnCount(2);
    } else {
      setActiveCardsIndex(1);
      setColumnCount(1);
    }

    const onResize = (): void => {
      const isLg = window.matchMedia('(min-width: 1024px)').matches;
      const isSm = window.matchMedia('(min-width: 640px)').matches;

      if (isLg) setColumnCount(3);
      else if (isSm) setColumnCount(2);
      else setColumnCount(1);
    };

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  };

  useEffect(onInitialRender, []);

  useEffect(() => {
    switch (columnCount) {
      case 3:
        if (activeCardsIndex < 3) setActiveCardsIndex(3);
        break;
      case 2:
        if (activeCardsIndex === 3 || activeCardsIndex < 2) setActiveCardsIndex(2);
        break;
      case 1:
        if (activeCardsIndex === 2 || activeCardsIndex === 3) setActiveCardsIndex(1);
    }
  }, [columnCount]);

  const handlePrevious = (): void => {
    if (!activeCardsIndex || activeCardsIndex - columnCount <= 0)
      setActiveCardsIndex(vehicles?.length);
    else if (activeCardsIndex - columnCount < columnCount) setActiveCardsIndex(columnCount);
    else setActiveCardsIndex(activeCardsIndex - columnCount);
  };

  const handleNext = (): void => {
    if (activeCardsIndex >= vehicles?.length) setActiveCardsIndex(columnCount);
    else setActiveCardsIndex(activeCardsIndex + columnCount);
  };

  return (
    <div className={`max-w-6xl mx-auto px-4 sm:px-6 ${className}`}>
      <div className="flex items-end pb-12 sm:pb-8 relative">
        <h3 className="flex flex-grow font-bold items-baseline text-3xl text-steel">
          {title}
          {categoryLinkUrl && (
            <a
              className="absolute bottom-6 sm:bottom-0.5 flex items-end sm:ml-4 sm:relative text-sm text-primary"
              href={categoryLinkUrl}
            >
              View All
              <ChevronRightIcon className="absolute -bottom-0.5 h-6 ml-1 -right-6 w-6" />
            </a>
          )}
        </h3>

        <div className="flex-shrink-0 text-primary">
          <button
            className="border-2 border-solid border-primary h-10 focus:outline-none relative rounded-full w-10"
            onClick={() => handlePrevious()}
            type="button"
          >
            <ChevronLeftIcon
              className="absolute h-8 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-8"
              aria-hidden="true"
            />
          </button>
          <button
            className="border-2 border-solid border-primary focus:outline-none h-10 ml-2 sm:ml-4 relative rounded-full w-10"
            onClick={() => handleNext()}
            type="button"
          >
            <ChevronRightIcon
              className="absolute h-8 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-8"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>

      <div className="h-80 relative w-full">
        <div className="absolute w-full">
          {vehicles.map((vehicle, index) => (
            <div
              className={`inline-block my-1 px-1 w-full sm:w-1/2 lg:w-1/3 ${
                index + 1 > activeCardsIndex - columnCount && index + 1 <= activeCardsIndex
                  ? 'block'
                  : 'hidden'
              }`}
              key={`${index}`}
            >
              <Link href={`/listing/${vehicle?.vehicleId}`}>
                <a>
                  <LeaseCard vehicle={vehicle} onUpdate={onUpdate} />
                </a>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardCarousel;
