interface Props {
  borderStyles?: string;
  styles?: string;
  title?: string;
  titleStyles?: string;
}

const Divider: React.FC<Props> = ({
  borderStyles = 'border-gray-300',
  styles = '',
  title,
  titleStyles = 'text-gray-500',
}: Props) => (
  <div className={`relative ${styles}`}>
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className={`w-full border-t ${borderStyles}`} />
    </div>
    <div className="relative flex justify-center">
      <span className={`px-2 bg-white text-md uppercase ${titleStyles}`}>{title || ''}</span>
    </div>
  </div>
);

export default Divider;
