import Link from 'next/link';

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  href?: string;
  icon?: JSX.Element;
  iconLeft?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  primary?: boolean;
  scroll?: boolean;
  size: 'sm' | 'md' | 'lg';
  styles?: string;
  type?: 'button' | 'submit' | 'reset';
}

const Button: React.FC<Props> = ({
  children,
  disabled = false,
  fullWidth = false,
  href = '',
  icon = null,
  iconLeft = true,
  onClick = null,
  primary = false,
  scroll = false,
  size = 'sm',
  styles = '',
  type = 'button',
}: Props) => {
  const bgColorClasses = primary
    ? `${disabled ? 'bg-sky-blue' : 'bg-primary'}`
    : `bg-white ${disabled ? '' : 'hover:bg-barely-blue'}`;
  const borderClasses = primary
    ? `border-2 ${disabled ? 'border-sky-blue' : 'hover:border-light-blue border-primary'}`
    : `border-2 ${disabled ? 'border-sky-blue' : 'hover:border-medium-blue border-primary'}`;
  const disabledClasses = disabled ? 'cursor-auto opacity-80' : '';
  const paddingClasses = size === 'sm' ? 'px-4 py-1.5' : size === 'md' ? 'px-5 py-2' : 'px-28 py-2';
  const textColorClasses = primary
    ? 'text-white'
    : `${disabled ? 'text-sky-blue' : 'text-primary'}`;
  const textSizeClasses = size === 'sm' ? 'text-sm' : 'text-md';
  const widthClasses = fullWidth ? 'w-full' : '';

  const buttonClasses = [
    'font-extrabold flex items-center justify-center focus:outline-none relative focus:ring-2 focus:ring-medium-blue focus:ring-offset-2 rounded-full shadow-sm',
    bgColorClasses,
    borderClasses,
    disabledClasses,
    paddingClasses,
    textColorClasses,
    textSizeClasses,
    widthClasses,
  ];

  return (
    <div className={`flex-shrink-0 ${styles} ${icon ? 'flex' : ''}`}>
      <Link href={href} scroll={scroll}>
        <button
          className={buttonClasses.join(' ')}
          disabled={disabled}
          onClick={onClick}
          type={type}
        >
          {icon && iconLeft && <div className="h-6 mr-1 w-6">{icon}</div>}
          {children}
          {icon && !iconLeft && <div className="h-6 ml-1 w-6">{icon}</div>}
        </button>
      </Link>
    </div>
  );
};

export default Button;
