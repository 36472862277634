import React from 'react';
import { WidgetLoader, Widget } from 'react-cloudinary-upload-widget';

interface ImageData {
  path?: string;
  url?: string;
}

interface Response {
  error: string;
  info: ImageData;
}

interface Styles {
  position?: string;
  height?: string;
  width?: string;
}

interface Props {
  successCallBack: (response: Response) => void;
  failureCallBack: (response: Response) => void;
  styles: Styles;
  text: string;
}

const CloudinaryUploadWidget: React.FC<Props> = ({
  successCallBack,
  failureCallBack,
  styles,
  text = 'Open',
}: Props) => {
  return (
    <>
      <WidgetLoader />
      <Widget
        sources={['local', 'dropbox']} // set the sources available for uploading -> by default
        // all sources are available. More information on their use can be found at
        // https://cloudinary.com/documentation/upload_widget#the_sources_parameter
        // sourceKeys={{dropboxAppKey: '1dsf42dl1i2', instagramClientId: 'd7aadf962m'}} // add source keys
        resourceType={'image'} // optionally set with 'auto', 'image', 'video' or 'raw' -> default = 'auto'
        cloudName={'swapalease'} // your cloudinary account cloud name.
        uploadPreset={'m1psotop'} // check that an upload preset exists and check mode is signed or unisgned
        buttonText={text} // default 'Upload Files'
        style={styles} // inline styling only or style id='cloudinary_upload_button'
        folder={'vehicle-images'} // set cloudinary folder name to send file
        cropping={false} // set ability to crop images -> default = true
        onSuccess={successCallBack} // add success callback -> returns result
        onFailure={failureCallBack} // add failure callback -> returns 'response.error' + 'response.result'
        logging={false} // logs will be provided for success and failure messages,
        customPublicId={'sample'} // set a specific custom public_id.
        eager={'w_400,h_300,c_pad|w_260,h_200,c_crop'} // add eager transformations -> deafult = null
        use_filename={false} // tell Cloudinary to use the original name of the uploaded
      />
    </>
  );
};

export default CloudinaryUploadWidget;
