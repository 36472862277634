export const SaveSearchIcon = (props: React.ComponentProps<'svg'>): JSX.Element => (
  <svg
    className={props.className}
    fill="none"
    height="24"
    viewBox="0 0 24 23"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.6506 8.73099V5.86457C21.6506 5.48446 21.4996 5.11992 21.2309 4.85114C20.9621 4.58236 20.5975 4.43136 20.2174 4.43136H8.75174V2.99815C8.75174 2.61804 8.60075 2.2535 8.33197 1.98472C8.06319 1.71594 7.69864 1.56494 7.31853 1.56494H3.0189C2.63879 1.56494 2.27425 1.71594 2.00547 1.98472C1.73669 2.2535 1.58569 2.61804 1.58569 2.99815V20.0056C1.59109 20.3958 1.73683 20.7711 1.99624 21.0627C2.25566 21.3544 2.61141 21.5428 2.99839 21.5936C3.38538 21.6444 3.77772 21.5542 4.10362 21.3394C4.42952 21.1247 4.6672 20.7997 4.77315 20.4241L7.02043 9.76672C7.10661 9.46816 7.2875 9.20569 7.53584 9.01888C7.78418 8.83206 8.08651 8.73103 8.39726 8.73099H21.6506ZM21.6506 8.73099C21.8703 8.73092 22.0871 8.78135 22.2842 8.87839C22.4813 8.97543 22.6534 9.11648 22.7873 9.29065C22.9213 9.46482 23.0133 9.66744 23.0565 9.88286C23.0996 10.0983 23.0926 10.3207 23.0361 10.533L20.934 20.5655C20.8527 20.8708 20.6728 21.1407 20.4222 21.3332C20.1717 21.5257 19.8646 21.63 19.5486 21.6299H3.20713"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      fill="none"
    />
    <path
      d="M6.28239 6.69486L8.35099 8.76346M1.18494 4.58327C1.18494 5.37516 1.49952 6.13463 2.05947 6.69458C2.61943 7.25454 3.37889 7.56912 4.17079 7.56912C4.96269 7.56912 5.72215 7.25454 6.28211 6.69458C6.84207 6.13463 7.15665 5.37516 7.15665 4.58327C7.15665 3.79137 6.84207 3.0319 6.28211 2.47195C5.72215 1.91199 4.96269 1.59741 4.17079 1.59741C3.37889 1.59741 2.61943 1.91199 2.05947 2.47195C1.49952 3.0319 1.18494 3.79137 1.18494 4.58327Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      transform="translate(9,10)"
    />
  </svg>
);
