// https://tailwindui.com/components/application-ui/forms/radio-groups

import { RadioGroup } from '@headlessui/react';
import { classNames } from 'src/utils/classNames';

type Option = {
  description?: string;
  id?: string;
  name?: string;
  price?: number;
  duration?: string;
  value?: string;
};

interface Props {
  options: Option[];
  onChange?: (arg: Option) => void;
  selectedOption: Option;
}

const RadioGroupList: React.FC<Props> = ({ options, onChange, selectedOption }: Props) => {
  const handleOnChange = (option: Option): void => {
    onChange && onChange(option);
  };

  return (
    <RadioGroup value={selectedOption} onChange={handleOnChange}>
      <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
      <div className="bg-white rounded-md -space-y-px">
        {options.map((option, optionIdx) => (
          <RadioGroup.Option
            key={option?.name}
            value={option}
            className={({ checked }) =>
              classNames(
                optionIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                optionIdx === options.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                checked ? 'bg-barely-blue border-lighter-blue z-10' : 'border-gray-200',
                'relative border p-4 flex cursor-pointer focus:outline-none'
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked ? 'bg-primary border-transparent' : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-primary' : '',
                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col text-left">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? 'text-primary' : 'text-gray-900',
                      'text-charcoal block text-sm font-bold'
                    )}
                  >
                    {option?.name}
                  </RadioGroup.Label>
                  <RadioGroup.Description as="span" className="block text-sm text-dark-blue">
                    <span className="font-bold">
                      {option?.price}{' '}
                      <span
                        className={classNames(
                          checked ? 'text-primary' : 'text-steel',
                          'font-medium'
                        )}
                      >
                        for {option?.duration}
                      </span>
                    </span>
                  </RadioGroup.Description>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(checked ? 'text-primary' : 'text-steel', 'block text-sm')}
                  >
                    {option?.description}
                  </RadioGroup.Description>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default RadioGroupList;
