import { RadioGroup } from '@headlessui/react';
import { classNames } from 'src/utils/classNames';
import utils from 'src/utils';

type Option = {
  description?: string;
  disabled?: boolean;
  isActive?: boolean;
  title?: string | number;
  subTitle?: string;
  price?: number;
  priceDescription?: string;
  notes?: string[];
};

interface Props {
  options: Option[];
  onChange?: (arg: Option) => void;
  selectedOption: Option;
}

const RadioGroupTable: React.FC<Props> = ({ options = [], onChange, selectedOption }: Props) => {
  const handleOnChange = (option: Option): void => {
    onChange && onChange(option);
  };

  return (
    <RadioGroup value={selectedOption} onChange={handleOnChange}>
      <RadioGroup.Label className="sr-only">Pricing plans</RadioGroup.Label>
      <div className="relative bg-white rounded-md -space-y-px">
        {options?.map((option, optionIdx) => (
          <RadioGroup.Option
            key={option?.title}
            value={option}
            disabled={option.disabled}
            className={({ checked }) =>
              classNames(
                optionIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                optionIdx === options.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                checked ? 'bg-barely-blue border-lighter-blue z-10' : 'border-gray-200',
                'relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none'
              )
            }
          >
            {({ active, disabled, checked }) => (
              <>
                <div className="flex items-center text-sm">
                  <span
                    className={classNames(
                      checked ? 'bg-primary border-transparent' : 'bg-white border-gray-300',
                      active ? 'ring-2 ring-offset-2 ring-primary' : '',
                      disabled ? 'bg-gray-300 border-gray-300' : '',
                      !option?.price ? 'opacity-0' : '',
                      'h-4 w-4 rounded-full border flex items-center justify-center'
                    )}
                    aria-hidden="true"
                  >
                    <span
                      className={classNames(
                        disabled ? 'bg-gray-300' : 'bg-white',
                        'rounded-full w-1.5 h-1.5'
                      )}
                    />
                  </span>
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      disabled ? 'opacity-40' : 'opacity-100',
                      'ml-3 font-bold text-dark-blue'
                    )}
                  >
                    {option?.title}
                  </RadioGroup.Label>
                </div>
                <RadioGroup.Description className="flex ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center items-center text-dark-blue">
                  {option?.isActive && option?.price && (
                    <span
                      className={classNames(disabled ? 'opacity-40' : 'opacity-100', 'font-bold')}
                    >
                      {utils.localizeCurrency(option?.price)}
                      <span
                        className={classNames(
                          checked ? 'text-primary' : 'text-steel',
                          'font-medium ml-1'
                        )}
                      >
                        {option?.description}
                      </span>
                    </span>
                  )}
                  {!option?.price && option?.description}
                </RadioGroup.Description>
                <RadioGroup.Description
                  className={classNames(
                    checked ? 'text-primary' : 'text-steel',
                    disabled ? 'opacity-40' : 'opacity-100',
                    'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right font-medium'
                  )}
                >
                  {`${option?.notes?.[0]}${option?.notes?.[0] ? ' • ' + option?.notes?.[1] : ''}`}
                </RadioGroup.Description>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default RadioGroupTable;
