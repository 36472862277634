import { AppLayout } from 'src/components';

const Loading: React.FC = () => {
  return (
    <AppLayout>
      <div className="bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 h-96">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src="/svg/sal-logo-text.svg" alt="Swapalease" />
          <h2 className="mt-6 text-center text-lg font-extrabold text-gray-900">Loading...</h2>
        </div>
      </div>
    </AppLayout>
  );
};

export default Loading;
