import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { classNames } from 'src/utils/classNames';

interface Option {
  name: string;
  description: string;
  href: string;
  icon?: React.ReactNode;
}

interface Props {
  options?: Option[];
  title?: string;
  children?: React.ReactNode;
}

const FlyoutMenu: React.FC<Props> = ({ children, options = [], title }: Props) => (
  <Popover className="relative">
    {({ open }) => (
      <>
        <Popover.Button
          className={classNames(
            open ? 'text-gray-900' : 'text-gray-500',
            'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none'
          )}
        >
          <span className="relative text-sm text-charcoal font-bold hover:underline">{title}</span>
          <ChevronDownIcon
            className="text-primary ml-2 h-5 w-5 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                {options.map(option => (
                  <a
                    key={option?.name}
                    href={option?.href}
                    className="-m-3 p-3 block rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                  >
                    <div className="flex">
                      {option?.icon ? option?.icon : null}
                      <div className="flex flex-col">
                        <p className="text-base font-bold text-gray-900">{option?.name}</p>
                        <p className="mt-1 text-sm text-gray-500">{option?.description}</p>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
              {children}
            </div>
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
);

export default FlyoutMenu;
