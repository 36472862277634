const VEHICLE_STATUS = {
  'INCOMPLETE': 0,
  'INACTIVE': 1,
  'ACTIVE': 2,
  'SOLD': 3,
  'PENDING': 4
};


export default VEHICLE_STATUS;
