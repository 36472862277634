import { PhoneIcon } from '@heroicons/react/outline';

interface Props {
  bgColor?: string;
  borderColor?: string;
}

const NeedHelpFooter: React.FC<Props> = ({
  bgColor = 'bg-platinum',
  borderColor = 'border-silver',
}: Props) => (
  <div className={`text-center pt-6 pb-32 border-t-2 ${borderColor} ${bgColor}`}>
    <h4 className="text-steel">
      <span className="font-bold">Need help?</span> Speak with a lease specialist.
    </h4>
    <div className="flex justify-center items-center mt-2">
      <PhoneIcon className="h-6 w-6 mr-2 text-primary" aria-hidden="true" />
      <h4 className="text-primary font-extrabold">1-877-893-1588</h4>
    </div>
  </div>
);

export default NeedHelpFooter;
