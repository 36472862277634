import useSWR from 'swr';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { Avatar, Divider, Modal } from 'src/components/ui';
import { BillingForm } from 'src/components';
import { useBilling, updateBilling } from 'src/providers/BillingProvider';
import { useSession } from 'next-auth/client';
import { SwitchHorizontalIcon } from '@heroicons/react/outline';
import { getCardCode, getCardImage } from 'src/utils/creditCardUtils';
import { getFormattedDateText } from 'src/utils/dateUtils';
import { useNotificationDispatch, updateNotification } from 'src/providers/NotificationProvider';
import { Message } from 'src/types';
import { useRouter } from 'next/router';
import { PackageData } from 'src/types/Package';
import VEHICLE_STATUS from 'src/config/vehicleStatus';

interface Props {
  isOpen: boolean;
  message?: Message;
  onClose: () => void;
}

const AcceptOfferModal: React.FC<Props> = ({ isOpen = false, onClose, message }: Props) => {
  const [state, dispatch] = useBilling();
  const router = useRouter();
  const notifyDispatch = useNotificationDispatch();
  const [session] = useSession();
  const userEmail = session?.user?.email;
  const buyer = message?.users?.find(user => user.isBuyer);

  // Modal state
  const [cardInfoinitialized, setCardInfoinitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editCardMode, setEditCardMode] = useState(false);

  // Get vehicle data
  const { data } = useSWR(`/api/getVehicleById?vehicleId=${message?.vehicleId}`);
  const vehicle = data?.vehicle;

  // Get buyer packages to build options
  const { data: sellerPackages } = useSWR('/api/packages/getPackages?type=seller');
  const sellerPackage = sellerPackages?.data?.find(
    (pkg: PackageData) => pkg.packageRef === vehicle?.listingPackageId
  );

  // AuthNet payment profile
  const { data: paymentProfileData } = useSWR(`/api/billing/getPaymentProfile?email=${userEmail}`);

  // Billing transation data
  const { data: transactionData } = useSWR(`/api/billing/getTransactionsByUser?email=${userEmail}`);
  const currentTransaction = transactionData?.data?.latestTransaction;

  // Load billing data into billing state
  useEffect(() => {
    if (paymentProfileData) {
      const billingModel = {
        address: paymentProfileData?.data?.paymentProfile?.billTo?.address,
        address2: paymentProfileData?.data?.paymentProfile?.billTo?.address2,
        cardNumber: paymentProfileData?.data?.paymentProfile?.payment?.creditCard?.cardNumber,
        cardType: paymentProfileData?.data?.paymentProfile?.payment?.creditCard?.cardType,
        city: paymentProfileData?.data?.paymentProfile?.billTo?.city,
        cvc: '',
        month: '',
        name:
          session?.user?.name ||
          `${paymentProfileData?.data?.firstName} ${paymentProfileData?.data?.lastName}`,
        state: paymentProfileData?.data?.paymentProfile?.billTo?.state,
        year: '',
        zip: paymentProfileData?.data?.paymentProfile?.billTo?.zip,
      };
      setCardInfoinitialized(true);
      dispatch(updateBilling(billingModel));
    }
  }, [paymentProfileData]);

  const handleOnSubmit = async (): Promise<void> => {
    setIsLoading(true);
    // Handle updating AuthNet payment profile with new credit card info
    if (editCardMode) {
      const res = await fetch('/api/billing/updatePaymentProfile', {
        body: JSON.stringify({
          userInfo: state,
          email: userEmail,
        }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });

      const { error } = await res.json();
      if (error) {
        setIsLoading(false);
        notifyDispatch(
          updateNotification({
            title: 'Update Error',
            icon: 'error',
            show: true,
            description: 'There was an issue updating your payment details.',
          })
        );
        return console.error(error);
      }

      notifyDispatch(
        updateNotification({
          autoHide: true,
          title: 'Update Successful',
          icon: 'success',
          show: true,
          description: 'Your payment details were updated successfully!',
        })
      );

      setIsLoading(false);
      setEditCardMode(false);
      return;
    }

    // Seller package requires success fee - handle success fee payment
    if (sellerPackage?.successFee) {
      const res = await fetch('/api/billing/submitPayment', {
        body: JSON.stringify({
          userInfo: {
            ...state,
            email: userEmail,
            selectedPlan: {
              name: 'Success Fee',
              price: sellerPackage?.successFee,
            },
          },
        }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });

      const { data, error } = await res.json();
      if (error) {
        setIsLoading(false);
        notifyDispatch(
          updateNotification({
            title: 'Transaction Error',
            icon: 'error',
            show: true,
            description:
              'There was an issue with your transaction. Please go back and check your payment information',
          })
        );
        console.error(error);
        return;
      }

      notifyDispatch(
        updateNotification({
          autoHide: true,
          title: 'Transaction Successful',
          icon: 'success',
          show: true,
          description: 'Your transaction was successful!',
        })
      );

      // Update billing state with transaction info
      dispatch(
        updateBilling({
          cardType: data?.transactionResponse?.accountType,
          transactionId: data?.transactionResponse?.transId,
          vehicleDescription: message?.title,
          vehicleId: message?.vehicleId,
          selectedPlan: {
            title: 'Success Fee',
            price: sellerPackage?.successFee,
          },
          email: userEmail,
        })
      );

      // Update the vehicle's listing status to pending
      fetch('/api/updateVehicle', {
        body: JSON.stringify({
          vehicleId: `${message?.vehicleId || ''}`,
          payload: {
            vehicleStatusId: VEHICLE_STATUS.PENDING,
          },
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });

      setIsLoading(false);
      router.push('/my-account/messages/confirmation');
      return;
    }

    // Vehicle does not require payment.
    // Update the vehicle's listing status to pending and move on
    fetch('/api/updateVehicle', {
      body: JSON.stringify({
        vehicleId: `${message?.vehicleId || ''}`,
        payload: {
          vehicleStatusId: VEHICLE_STATUS.PENDING,
        },
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    setIsLoading(false);
    onClose && onClose();
  };

  const vehicleSection = (
    <div className="flex shadow w-full mb-5 px-4 py-2 rounded-lg">
      <div className="w-24 h-24 rounded-lg bg-red-900 overflow-hidden">
        <Image
          alt="vehicle-image"
          height={96}
          layout="fixed"
          src={vehicle?.imageUrl || '/svg/sal-logo-mark.svg'}
          width={96}
        />
      </div>
      <div className="flex flex-col justify-center items-start ml-4">
        <div className="font-bold text-charcoal">{message?.title}</div>
        <div className="flex items-center font-bold text-charcoal mt-1">
          <Avatar size="sm" />
          <div className="ml-2 text-sm font-medium text-steel">{buyer?.name}</div>
        </div>
        <div className="text-sm font-medium text-slate mt-2">to take over this lease.</div>
      </div>
    </div>
  );

  const successFeeSection = (
    <div className="w-full flex border-2 border-platinum justify-between items-start rounded-lg px-4 py-4">
      <div className="font-bold text-charcoal">Success Fee</div>
      <div className="font-bold text-primary mml-auto">{`$${sellerPackage?.successFee}`}</div>
    </div>
  );

  const paymentMethodSection = (
    <div className="w-full">
      <div className="pb-3 w-full text-sm font-bold text-left">
        Paying&nbsp;<span className="text-primary">{`$${sellerPackage?.successFee}`}</span>
        &nbsp;with:
      </div>
      <div className="flex flex-col sm:flex-row bg-frost mb-6 px-6 py-6 rounded-lg justify-between w-full">
        {!cardInfoinitialized ? (
          <div className="flex justify-center items-center h-12 w-12 flex-shrink-0 mt-1">
            <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-primary"></div>
          </div>
        ) : (
          <div className="flex">
            <div className="p-1">
              <Image
                alt="credit-card"
                layout="fixed"
                src={getCardImage(
                  paymentProfileData?.data?.paymentProfile?.payment?.creditCard?.cardType
                )}
                height={32}
                width={48}
              />
            </div>
            <div className="flex flex-col items-start pl-2">
              <h3 className="font-bold text-charcoal">{`Ending with ${getCardCode(
                paymentProfileData?.data?.paymentProfile?.payment?.creditCard?.cardNumber
              )}`}</h3>
              <h2 className="text-sm font-medium text-slate mt-1">
                {`Last updated on ${getFormattedDateText(currentTransaction?.submitTimeLocal)}`}
              </h2>
            </div>
          </div>
        )}

        <a
          href="#"
          onClick={() => {
            dispatch(updateBilling({ cardNumber: null }));
            setEditCardMode(true);
          }}
          className="flex items-center text-base font-extrabold text-primary hover:text-sky-blue justify-center sm:justify-start mt-4 sm:mt-0"
        >
          Edit
        </a>
      </div>
    </div>
  );

  const renderModalContent = (): React.ReactNode => {
    if (editCardMode) {
      return <BillingForm state={state} onUpdate={payload => dispatch(updateBilling(payload))} />;
    }

    return (
      <>
        <div className="text-steel mb-4">
          {`${buyer?.name || 'Buyer'} has offered to take over your lease.`}
        </div>
        <div className="flex justify-center flex-wrap">
          {vehicleSection}
          {sellerPackage?.successFee && (
            <>
              {successFeeSection}
              <Divider styles="my-5 w-full" />
              {paymentMethodSection}
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(
          updateBilling({
            cardNumber: paymentProfileData?.data?.paymentProfile?.payment?.creditCard?.cardNumber,
          })
        );
        onClose && onClose();
        setEditCardMode(false);
      }}
      showButtons={true}
      onSubmit={() => handleOnSubmit()}
      submitButtonTitle={isLoading ? 'Loading...' : editCardMode ? 'Save' : 'Accept Offer'}
      isSubmitDisabled={isLoading}
      closeButtonTitle="Cancel"
      size="md"
      title={
        <div className="flex items-center justify-center font-bold text-charcoal text-xl">
          <SwitchHorizontalIcon className="h-6 w-6 mr-1 text-primary" />
          <div>{editCardMode ? 'Edit Payment' : 'Accept Offer'}</div>
        </div>
      }
    >
      {renderModalContent()}
    </Modal>
  );
};

export default AcceptOfferModal;
