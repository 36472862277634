import { addMonths, format, differenceInCalendarMonths } from 'date-fns';

function getDate(faunaDate: any, formatString?: string): string | undefined {
  if (!faunaDate) return undefined;

  const date = new Date(faunaDate?.['@ts']);
  if (!date) return undefined;

  const year = format(date, 'yyyy');
  if (year === '1898') return undefined;

  return format(date, formatString ? formatString : 'MM/dd/yyyy');
}

function getFormattedDateText(dateString: string): string {
  const date = dateString ? new Date(dateString) : new Date();
  const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];
  return `${month}/${day}/${year}`;
}

function getMonthsLeft(faunaDate: any): number | undefined {
  const date = new Date(faunaDate?.['@ts']);
  if (!date) return undefined;

  const year = format(date, 'yyyy');
  if (year === '1898') return undefined;

  const today = new Date();

  const monthsLeft = differenceInCalendarMonths(date, today);
  return monthsLeft;
}

function getFutureDateByMonths(months: number | string): Date | undefined {
  const today = new Date();
  if (!months) return today;

  const futureDate = addMonths(today, months as number);
  return futureDate;
}


export { getDate, getFormattedDateText, getFutureDateByMonths, getMonthsLeft };
