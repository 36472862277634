import algoliasearch from 'algoliasearch/lite';
import { Button } from 'src/components/ui';
import { Autocomplete } from 'src/components';
import { useRouter } from 'next/router';
import { SearchIcon } from '@heroicons/react/outline';
import { InstantSearch, Configure } from 'react-instantsearch-dom';

const Hero: React.FC = () => {
  const router = useRouter();

  const searchClient = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_ONLY_API_KEY
  );

  const onSuggestionSelected = (suggestion: string): void => {
    router.push(`/search?query=${suggestion}`);
  };

  return (
    <div className="relative border-primary border-b-8">
      <div className="absolute inset-x-0 bottom-0 bg-gray-100" />

      <div className="relative shadow-xl sm:overflow-hidden h-full">
        <div className="absolute inset-0 overflow-hidden">
          <img
            className="h-full w-full object-cover hidden sm:block"
            src="hero-image.png"
            alt="find-your-next-lease"
          />
          <img
            className="h-full w-full object-cover object-center -mt-32 sm:hidden"
            src="hero-image-mobile.png"
            alt="find-your-next-lease"
          />
        </div>

        {/*<div className="flex flex-col max-w-6xl mx-auto">*/}
        <div className="flex flex-col max-w-6xl mx-auto">
          <div className=" flex flex-col justify-start w-full md:w-4/6 sm:items-start relative py-4 md:py-16 px-4 md:px-0 md:ml-6 sm:py-24">
            <div className="text-4xl font-extrabold tracking-tight mx-4 sm:mx-1 sm:text-5xl lg:text-6xl w-56 sm:w-full">
              <span className="block text-white">Find your next lease.</span>
            </div>

            <div className="w-full max-w-3xl mt-6 mb-4 md:mb-0 md:pr-8">
              <div className="flex bg-white items-center rounded-full w-full py-1 px-2 mx-auto shadow-input">
                <SearchIcon className="h-8 w-8 text-primary" aria-hidden="true" />
                <InstantSearch indexName="dev_CARS" searchClient={searchClient}>
                  <Configure hitsPerPage={15} />
                  <Autocomplete onSuggestionSelected={onSuggestionSelected} />
                </InstantSearch>
              </div>
            </div>
          </div>

          <div className="relative w-full md:w-4/6 md:ml-6 px-8 py-6 mt-64 sm:mt-0 rounded-t-3xl bg-primary">
            <span className="block text-white text-xl sm:text-4xl">
              <strong>swapalease</strong> gives you options.
            </span>
            <p className="block text-white my-4">
              Looking to free yourself from your binding lease agreement? Let one of the thousands
              of buyers on Swapalease assume your lease.
            </p>
            <div className="hidden lg:block">
              <Button href="/create" size="md">
                List My Lease
              </Button>
            </div>
            <div className="sm:block mt-2 lg:hidden">
              <Button href="/create" fullWidth size="md">
                List My Lease
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
