import Link from 'next/link';

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
  href?: string;
  icon?: JSX.Element;
  iconLeft?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  scroll?: boolean;
  size: 'sm' | 'md';
  styles?: string;
}

const TextLink: React.FC<Props> = ({
  children,
  disabled = false,
  href = '',
  icon = null,
  iconLeft = true,
  onClick = null,
  scroll = false,
  size,
  styles = '',
}: Props) => {
  const disabledClasses = disabled
    ? 'cursor-auto opacity-80 text-sky-blue'
    : 'text-primary hover:underline';
  const textSizeClasses = size === 'sm' ? 'text-sm' : 'text-md';

  return (
    <Link href={href} scroll={scroll}>
      <a
        className={`font-extrabold relative ${
          icon && !iconLeft ? 'pr-6' : ''
        } ${disabledClasses} ${textSizeClasses} ${styles}`}
        onClick={event => {
          if (onClick) onClick(event);
        }}
      >
        {icon && iconLeft && <div className="absolute h-6 w-6">{icon}</div>}
        {children}
        {icon && !iconLeft && (
          <div className="absolute h-6 right-0 transform top-1/2 -translate-y-1/2 w-6">{icon}</div>
        )}
      </a>
    </Link>
  );
};

export default TextLink;
