import Image from 'next/image';

interface Props {
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  imageUrl: string;
}

const HeaderWithImage: React.FC<Props> = ({ description, imageUrl, title }: Props) => {
  return (
    <div className="relative primary overflow-hidden">
      <div className="absolute inset-0">
        <Image
          className="w-full h-full object-cover"
          alt="dealer-program-image"
          height={589}
          layout="fill"
          src={imageUrl}
          width={1440}
        />
        <div className="absolute inset-0 bg-primary mix-blend-multiply" aria-hidden="true" />
      </div>
      <div className="relative max-w-7xl mx-auto px-4 py-24 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
          {title}
        </h1>
        <div className="mt-6 text-xl text-blue-100 max-w-3xl">{description}</div>
      </div>
    </div>
  );
};

export default HeaderWithImage;
