import Image from 'next/image';
import { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

interface Props {
  images: string[];
  activeIndex: number;
  onClose: () => void;
  title: string;
}

const ImageCarousel: React.FC<Props> = ({
  images = [],
  activeIndex = 0,
  onClose,
  title,
}: Props) => {
  const [activeImageIndex, setActiveImageIndex] = useState(activeIndex);

  const handlePrev = (): void => {
    if (!activeImageIndex || activeImageIndex < 0) return setActiveImageIndex(images.length - 1);
    setActiveImageIndex(activeImageIndex - 1);
  };

  const handleNext = (): void => {
    if (activeImageIndex >= images.length - 1) return setActiveImageIndex(0);
    setActiveImageIndex(activeImageIndex + 1);
  };

  const closeButton = (
    <div className="absolute top-0 right-0 pt-4 pr-4" onClick={() => onClose()}>
      <button
        type="button"
        className="md:bg-white rounded-md text-white md:text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 fill-primary stroke-primary"
      >
        <span className="sr-only">Close</span>
        <svg
          className="h-12 w-12 md:h-6 md:w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path stroke="currentColor" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  );

  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          onClick={() => onClose()}
        ></div>

        <div className="flex flex-col md:flex-row fixed z-10 inset-0 md:inset-16 bg-black">
          {/* Next and Prev buttons*/}
          <div className="absolute w-full md:w-3/4 h-full">
            <div className="relative w-full h-full">
              <button
                type="button"
                className="absolute bottom-0 mb-16 md:mb-0 md:top-1/2 left-1/4 md:left-0 bg-silver w-10 h-10 ml-4 rounded-full text-black  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => handlePrev()}
              >
                <ChevronLeftIcon className="h-8 w-8 ml-1" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="absolute bottom-0 mb-16 md:mb-0 md:top-1/2 right-1/4 md:right-0 bg-silver w-10 h-10 mr-4 rounded-full text-black  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => handleNext()}
              >
                <ChevronRightIcon className="h-8 w-8 ml-1" aria-hidden="true" />
              </button>
            </div>
          </div>

          <div className="w-full md:w-3/4 h-full overflow-hidden">
            {closeButton}
            <div className="flex flex-col justify-center items-center content-center h-full md:p-16">
              <div className="h-auto w-full md:w-11/12 bg-silver">
                <Image
                  src={images[activeImageIndex]}
                  alt="listing-image"
                  height={525}
                  width={800}
                  layout="responsive"
                />
              </div>
            </div>
          </div>
          <div className="hidden md:block md:w-1/4 h-full overflow-scroll bg-white">
            <div className="flex justify-start items-center font-bold h-16 pl-2 w-auto mr-12 text-black">
              {title}
            </div>
            {closeButton}
            <div className="flex flex-wrap mt-4">
              {images.map((image, i) => (
                <div
                  className={i === activeImageIndex ? 'w-1/2 border-4 border-primary' : 'w-1/2 p-1'}
                  key={i}
                  onClick={() => setActiveImageIndex(i)}
                >
                  <Image
                    src={image}
                    alt="listing-image"
                    height={75}
                    width={100}
                    layout="responsive"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
