import { SearchFilters } from 'src/types';
import { Divider, CurrencyInput, FilterRadioGroup, FilterSelect, Input } from 'src/components/ui';
import { FilterDisclosureArea } from 'src/components';
import { buildSearchOptions, getAllFilters } from 'src/utils/search';
import {
  leaseTypeOptions,
  locationDistanceOptions,
  makeOptions,
  monthsRemainingOptions,
  yearOptions,
} from 'src/config/inputOptions';
import vehicleCategories from 'src/config/vehicleCategories';
import { Action } from 'src/providers/SearchProvider';

interface Props {
  children?: React.ReactNode;
  closeButton?: React.ReactNode;
  dispatch: React.Dispatch<Action>;
  filters: SearchFilters;
  models: string[];
  removeFilter: (key: string) => Action;
  resetFilters: () => Action;
  topSection?: React.ReactNode;
  updateSearchFilters: (filter: SearchFilters) => Action;
}

const SearchFilterMenu: React.FC<Props> = ({
  children,
  closeButton,
  dispatch,
  filters,
  models,
  removeFilter,
  resetFilters,
  topSection,
  updateSearchFilters,
}: Props) => {
  const hasMinLengthZipcode = filters?.zipcode?.length > 4;
  const formattedMonthsRemainingOptions = buildSearchOptions(
    monthsRemainingOptions.map((range, index) => {
      if (monthsRemainingOptions?.length - 1 === index) return `${range[0]} + months`;
      return `${range[0]} - ${range[1]} months`;
    }),
    filters?.monthsRemainingFilters
  );
  const locationFilters = hasMinLengthZipcode
    ? [
        filters?.zipcode,
        ...filters?.locationFilters.map((filter: string) => `${filter} miles`),
      ].filter(Boolean)
    : [];
  const paymentFilters = [
    filters?.minMonthlyPayment && `$${filters?.minMonthlyPayment} min`,
    filters?.maxMonthlyPayment && `$${filters?.maxMonthlyPayment} max`,
  ].filter(Boolean);
  const allFilters = getAllFilters(filters);

  const monthlyPaymentInputs = (
    <div className="bg-frost px-2 mt-2 border border-platinum max-h-52 overflow-y-auto shadow-inner">
      <CurrencyInput
        numbersOnly
        onChange={e => {
          const value = e?.target?.value && parseInt(e?.target?.value);
          dispatch(updateSearchFilters({ minMonthlyPayment: value }));
        }}
        placeholder="Min"
        styles="mt-3"
        title="Min"
        value={filters?.minMonthlyPayment}
      />
      <CurrencyInput
        numbersOnly
        onChange={e => {
          const value = e?.target?.value && parseInt(e?.target?.value);
          dispatch(updateSearchFilters({ maxMonthlyPayment: value }));
        }}
        placeholder="Max"
        styles="my-3"
        title="Max"
        value={filters?.maxMonthlyPayment}
      />
    </div>
  );

  return (
    <>
      <div className="flex justify-start md:justify-between my-5">
        <h4 className="hidden md:block text-xl text-slate pt-1">Filters</h4>
        <h4 className="block md:hidden text-xl text-charcoal font-bold pl-2 md:pl-0">{`Filters (${allFilters.length})`}</h4>
        <div
          className="text-sm text-primary font-bold md:font-extrabold mt-1.5 md:mt-2 mr-16 md:mr-0 ml-3 md:ml-0 cursor-pointer"
          onClick={() => dispatch(resetFilters())}
        >
          Clear All
        </div>
        {closeButton}
      </div>
      <Divider borderStyles="block md:hidden border-light-blue" />
      {topSection}
      <Divider borderStyles="border-light-blue" />
      <FilterDisclosureArea
        title="Make"
        filters={filters?.makeFilters}
        onBadgeClick={filter => dispatch(removeFilter(filter))}
      >
        <FilterSelect
          options={buildSearchOptions(makeOptions, filters?.makeFilters)}
          onChange={filters => dispatch(updateSearchFilters({ makeFilters: filters }))}
          styles="mt-2"
          values={filters.makeFilters}
        />
      </FilterDisclosureArea>
      <Divider borderStyles="border-light-blue" />
      <FilterDisclosureArea
        title="Model"
        filters={filters?.modelFilters}
        onBadgeClick={filter => dispatch(removeFilter(filter))}
      >
        {filters?.makeFilters?.length ? (
          <FilterSelect
            options={buildSearchOptions([...new Set(models)] as string[], filters?.modelFilters)}
            onChange={filters => dispatch(updateSearchFilters({ modelFilters: filters }))}
            styles="mt-2"
            values={filters?.modelFilters}
          />
        ) : (
          <h4 className="pl-2 pb-4 text-sm font-bold text-slate">Please select a make.</h4>
        )}
      </FilterDisclosureArea>
      <Divider borderStyles="border-light-blue" />
      <FilterDisclosureArea
        title="Year"
        filters={filters?.yearFilters}
        onBadgeClick={filter => dispatch(removeFilter(filter))}
      >
        <FilterSelect
          options={buildSearchOptions(yearOptions, filters?.yearFilters)}
          onChange={filters => dispatch(updateSearchFilters({ yearFilters: filters }))}
          styles="mt-2"
          values={filters?.yearFilters}
        />
      </FilterDisclosureArea>
      <Divider borderStyles="border-light-blue" />
      <FilterDisclosureArea
        title="Location"
        filters={locationFilters}
        onBadgeClick={filter => dispatch(removeFilter(filter))}
      >
        <Input
          onChange={e => dispatch(updateSearchFilters({ zipcode: e?.target?.value }))}
          placeholder="Enter ZIP"
          styles="mb-4 mx-1"
          value={filters?.zipcode}
        />
        {hasMinLengthZipcode && (
          <FilterRadioGroup
            options={buildSearchOptions(locationDistanceOptions, filters?.locationFilters, 'miles')}
            onChange={filters => dispatch(updateSearchFilters({ locationFilters: filters }))}
            values={filters?.locationFilters}
          />
        )}
      </FilterDisclosureArea>
      <Divider borderStyles="border-light-blue" />
      <FilterDisclosureArea
        title="Monthly Payment"
        filters={paymentFilters}
        onBadgeClick={filter => dispatch(removeFilter(filter))}
      >
        {monthlyPaymentInputs}
      </FilterDisclosureArea>
      <Divider borderStyles="border-light-blue" />
      <FilterDisclosureArea
        title="Months Remaining"
        filters={filters?.monthsRemainingFilters}
        onBadgeClick={filter => dispatch(removeFilter(filter))}
      >
        <FilterSelect
          options={formattedMonthsRemainingOptions}
          onChange={filters => dispatch(updateSearchFilters({ monthsRemainingFilters: filters }))}
          styles="mt-2"
          values={filters?.monthsRemainingFilters}
        />
      </FilterDisclosureArea>
      <Divider borderStyles="border-light-blue" />
      <FilterDisclosureArea
        title="Body Style"
        filters={filters?.bodyStyleFilters}
        onBadgeClick={filter => dispatch(removeFilter(filter))}
      >
        <FilterSelect
          options={buildSearchOptions(
            vehicleCategories.map(style => style?.categoryCode).sort(),
            filters?.bodyStyleFilters
          )}
          onChange={filters => dispatch(updateSearchFilters({ bodyStyleFilters: filters }))}
          styles="mt-2"
          values={filters?.bodyStyleFilters}
        />
      </FilterDisclosureArea>
      <Divider borderStyles="border-light-blue" />
      <FilterDisclosureArea
        title="Lease Type"
        filters={filters?.leaseTypeFilters}
        onBadgeClick={filter => dispatch(removeFilter(filter))}
      >
        <FilterSelect
          options={buildSearchOptions(leaseTypeOptions, filters?.leaseTypeFilters)}
          onChange={filters => dispatch(updateSearchFilters({ leaseTypeFilters: filters }))}
          styles="mt-2"
          values={filters?.leaseTypeFilters}
        />
      </FilterDisclosureArea>
      <Divider borderStyles="border-light-blue" />
      {children}
    </>
  );
};

export default SearchFilterMenu;
