import React, { useEffect, useState } from 'react';
import { Highlight, connectAutoComplete } from 'react-instantsearch-dom';
import AutoSuggest from 'react-autosuggest';
import { Vehicle } from 'src/types';

interface Props {
  currentRefinement: string;
  hits: Vehicle[];
  initialValue: string;
  placeholderText?: string;
  onSuggestionCleared: () => void;
  onSuggestionSelected: (value: string) => void;
  refine: (value: string) => void;
}

const AutoComplete: React.FC<Props> = ({
  hits,
  initialValue = '',
  onSuggestionCleared,
  onSuggestionSelected,
  refine,
  placeholderText = 'Search makes, models, or keywords...',
}: Props) => {
  const [value, setValue] = useState(initialValue);
  const [suggestedTerms, setSuggestedTerms] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);

  // Remove duplicate search terms
  useEffect(() => {
    setSuggestedTerms(getAutocompleteTerms(hits));
  }, [hits]);

  const getAutocompleteTerms = (hits: Vehicle[]): Vehicle[] => {
    const uniqueTerms = [];
    hits.forEach((hit: Vehicle) => {
      const isDupeMake = uniqueTerms.find(t => t.make === hit.make && t.model === hit.model);
      if (!isDupeMake) uniqueTerms.push(hit);
    });
    return uniqueTerms;
  };

  const onChange = (_: void, { newValue }): void => {
    if (!newValue && onSuggestionCleared) onSuggestionCleared();
    setIsInitialized(false);
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value: hitValue }): void => {
    refine(hitValue);
  };

  const onSuggestionsClearRequested = (): void => {
    refine('');
  };

  const handleOnSuggestionSelected = (_: void, { suggestion }): void => {
    onSuggestionSelected(`${suggestion.make} ${suggestion.model}`);
  };

  const getSuggestionValue = (hit: Vehicle): string => {
    if (!hit.model) return hit.make;
    return `${hit.make} ${hit.model}`;
  };

  const renderSuggestion = (hit: Vehicle): React.ReactNode => {
    const hitName = `${hit?.make} ${hit?.model}`;
    const bgStyles = hitName === value ? 'bg-lighter-blue' : '';
    return (
      <div
        className={`cursor-pointer hover:border-light-blue hover:bg-lighter-blue bg-white px-2 py-1 w-48 sm:w-64 md:w-96 ${bgStyles}`}
      >
        <Highlight attribute="make" hit={hit} tagName="mark" />
        &nbsp;
        <Highlight attribute="model" hit={hit} tagName="mark" />
      </div>
    );
  };

  const inputProps = {
    placeholder: placeholderText,
    onChange: onChange,
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
      // Submit search on enter key down
      if (e.key === 'Enter' && onSuggestionSelected) {
        onSuggestionSelected(e?.currentTarget?.value);
        setIsInitialized(true);
        e?.currentTarget?.blur();
      }
    },
    value,
  };

  return (
    <>
      <div className="relative w-full px-2 mx-2 bg-primary">
        <div className="absolute -top-5 left-0 w-full z-30">
          <AutoSuggest
            id="search-autocomplete"
            class="search2-autocomplete"
            theme={{
              input: {
                width: '100%',
                border: 'none',
                borderRadius: '30px',
                backgroundColor: 'white',
                outlineColor: 'white',
                outline: 'none',
                boxShadow: 'none',
              },
              inputFocused: {
                border: 'none',
                outline: 'none',
                boxShadow: 'none',
              },
            }}
            suggestions={suggestedTerms}
            onSuggestionsFetchRequested={({ value }) => {
              refine(value);
              onSuggestionsFetchRequested({ value });
            }}
            focusInputOnSuggestionClick={false}
            shouldRenderSuggestions={() => !isInitialized && value.trim().length >= 1}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={handleOnSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
          />
        </div>
      </div>
      <button
        className="hidden md:block my-0.5 bg-primary font-extrabold text-md text-white rounded-full border-2 hover:border-light-blue border-primary px-4 py-1.5"
        onClick={() => onSuggestionSelected(value)}
      >
        Search
      </button>
    </>
  );
};

export default connectAutoComplete(AutoComplete);
