import { Divider } from 'src/components/ui';

const LogoCloud: React.FC = () => (
  <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
    <Divider title="The #1 car lease transfer marketplace" />
    <div className="mt-6 grid grid-cols-1 gap-8 md:grid-cols-3">
      <div className="col-span-1 flex justify-center">
        <img className="h-12" src="/svg/The_New_York_Times_logo.svg" alt="The New York Times" />
      </div>
      <div className="col-span-1 flex justify-center">
        <img className="h-12" src="/svg/WSJ_Logo.svg" alt="Wall Street Journal" />
      </div>
      <div className="col-span-1 flex justify-center">
        <img className="h-12" src="/svg/Chicago_Tribune_Logo.svg" alt="Chicago Tribune" />
      </div>
    </div>
    <div className="pt-8">
      <Divider />
    </div>
  </div>
);

export default LogoCloud;
