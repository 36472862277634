import { Button } from 'src/components/ui';

const ArticleCTA: React.FC = () => {
  return (
    <div className="bg-primary w-full">
      <div className="text-center py-8 px-4">
        <h2 className="text-2xl font-extrabold text-white">
          <span className="block">Now it&apos;s your turn!</span>
          <span className="block">Call us now, email us, or click here:</span>
        </h2>
        <Button size="lg" styles="w-full inline-flex items-center justify-center py-8">
          List Your Vehicle
        </Button>
      </div>
    </div>
  );
};

export default ArticleCTA;
