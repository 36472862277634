// Used for custom components only
import '../styles/globals.css';
import type { ReactElement, ReactNode } from 'react';
import type { AppProps /*, AppContext */ } from 'next/app';
import type { NextPage } from 'next';
import { Provider } from 'next-auth/client';
import CreateListingProvider from 'src/providers/CreateListingProvider';
import NotificationProvider from 'src/providers/NotificationProvider';
import BillingProvider from 'src/providers/BillingProvider';
import { useEffect } from 'react';
import { useSession, signIn } from 'next-auth/client';
import { Loading } from 'src/components';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout & { auth: boolean };
};

function MyApp({ Component, pageProps }: AppPropsWithLayout): React.ReactNode {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? (page => page);

  // TODO: Move these providers to appropriate pages using per-page layouts with 'getLayout'
  // https://nextjs.org/docs/basic-features/layouts#single-shared-layout-with-custom-app
  return getLayout(
    <Provider session={pageProps.session}>
      <CreateListingProvider>
        <NotificationProvider>
          <BillingProvider>
            {Component.auth ? (
              <Auth>
                <Component {...pageProps} />
              </Auth>
            ) : (
              <Component {...pageProps} />
            )}
          </BillingProvider>
        </NotificationProvider>
      </CreateListingProvider>
    </Provider>
  );
}

function Auth({ children }): React.ReactElement {
  const [session, loading] = useSession();
  const isUser = !!session?.user;
  useEffect(() => {
    if (loading) return; // Do nothing while loading
    if (!isUser) signIn(); // If not authenticated, force log in
  }, [isUser, loading]);

  if (isUser) {
    return children;
  }

  // Session is being fetched, or no user.
  // If no user, useEffect() will redirect.
  return <Loading />;
}

export default MyApp;
