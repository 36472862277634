import Image from 'next/image';

interface Props {
  image?: string;
  styles?: string;
  size?: 'sm' | 'md' | 'lg';
}

const Avatar: React.FC<Props> = ({ image, size = 'md', styles = '' }: Props) => {
  const imageSize = size === 'sm' ? 24 : size === 'md' ? 32 : 48;
  const sizeStyles = size === 'sm' ? 'h-6 w-6' : size === 'md' ? 'h-8 w-8' : 'h-12 w-12';

  return (
    <div
      className={`rounded-full overflow-hidden text-slate bg-gray-100 flex-shrink-0 ${sizeStyles} ${styles}`}
    >
      {image ? (
        <Image
          src={image}
          alt="Profile Image"
          height={imageSize}
          width={imageSize}
          layout="responsive"
        />
      ) : (
        <svg fill="currentColor" viewBox="0 0 24 24">
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0 1 12.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 1 1-8 0 4 4 0 0 1 8 0z" />
        </svg>
      )}
    </div>
  );
};

export default Avatar;
