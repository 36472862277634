import Image from 'next/image';
import Link from 'next/link';
import { Divider, NavLink } from 'src/components/ui';
import { Fragment } from 'react';
import { signIn, signOut, useSession } from 'next-auth/client';

import { Menu, Transition } from '@headlessui/react';
import utils from 'src/utils';
import { useNotificationDispatch, updateNotification } from 'src/providers/NotificationProvider';

const AdminNavigation: React.FC = () => {
  const [session] = useSession();
  const dispatch = useNotificationDispatch();

  const signUserOut = (): void => {
    signOut();
    dispatch(
      updateNotification({
        title: 'Signed Out',
        icon: 'success',
        show: true,
        description: 'You have been successfully signed out!',
      })
    );
  };

  const avatar = session?.user?.image ? (
    <Image
      height="48"
      width="48"
      className="h-10 w-10 rounded-full"
      src={session.user.image}
      alt="Profile Image"
    />
  ) : (
    <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
      <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    </span>
  );

  const adminMenuOptions = [
    {
      name: 'Listings',
      href: '/admin/listings',
    },
    {
      name: 'Users',
      href: '/admin/users',
    },
    {
      name: 'Orders',
      href: '/admin/orders',
    },
  ];

  return (
    <nav className="relative bg-white shadow z-40">
      <>
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-between h-16">
            <div className="flex w-full">
              <div className="flex-shrink-0 flex items-center">
                <div className="h-8 w-auto">
                  <Link href="/">
                    <a>
                      <Image
                        src="/svg/sal-logo-text.svg"
                        alt="Swapalease logo"
                        width="176"
                        height="34"
                      />
                    </a>
                  </Link>
                </div>
              </div>

              <div className="hidden md:ml-12 md:flex md:space-x-8 justify-left w-full">
                <NavLink title="Listings" to="/admin/listings" />
                <NavLink title="Users" to="/admin/users" />
                <NavLink title="Orders" to="/admin/orders" />
              </div>
            </div>

            <div className="flex flex-row w-16 md:w-96 items-center justify-end">
              {/* Profile dropdown */}

              {session ? (
                <div className="hidden md:block w-16">
                  <Menu as="div" className="ml-3 relative z-40">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                            <span className="sr-only">Open user menu</span>
                            <div className="h-9 w-9 rounded-full overflow-hidden">{avatar}</div>
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            {adminMenuOptions.map((item, idx) => (
                              <Menu.Item key={idx}>
                                {({ active }) => (
                                  <Link href={item.href}>
                                    <a
                                      className={utils.classNames(
                                        active ? 'bg-secondary' : '',
                                        'block px-4 py-2 text-sm text-gray-700'
                                      )}
                                    >
                                      {item.name}
                                    </a>
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}

                            <Divider />

                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={utils.classNames(
                                    active ? 'bg-secondary' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                  onClick={() => signUserOut()}
                                >
                                  Sign Out
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              ) : (
                <div className="hidden md:block w-18">
                  <button
                    className="block px-4 py-2 text-sm font-bold text-primary"
                    onClick={() => signIn()}
                  >
                    Sign In
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </nav>
  );
};

export default AdminNavigation;
